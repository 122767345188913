import {
  Event,
  MeetGreetIndex,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { fstore } from "firebase-tools";
import { useEffect, useRef, useState } from "react";
import { useUserContext } from "UserContextProvider";
import { SessionEvent } from "./index";

export const useData = (
  artistGroupId: string
): {
  isLoading: boolean;
  sessions: MeetGreetIndex[];
  incompleteEvents: SessionEvent[];
} => {
  const { user, isAdmin } = useUserContext();
  const [sessions, setSessions] = useState<MeetGreetIndex[] | null>(null);
  const [incompleteEvents, setIncompleteEvents] = useState<
    SessionEvent[] | null
  >(null);

  const [isLoading, setIsLoading] = useState(true);

  const sessionsUnsubscribeFunc = useRef(null);
  const eventsUnsubscribeFunc = useRef(null);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let query = fstore
        .collection("meet_greet_index")
        .where("artistGroupId", "==", artistGroupId);
      if (!isAdmin) query = query.where("uids", "array-contains", user.uid);

      sessionsUnsubscribeFunc.current = query.onSnapshot((snap) => {
        const sessions = snap.docs
          .map((d) => {
            try {
              return MeetGreetIndex.raw(d.data(), { safeMode: true });
            } catch (error) {
              console.error(
                `Error loading MeetGreetIndex ${d.id}`,
                JSON.stringify(error)
              );
            }
            return null;
          })
          .filter((v) => !!v);
        setSessions(sessions);
      });

      eventsUnsubscribeFunc.current = fstore
        .collection("meet_greet_events")
        .where("sessionCount", "==", 0)
        .where("artistGroupId", "==", artistGroupId)
        .onSnapshot((snap) => {
          const events = snap.docs
            .map((doc) => {
              try {
                const event = Event.raw(doc.data(), {
                  safeMode: true,
                });

                return {
                  eventId: doc.id,
                  eventName: event.name,
                  eventAssets: event.assets,
                  unitPrice: event.unitPrice,
                  sessionDuration: event.sessionDuration,
                };
              } catch (error) {
                console.error(
                  `Error loading Event ${doc.id}`,
                  JSON.stringify(error)
                );
                return null;
              }
            })
            .filter((e) => !!e);

          setIncompleteEvents(events);
        });
    })();

    return () => {
      sessionsUnsubscribeFunc.current();
      eventsUnsubscribeFunc.current();
    };
  }, []);

  useEffect(() => {
    if (!!sessions && !!incompleteEvents) setIsLoading(false);
  }, [sessions, incompleteEvents]);

  const value = { isLoading, sessions, incompleteEvents };
  return value;
};
