import {
  Div,
  Modal as ModalBase,
  ModalWrapper,
  ModalContainer,
  useIsMobile,
  useWindowInnerSize,
} from "@max/common-ui";
import { useState } from "react";
import styled, { css } from "styled-components";
import WatermarkEditor from "./WatermarkEditor";
import { Button, Checkbox, Icon } from "notes";
import maleSvg from "./assets/male.svg";
import femaleSvg from "./assets/female.svg";

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    margin-top: 0;
    height: 100%;
  }
  ${ModalWrapper} {
    box-sizing: border-box;
    box-shadow: none;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @media only screen and ${(props) => props.theme.media.mobile} {
      overflow-x: hidden;
      background: #000000;
      padding: 12px 0 0 0;
    }
  }
`;

const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray20};
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

const Svg = styled(Div)`
  height: 100%;
  width: calc(100% - 1px);
  background-position: 50% 15%;
  background-size: 140% 140%;
  pointer-events: none;
  background-image: url(${(props) => props.src});
`;

const HorizontalSvg = styled(Svg)`
  height: calc(50% - 1px);
  width: 100%;
`;

const DividerVertical = styled(Div)`
  background: white;
  width: 2px;
  height: 100%;
`;

const DividerHorizontal = styled(Div)`
  background: white;
  height: 2px;
  width: 100%;
`;

const Gradient = styled(Div)`
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: -1px;
  background: linear-gradient(0deg, #000000 0%, rgba(100, 100, 100, 0) 100%);
`;

const ImageBackground = styled(Div)`
  position: relative;
`;

const WatermarkPositioningModal = ({
  initialValues = {
    desktopPosition: null,
    mobilePosition: null,
    includesGradient: false,
  },
  watermarkImgUrl,
  onSubmit,
  onClose,
}) => {
  const [includesGradient, setIncludesGradient] = useState(
    initialValues.includesGradient
  );
  const [desktopPosition, setDesktopPosition] = useState(
    initialValues.desktopPosition
  );
  const [mobilePosition, setMobilePosition] = useState(
    initialValues.mobilePosition
  );
  const [mobileView, setMobileView] = useState("desktop");
  const isMobile = useIsMobile();
  const windowSize = useWindowInnerSize();
  const innerWidth = windowSize.width;

  document.body.style.overflow = "hidden";

  const handleClose = () => {
    onClose();
    document.body.style.overflow = "auto";
  };

  const _onSubmit = () => {
    onSubmit({
      includesGradient,
      desktopPosition,
      mobilePosition,
    });
    handleClose();
  };

  const buttonsUI = (
    <Div dflex mt_55>
      <Button type="tertiary" onClick={handleClose}>
        Cancel
      </Button>
      <Div ml_30>
        <Button onClick={_onSubmit}>Apply</Button>
      </Div>
    </Div>
  );

  const headerUI = (
    <>
      <HeaderContainer c_text>
        <HeaderTitle extraBold fs_25>
          Watermark Positioning
        </HeaderTitle>
        <HeaderDescription fs_17 lite mt_12>
          Please adjust the position and sizing the watermark on the desktop and
          mobile views. If your watermark is light colored, it’s recommended to
          include a gradient and place the logo over the shaded area.
        </HeaderDescription>
        <Div clickable dflex as="label" alignCenter>
          <Div svg_white c_white alignCenter>
            <Checkbox
              checked={includesGradient}
              onChange={(checked) => setIncludesGradient(checked)}
            />
          </Div>
          <CheckboxLabel fs_17>
            Include a gradient at the bottom of calls
          </CheckboxLabel>
        </Div>
      </HeaderContainer>
      <Divider mt_15 mb_25 />
    </>
  );

  const desktopEditorUI = (
    <MobileCenter>
      <Div c_text fs_15 mb_10 forDesktop>
        Desktop size and position
      </Div>
      <WatermarkEditor
        initialPosition={desktopPosition}
        selectedImg={watermarkImgUrl}
        onUpdate={(res) => setDesktopPosition(res)}
        width={isMobile ? innerWidth : 500}
        height={isMobile ? innerWidth / 2 : 250}
        backgroundUI={
          <ImageBackground w100 h100 dflex includesGradient={includesGradient}>
            <Svg src={maleSvg} />
            <DividerVertical />
            <Svg src={femaleSvg} />
            {includesGradient && <Gradient />}
          </ImageBackground>
        }
      />
    </MobileCenter>
  );

  const mobileEditorUI = (
    <MobileCenter>
      <Div c_text fs_15 mb_10 forDesktop>
        Mobile size and position
      </Div>
      <WatermarkEditor
        initialPosition={mobilePosition}
        selectedImg={watermarkImgUrl}
        onUpdate={(res) => setMobilePosition(res)}
        width={isMobile ? innerWidth / 2 : 250}
        height={isMobile ? innerWidth : 500}
        backgroundUI={
          <ImageBackground w100 h100 includesGradient={includesGradient}>
            <HorizontalSvg src={maleSvg} />
            <DividerHorizontal />
            <HorizontalSvg src={femaleSvg} />
            {includesGradient && <Gradient />}
          </ImageBackground>
        }
      />
    </MobileCenter>
  );

  return (
    <Modal isOpen={true} onClose={handleClose}>
      {!isMobile ? (
        <Wrapper>
          {headerUI}
          <Div dflex spaceBetween>
            {desktopEditorUI}
            {mobileEditorUI}
          </Div>
          {buttonsUI}
        </Wrapper>
      ) : (
        <Div dflexColumn>
          <Div justifyCenter alignCenter style={{ height: "100vw" }}>
            {mobileView === "desktop" && desktopEditorUI}
            {mobileView === "mobile" && mobileEditorUI}
          </Div>
          {headerUI}
          <Div dflexColumn>
            <Div justifyCenter>
              <PillButton
                type={
                  mobileView === "desktop" ? "primaryReverse" : "linkReverse"
                }
                iconLeft={<Icon form name="Desktop" />}
                onClick={() => setMobileView("desktop")}
              >
                Desktop
              </PillButton>
              <PillButton
                type={
                  mobileView === "mobile" ? "primaryReverse" : "linkReverse"
                }
                iconLeft={<Icon form name="Phone" />}
                onClick={() => setMobileView("mobile")}
              >
                Mobile
              </PillButton>
            </Div>
            <ButtonContainer spaceBetween>
              <SmallButton type="linkReverse" onClick={handleClose}>
                Cancel
              </SmallButton>
              <GrayButton onClick={_onSubmit}>Done</GrayButton>
            </ButtonContainer>
          </Div>
        </Div>
      )}
    </Modal>
  );
};

export default WatermarkPositioningModal;

const Wrapper = styled(Div)`
  max-width: 888px;
  margin: 0 auto;
  padding: 0 40px;
`;

const ButtonContainer = styled(Div)`
  padding: 24px 20px 20px;
`;

const SmallButton = styled(Button)`
  min-width: 70px;
`;

const PillButton = styled(Button)`
  border-radius: 20px;
  min-width: 112px;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  padding: 6px 8px 7px;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    padding: 6px 8px 7px;
    text-decoration: none;
    ${(props) =>
      props.type === "primaryReverse" &&
      css`
        background-color: #ffffff;
        color: #222222;
      `};
  }
`;

const GrayButton = styled(SmallButton)`
  background-color: ${(props) => props.theme.palette.gray.primary};
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${(props) => props.theme.palette.gray.primary};
  }
`;

const HeaderTitle = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    color: #ffffff;
    font-size: 15px;
    line-height: 19px;
  }
`;

const HeaderDescription = styled(HeaderTitle)`
  margin-bottom: 30px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    color: ${(props) => props.theme.palette.gray.lighter};
    margin-top: 4px;
    margin-bottom: 16px;
  }
`;

const CheckboxLabel = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    color: #ffffff;
    font-size: 15px;
    line-height: 19px;
  }
`;

const HeaderContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 16px 20px 24px;
  }
`;

const MobileCenter = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    display: flex;
    justify-content: center;
  }
`;
