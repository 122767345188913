var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import ChromeDefault from "../../../../assets/images/ChromeDefault.png";
import FirefoxDefault from "../../../../assets/images/FirefoxDefault.png";
import EdgeDefault from "../../../../assets/images/EdgeDefault.png";
export var ImageLogo = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 40px;\n  height: 40px;\n  display: block;\n  flex-shrink: 0;\n  margin-right: 12px;\n"], ["\n  width: 40px;\n  height: 40px;\n  display: block;\n  flex-shrink: 0;\n  margin-right: 12px;\n"])));
var imagePath = process.env.REACT_APP_ASSETS_PATH + "/troubleshoot/windows";
export var windows = [
    {
        key: "chrome",
        name: "Chrome",
        logo: React.createElement(ImageLogo, { src: ChromeDefault }),
        data: [
            {
                type: "text",
                content: "Allow Chrome access to Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/chrome/1.png",
            },
            {
                type: "text",
                content: "Or, click the lock icon and choose Site settings",
            },
            {
                type: "image",
                content: imagePath + "/chrome/2.png",
            },
            {
                type: "text",
                content: "Select Allow under the drop-downs to allow access to your Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/chrome/3.png",
            },
            {
                type: "text",
                content: "Refresh website",
            },
        ],
    },
    {
        key: "firefox",
        name: "Firefox",
        logo: React.createElement(ImageLogo, { src: FirefoxDefault }),
        data: [
            {
                type: "text",
                content: "Allow Firefox access to your Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/firefox/1.png",
            },
            {
                type: "image",
                content: imagePath + "/firefox/2.png",
            },
            {
                type: "image",
                content: imagePath + "/firefox/3.png",
            },
            {
                type: "text",
                content: "Or, click the lock icon and then the arrow to the right",
            },
            {
                type: "image",
                content: imagePath + "/firefox/4.png",
            },
            {
                type: "text",
                content: "Click More Information to access additional settings",
            },
            {
                type: "image",
                content: imagePath + "/firefox/5.png",
            },
            {
                type: "text",
                content: "Click Permissions tab and scroll to bottom",
            },
            {
                type: "text",
                content: "Select Allow to allow Firefox access to both your Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/firefox/6.png",
            },
        ],
    },
    {
        key: "edge",
        name: "Edge",
        logo: React.createElement(ImageLogo, { src: EdgeDefault }),
        data: [
            {
                type: "text",
                content: "Allow Edge access to Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/edge/1.png",
            },
            {
                type: "text",
                content: "Or, click the lock icon and choose Site permissions",
            },
            {
                type: "image",
                content: imagePath + "/edge/2.png",
            },
            {
                type: "text",
                content: "Select Allow under the drop-downs to allow access to both your Camera and Microphone",
            },
            {
                type: "image",
                content: imagePath + "/edge/3.png",
            },
            {
                type: "text",
                content: "Refresh website",
            },
            {
                type: "image",
                content: imagePath + "/edge/4.png",
            },
        ],
    },
];
var templateObject_1;
