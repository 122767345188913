import {
  Div,
  MobileDivider,
  SelectImageFields,
  VideoRecorder,
} from "@max/common-ui";
import { ErrorMessage as ErrorMessageBase, Icon } from "notes";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FormContentWrapper, FormSection } from "../form";
import { Container } from "../TicketSiteFormFields";
import {
  EventMediaContextProvider,
  useEventMediaContext,
} from "./EventMediaContextProvider";
import { imageFields } from "./imageFields";
import { useBlobUploads } from "./useBlobUploads";
import { VideoField } from "./VideoField";
import { WatermarkField } from "./WatermarkField";

const ErrorWarningMessageContent = styled(ErrorMessageBase)`
  width: 100%;
  margin: 0;
`;

const errorMessageUI = (
  <Div c_inputErrorBorder>
    Please review the highlighted errors before attempting your submission
    again.
  </Div>
);

const ErrorMessageWarning = () => {
  return (
    <Div centered>
      <ErrorWarningMessageContent content={errorMessageUI} />
    </Div>
  );
};

const FormContentContainer = styled(FormContentWrapper)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 0;
  }
`;

export const EventMediaFields = ({
  onSubmit,
  mediaData,
  setMediaData,
  FormButtons,
}) => {
  return (
    <EventMediaContextProvider {...{ mediaData, setMediaData }}>
      <EventMediaWithContext {...{ onSubmit, FormButtons }} />
    </EventMediaContextProvider>
  );
};

const EventMediaWithContext = ({ onSubmit, FormButtons }) => {
  const { mediaData, setMediaData } = useEventMediaContext();
  const [isVideoRecorderOpen, setIsVideoRecorderOpen] = useState(false);
  const [isMissingImages, setIsMissingImages] = useState(false);
  const missingImagesErrorRef = useRef(null);

  const { uploadBlobs, progressModalUI } = useBlobUploads(
    getFormattedBlobs(mediaData)
  );

  const { artistImages } = mediaData;

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "show warning";
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    setIsMissingImages(false);
  }, [artistImages]);

  const setRecordingBlob = (blob) =>
    setMediaData((v) => ({ ...v, recordingBlob: blob }));

  const setArtistImages = (images) =>
    setMediaData((v) => ({ ...v, artistImages: images }));

  const onSubmitForm = async () => {
    if (!artistImages?.desktopImg?.blob || !artistImages?.mobileImg?.blob) {
      setIsMissingImages(true);
      missingImagesErrorRef?.current?.focus();
      return;
    }
    const assetUrls = await uploadBlobs();
    onSubmit(assetUrls);
  };

  const optionalFields = (
    <FormContentContainer>
      <VideoField
        {...{
          setRecordingBlob,
          setIsVideoRecorderOpen,
        }}
      />
      <MobileDivider />
      <WatermarkField />
    </FormContentContainer>
  );

  const formUI = (
    <>
      <FormSection
        header="Artist Images"
        icon={<Icon form name="Gallery" />}
        description="The images below will be used on your Meet & Greet sales page, in emails sent to fans who buy tickets, and in the header of the Meet & Greet experience itself. Images without text will work best."
      />
      <FormContentWrapper>
        <SelectImageFields
          values={artistImages}
          setValues={setArtistImages}
          fields={imageFields}
        />
        {isMissingImages && (
          <Div ref={missingImagesErrorRef} fs_15 c_red20 mt_20 tabIndex="0">
            Images are required for the sales page
          </Div>
        )}
      </FormContentWrapper>
      <MobileDivider style={{ marginTop: "32px" }} />
      {optionalFields}
    </>
  );

  return (
    <Container mcontainer>
      {progressModalUI}
      {!isVideoRecorderOpen ? (
        <>
          {isMissingImages && (
            <Div mb_40>
              <ErrorMessageWarning />
            </Div>
          )}
          {formUI}
          <FormButtons onSubmit={onSubmitForm} />
        </>
      ) : (
        <VideoRecorder
          setRecordingBlob={(blob) =>
            setRecordingBlob({ blob, isInitialValue: false })
          }
          {...{
            isVideoRecorderOpen,
            setIsVideoRecorderOpen,
          }}
        />
      )}
    </Container>
  );
};

const getFormattedBlobs = (mediaData) => {
  const { artistImages, recordingBlob, watermarkImages } = mediaData;
  const blobs = [
    {
      ...artistImages.desktopImg,
      label: "Artist Image (desktop)",
      key: "desktopImg",
    },
    {
      ...artistImages.mobileImg,
      label: "Artist Image (mobile)",
      key: "mobileImg",
    },
    {
      ...watermarkImages.desktopImg,
      key: "desktopWatermarkImg",
      label: "Desktop Watermark",
    },
    {
      ...watermarkImages.mobileImg,
      key: "mobileWatermarkImg",
      label: "Mobile Watermark",
    },
    {
      ...watermarkImages.logo,
      key: "logoWatermarkImg",
      label: "Watermark logo",
    },
    { ...recordingBlob, label: "Promotional Video", key: "recordingBlob" },
  ].filter(({ isInitialValue, blob }) => !isInitialValue && blob);
  return blobs;
};
