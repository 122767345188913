import { EVENT_ADMIN_ROUTES, EVENT_ROUTES } from "components/EventsAdmin";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";

type Return = {
  gotoCurrentEventDetail: () => void;
  gotoAddSession: (_eventId: any) => void;
  gotoEventDetail: (_eventId: any) => void;
  gotoSessionDetail: (_eventId: any, _sessionId: any) => void;
  gotoEditTicketSite: () => void;
  gotoEditMedia: () => void;
  gotoDefault: () => void;
};

export const useEventNavigation = (): Return => {
  const { eventId: routeEventId } = useParams<any>();
  const { artistGroupId } = useParams<any>();
  const history = useHistory();

  const gotoDefault = () => {
    history.push(
      generatePath(`${ROUTES.EVENTS_ADMIN}`, {
        artistGroupId,
      })
    );
  };

  const gotoEditTicketSite = () =>
    history.push(
      generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}${EVENT_ROUTES.EDIT_TICKET_SITE}`,
        {
          artistGroupId,
          eventId: routeEventId,
        }
      )
    );

  const gotoEditMedia = () =>
    history.push(
      generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}${EVENT_ROUTES.EDIT_MEDIA}`,
        {
          artistGroupId,
          eventId: routeEventId,
        }
      )
    );

  const gotoAddSession = (_eventId) => {
    history.push(
      `${generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.ADD_SESSION}`,
        {
          artistGroupId,
          eventId: _eventId,
        }
      )}`
    );
  };

  const gotoEventDetail = (_eventId) => {
    history.push(
      `${generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`,
        {
          artistGroupId,
          eventId: _eventId,
        }
      )}`
    );
  };

  const gotoSessionDetail = (_eventId, _sessionId) => {
    history.push(
      `${generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`,
        {
          artistGroupId,
          eventId: _eventId,
        }
      )}?sId=${_sessionId}`
    );
  };

  const gotoCurrentEventDetail = () => {
    history.push(
      generatePath(`${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`, {
        eventId: routeEventId,
        artistGroupId,
      })
    );
  };

  const value = {
    gotoCurrentEventDetail,
    gotoAddSession,
    gotoEventDetail,
    gotoSessionDetail,
    gotoEditTicketSite,
    gotoEditMedia,
    gotoDefault,
  };

  return value;
};
