import {
  AppContainer,
  AppLayout,
  AppLoading,
  Div,
  Footer,
  MobileDivider,
} from "@max/common-ui";
import { fstore } from "firebase-tools";
import { Icon } from "notes";
import { useContext, useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";
import styled, { ThemeContext } from "styled-components";
import { MediaForm } from "./MediaForm";
import {
  ScheduleMeetGreetContextProvider,
  useScheduleMeetGreetContext,
} from "./ScheduleMeetGreetContextProvider";
import SessionsForm from "./SessionForm";
import { Steps } from "./Steps";
import { TicketSiteForm } from "./TicketSiteForm";

const StepsContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

const Layout = ({ children }) => {
  const { artistGroupId } = useScheduleMeetGreetContext();
  const {
    colors: { white: whiteColor },
  } = useContext(ThemeContext);

  const history = useHistory();
  useEffect(() => {
    document.body.style.backgroundColor = whiteColor;
  }, []);

  const headerUI = (
    <Div mt_30 spaceBetween alignCenter svg_gray20>
      <Div />
      <Div
        clickable
        onClick={() =>
          history.push(generatePath(ROUTES.EVENTS_ADMIN, { artistGroupId }))
        }
      >
        <Icon platform name="Close" />
      </Div>
    </Div>
  );

  return (
    <AppLayout>
      <Div mcontainer>
        <AppContainer>{headerUI}</AppContainer>
      </Div>
      <AppContainer>{children}</AppContainer>
      <Footer background={whiteColor} />
    </AppLayout>
  );
};

const ScheduleMeetGreet = () => {
  const { artistGroupId } = useParams<any>();
  const [artistGroup, isLoadingArtistGroup]: any = useDocumentData(
    fstore.doc(`/artist_groups/${artistGroupId}`)
  );

  return (
    <>
      {isLoadingArtistGroup ? (
        <AppLoading />
      ) : (
        <ScheduleMeetGreetWithData artistName={artistGroup?.name} />
      )}
    </>
  );
};

const ScheduleMeetGreetWithData = ({ artistName }) => {
  return (
    <ScheduleMeetGreetContextProvider artistName={artistName}>
      <ScheduleMeetGreetWithContext />
    </ScheduleMeetGreetContextProvider>
  );
};

const ScheduleMeetGreetWithContext = () => {
  const { currentStep } = useScheduleMeetGreetContext();
  return (
    <Layout>
      <StepsContainer mt_45 mb_80>
        <Steps currentStep={currentStep} />
      </StepsContainer>
      <MobileDivider style={{ marginBottom: "22px" }} />
      <PageTitle fs_25 lh_32 c_gray20 mb_10 mcontainer>
        {"Schedule a Meet & Greet"}
      </PageTitle>
      <Div mt_15 mb_80>
        {currentStep === 0 && <TicketSiteForm />}
        {currentStep === 1 && <MediaForm />}
        {currentStep === 2 && <SessionsForm />}
      </Div>
    </Layout>
  );
};

export default ScheduleMeetGreet;

const PageTitle = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
    line-height: 26px;
  }
`;
