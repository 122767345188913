import { Div, Modal as ModalBase, ModalWrapper } from "@max/common-ui";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import styled from "styled-components";
import { ShareUrl } from "../ShareUrl";

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    width: 472px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 60vw;
    }
  }
`;

export const ShareTicketSiteModal = ({ isOpen, onClose }) => {
  const { event, routeEventId } = useEventDetailContext();
  return (
    <Modal withCloseIcon={false} {...{ isOpen, onClose }}>
      <Div c_text textAlignCenter>
        <Div fs_25 extraBold>
          Share Ticket Site
        </Div>
        <Div mt_10 bold fs_17 c_gray30 truncate>
          {event.artist}
        </Div>
        <Div centered>
          <Div w_392 lite fs_17 mt_28 mb_40>
            {
              "How would you like to share your upcoming meet & greet with fans?"
            }
          </Div>
        </Div>
        <Div centered>
          <ShareUrl
            url={`${process.env.REACT_APP_TICKET_SITE_URL}/${routeEventId}`}
          />
        </Div>
        <Div link c_action fs_17 mt_40 mb_15 bold onClick={onClose}>
          Done
        </Div>
      </Div>
    </Modal>
  );
};
