var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { GridColumn, GridRow, H3, Icon, P, withDefaultMedia, BrowserInfo, } from "notes";
import { android, windows } from "./os";
import { Div } from "../../Div";
export var MediaTroubleshoot = withDefaultMedia(function (_a) {
    var _b, _c, _d, _e;
    var matches = _a.matches, onRef = _a.onRef, props = __rest(_a, ["matches", "onRef"]);
    var defaultOs = (_b = BrowserInfo === null || BrowserInfo === void 0 ? void 0 : BrowserInfo.getOS()) === null || _b === void 0 ? void 0 : _b.name;
    var defaultBrowser = (_d = (_c = BrowserInfo === null || BrowserInfo === void 0 ? void 0 : BrowserInfo.getBrowser()) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.toLowerCase();
    var _f = useState(defaultOs), os = _f[0], setOS = _f[1];
    var _g = useState(defaultBrowser), browser = _g[0], setBrowser = _g[1];
    var _h = useState(false), menuOpen = _h[0], setMenuOpen = _h[1];
    var containerRef = useRef(null);
    var handleClickOutside = function (evt) {
        var _a;
        if (!((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(evt.target))) {
            setMenuOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    });
    var handleClick = function (view) {
        setBrowser(view);
        setMenuOpen(false);
    };
    var browserList = os === "Windows" ? windows : os === "Android" ? android : windows;
    var selectedBrowser = browserList.find(function (_a) {
        var key = _a.key;
        return browser.includes(key);
    });
    if (!selectedBrowser || !browserList) {
        return React.createElement(P, null, "Your browser is not supported.");
    }
    var wrapperRef = useRef();
    useEffect(function () {
        !!onRef && onRef(wrapperRef);
    }, [wrapperRef]);
    var returnItem = function (item) {
        switch (item.type) {
            case "image":
                // return <Lightbox image={item.content} />;
                return React.createElement(PreviewImage, { src: item.content });
            default:
                return React.createElement(Li, null, item.content);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalHeader, null,
            React.createElement(Div, { alignCenter: true, h100: true, w100: true, spaceBetween: true },
                React.createElement(Div, { ml_23: true }, " Troubleshoot"),
                React.createElement(Div, { mr_23: true, clickable: true, centered: true },
                    React.createElement(Icon, { platform: true, name: "Close", onClick: props.onClose })))),
        React.createElement(Wrapper, null,
            React.createElement(Div, { dflex: true, alignCenter: true, mt_50: true },
                React.createElement(H3, null, "Camera or Microphone Not Working?"),
                React.createElement(Div, { ml_30: true },
                    React.createElement(Selector, { ref: containerRef },
                        React.createElement(Button, { onClick: function () { return setMenuOpen(true); } },
                            selectedBrowser.name,
                            React.createElement(Caret, { indicator: true, name: "Dropdown" })),
                        React.createElement(Menu, { open: menuOpen }, browserList.map(function (_a) {
                            var key = _a.key, name = _a.name, logo = _a.logo;
                            return (React.createElement(Item, { key: key, active: key === browser, onClick: function () { return handleClick(key); } },
                                logo,
                                name));
                        }))))),
            React.createElement(Content, null,
                React.createElement(Ol, null, (_e = selectedBrowser === null || selectedBrowser === void 0 ? void 0 : selectedBrowser.data) === null || _e === void 0 ? void 0 : _e.map(function (item) { return returnItem(item); }))))));
});
var Wrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 40px 40px 40px;\n"], ["\n  padding: 0 40px 40px 40px;\n"])));
var ModalHeader = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  z-index: 10000000000000000;\n  background-color: white;\n  height: 50px;\n  font-size: 17px;\n  color: #222222;\n  font-weight: 600;\n  border-bottom: 1px solid #9ca4a8;\n"], ["\n  position: fixed;\n  width: 100%;\n  z-index: 10000000000000000;\n  background-color: white;\n  height: 50px;\n  font-size: 17px;\n  color: #222222;\n  font-weight: 600;\n  border-bottom: 1px solid #9ca4a8;\n"])));
var PreviewImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  margin: 16px 0;\n  max-width: 100%;\n"], ["\n  display: block;\n  margin: 16px 0;\n  max-width: 100%;\n"])));
var Ol = styled.ol(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  list-style-type: decimal;\n  padding-left: 20px;\n"], ["\n  list-style-type: decimal;\n  padding-left: 20px;\n"])));
var Li = styled(P).attrs({ as: "li" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  & + & {\n    margin-top: 16px;\n  }\n"], ["\n  color: ", ";\n  & + & {\n    margin-top: 16px;\n  }\n"])), function (props) {
    return props.theme.dark ? "#ffffff" : props.theme.palette.black;
});
var Container = styled(GridColumn).attrs({ as: "div" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  box-sizing: border-box;\n  padding: 24px;\n  @media only screen and ", " {\n    border-top: 2px solid ", ";\n    margin: 40px 44px 0 44px;\n    padding: 24px 0;\n  }\n"], ["\n  display: flex;\n  box-sizing: border-box;\n  padding: 24px;\n  @media only screen and ", " {\n    border-top: 2px solid ", ";\n    margin: 40px 44px 0 44px;\n    padding: 24px 0;\n  }\n"])), function (props) { return props.theme.media.large; }, function (props) { return props.theme.palette.gray.lighter; });
var Header = styled(GridRow)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex-direction: column;\n  & > ", " {\n    color: ", ";\n    font-size: 17px;\n    line-height: 22px;\n    margin-bottom: 4px;\n  }\n  @media only screen and ", " {\n    flex-direction: row;\n    & > ", " {\n      font-size: 25px;\n      line-height: 32px;\n      margin-bottom: 0;\n      width: auto;\n    }\n  }\n"], ["\n  flex-direction: column;\n  & > ", " {\n    color: ", ";\n    font-size: 17px;\n    line-height: 22px;\n    margin-bottom: 4px;\n  }\n  @media only screen and ", " {\n    flex-direction: row;\n    & > ", " {\n      font-size: 25px;\n      line-height: 32px;\n      margin-bottom: 0;\n      width: auto;\n    }\n  }\n"])), H3, function (props) {
    return props.theme.dark ? "#ffffff" : props.theme.palette.black;
}, function (props) { return props.theme.media.large; }, H3);
var Content = styled(GridColumn)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: -10px;\n"], ["\n  width: 100%;\n  margin-top: -10px;\n"])));
var Caret = styled(Icon)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-left: 6px;\n  path {\n    fill: ", ";\n  }\n"], ["\n  margin-left: 6px;\n  path {\n    fill: ", ";\n  }\n"])), function (props) { return props.theme.palette.purple.primary; });
var Selector = styled(H3)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n  @media only screen and ", " {\n    margin-left: 16px;\n  }\n"], ["\n  position: relative;\n  @media only screen and ", " {\n    margin-left: 16px;\n  }\n"])), function (props) { return props.theme.media.large; });
var Button = styled(H3)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  font-size: 17px;\n  line-height: 22px;\n  &:hover {\n    color: ", ";\n    ", " {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n\n  @media only screen and ", " {\n    font-size: 25px;\n    line-height: 32px;\n  }\n"], ["\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  font-size: 17px;\n  line-height: 22px;\n  &:hover {\n    color: ", ";\n    ", " {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n\n  @media only screen and ", " {\n    font-size: 25px;\n    line-height: 32px;\n  }\n"])), function (props) { return props.theme.palette.purple.primary; }, function (props) { return props.theme.palette.purple.darker; }, Caret, function (props) { return props.theme.palette.purple.darker; }, function (props) { return props.theme.media.large; });
var Menu = styled(GridColumn).attrs({ as: "div" })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-left: -75px;\n  display: flex;\n  box-sizing: border-box;\n  background-color: #ffffff;\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);\n  width: 184px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 5;\n  opacity: ", ";\n  visibility: ", ";\n"], ["\n  margin-left: -75px;\n  display: flex;\n  box-sizing: border-box;\n  background-color: #ffffff;\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);\n  width: 184px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 5;\n  opacity: ", ";\n  visibility: ", ";\n"])), function (props) { return (props.open ? 1 : 0); }, function (props) { return (props.open ? "visible" : "hidden"); });
var Item = styled(GridRow)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background-color: ", ";\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  padding: 16px 12px;\n  font-weight: 600;\n  & + & {\n    border-top: 1px solid ", ";\n  }\n  &:hover {\n    background-color: #f5f5f5;\n  }\n  svg {\n    margin-right: 12px;\n  }\n"], ["\n  background-color: ", ";\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  padding: 16px 12px;\n  font-weight: 600;\n  & + & {\n    border-top: 1px solid ", ";\n  }\n  &:hover {\n    background-color: #f5f5f5;\n  }\n  svg {\n    margin-right: 12px;\n  }\n"])), function (props) { return (props.active ? "#f5f5f5" : "transparent"); }, function (props) { return props.theme.palette.black; }, function (props) { return props.theme.palette.gray.lightest; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
