import { Div } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import { getArtistAdminsList } from ".";
import { shoutoutI } from "firebase-tools";
import { generatePath } from "react-router-dom";
import { ROUTES } from "Routes";
import { LoaderInline } from "notes";
import { useAppContext } from "AppContextProvider";
import { useUserContext } from "UserContextProvider";

const Message = styled(Div)`
  span {
    user-select: none;
    ${(props) =>
      props.isArtistGroupAdmin &&
      css`
        :hover {
          cursor: pointer;
          opacity: 0.8;
          text-decoration: underline;
        }
      `}
  }
`;

const Container = styled(Div)`
  background: ${(props) => props.theme.colors.yellow20};
  border-bottom: solid 1px ${(props) => props.theme.colors.yellow40};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 20px;
  ${(props) =>
    props.isArtistGroupAdmin &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
    @media all and ${(props) => props.theme.media.mobile} {
      padding: 17px;
      max-height: 110px;
      overflow: hidden;
      word-wrap: break-word;
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
`;

const Description = styled(Div)`
  span {
    font-weight: bold;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const StripeConnectMessage = ({
  isStripeReady = null,
  artistGroupId,
  isArtistGroupAdmin,
  artistGroupAdmins = [],
  className = "",
  stripeRedirectTo,
}) => {
  const { setAppIsLoading } = useAppContext();
  const { isAdmin: isAdminUser } = useUserContext();
  const isAdmin = isAdminUser || isArtistGroupAdmin;

  const message = isAdmin ? (
    <Message isArtistGroupAdmin={isAdmin}>
      Set-up <span>Stripe Connect</span> to start getting paid!
    </Message>
  ) : artistGroupAdmins ? (
    <Message isArtistGroupAdmin={isAdmin}>
      Set-up <span>Stripe Connect</span> to start getting paid! To proceed,
      please contact {getArtistAdminsList(artistGroupAdmins)}.
    </Message>
  ) : (
    <LoaderInline />
  );

  const setupStripe = async () => {
    setAppIsLoading(true);

    const redirectPath = generatePath(ROUTES.STRIPE_CONNECT, {
      redirectTo: stripeRedirectTo,
      artistGroupId,
    });

    const {
      result,
      error,
    } = await shoutoutI.functions().payments.connectAccount({
      redirectUri: `${window.location.origin}${redirectPath}`,
      artistGroupId,
    });
    if (!error && result.url) window.location.href = result.url;
  };

  return (
    <>
      {!isStripeReady && (
        <Container
          onClick={async (e) => {
            if (isAdmin) {
              e.stopPropagation();
              e.preventDefault();
              setupStripe();
            }
          }}
          className={className}
          isArtistGroupAdmin={isAdmin}
        >
          <Div dflex svg_text centered>
            <Description mr_7>{message}</Description>
            {isAdmin && <Icon name="Right" height="18px" />}
          </Div>
        </Container>
      )}
    </>
  );
};
