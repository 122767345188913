import { AppLoading } from "@max/common-ui";
import { MeetGreetIndex } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { storeSelectedFilter } from "components/common";
import { useEventNavigation } from "components/common/useEventNavigation";
import { createContext, useContext, useState } from "react";
import { useParams } from "react-router";
import {
  compareIncompleteEvents,
  compareSessions,
  getEventFilterFunction,
  getEventSortFunction,
  unique,
} from "./helpers";
import { useData } from "./useData";

const eventFilterOptions = ["upcoming", "past"] as const;
export type EventFilterOptions = typeof eventFilterOptions[number];
export const eventFilterLabels: { [key in EventFilterOptions]?: string } = {
  upcoming: "Upcoming",
  past: "Past",
};

export type SessionEvent = Pick<
  MeetGreetIndex,
  "eventId" | "eventName" | "eventAssets" | "unitPrice" | "sessionDuration"
>;

type State = {
  artistGroupId: string;
  eventIds: string[];
  sessions: MeetGreetIndex[];
  defaultEventId: any;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  selectedFilter: EventFilterOptions;
  setSelectedFilter: (filter: EventFilterOptions) => void;
  incompleteEvents: SessionEvent[];
  eventNameUpdates: {
    eventId: string;
    value: string;
  }[];
  hasPastSessions: boolean;
  onEventNameUpdate: (eventId: any, value: any) => void;
};
const EventsAdminContext = createContext<State>({} as State);

export const EventsAdminContextProvider = ({ children }) => {
  const { artistGroupId } = useParams<any>();
  const { gotoDefault } = useEventNavigation();
  const [searchText, setSearchText] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<EventFilterOptions>(
    (localStorage.getItem("selectedFilter") as EventFilterOptions) || "upcoming"
  );
  const { sessions, incompleteEvents, isLoading } = useData(artistGroupId);
  // used for updating frontend values optimistically while fb index updates
  const [eventNameUpdates, setEventNameUpdates] = useState([]);

  const onEventNameUpdate = (eventId, value) =>
    setEventNameUpdates((p) => {
      const update = p.find((pi) => pi.eventId === eventId);
      if (update) {
        update.value = value;
        return p;
      }
      return [...p, { eventId, value }];
    });

  const _setSelectedFilter = (filter: EventFilterOptions) => {
    storeSelectedFilter(filter);
    setSearchText("");
    setSelectedFilter(filter);
    gotoDefault();
  };

  let filteredSessions;
  let eventIds;
  let defaultEventId;
  let hasPastSessions = false;

  if (!isLoading) {
    hasPastSessions =
      sessions.filter(getEventFilterFunction("past")).length > 0;
    filteredSessions = sessions
      .filter((s) => compareSessions(s, searchText))
      .filter(getEventFilterFunction(selectedFilter))
      .sort(getEventSortFunction(selectedFilter));
    eventIds = filteredSessions.map((s) => s.eventId).filter(unique);
    if (selectedFilter === "upcoming")
      incompleteEvents
        .filter((e) => compareIncompleteEvents(e, searchText))
        .forEach(({ eventId }) => eventIds.unshift(eventId));
    defaultEventId = eventIds[0];
  }

  const value = {
    hasPastSessions,
    eventNameUpdates,
    artistGroupId,
    eventIds,
    defaultEventId,
    sessions: filteredSessions,
    searchText,
    setSearchText,
    selectedFilter,
    onEventNameUpdate,
    setSelectedFilter: _setSelectedFilter,
    incompleteEvents,
  };

  return (
    <EventsAdminContext.Provider value={value}>
      {isLoading ? <AppLoading /> : children}
    </EventsAdminContext.Provider>
  );
};

export const useEventsAdminContext = () => useContext(EventsAdminContext);
