import {
  AppLoading,
  Div,
  IconWrapper as IconWrapperBase,
} from "@max/common-ui";
import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import AssetModal from "./AssetModal";
import { Icon } from "notes";

const IconWrapper = styled(IconWrapperBase)`
  border-radius: 4px;
  background: #101010;
  height: 40px;
  width: 40px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
  :hover {
    background: ${(props) => props.theme.colors.white};
    svg {
      path {
        fill: #101010;
      }
    }
  }
`;

export const AssetsModal = ({ label, onClose, AssetComponent, query }) => {
  const [assets, isLoadingAssets] = useCollectionData<any>(query);

  return (
    <>
      {isLoadingAssets && !assets ? (
        <AppLoading />
      ) : (
        <AsssetsModalWithData {...{ label, onClose, assets, AssetComponent }} />
      )}
    </>
  );
};

const getAssetsArr = (assets, label) => {
  return assets.reduce((prev, curr, idx) => {
    return [
      ...prev,
      {
        title: `Meet & Greet ${label} ${idx + 1} (Desktop)`,
        downloadUrl: curr?.downloads?.landscape,
        source: curr?.source?.landscape,
      },
      {
        title: `Meet & Greet ${label} ${idx + 1} (Mobile)`,
        downloadUrl: curr?.downloads?.portrait,
        source: curr?.source?.portrait,
        isMobile: true,
      },
    ];
  }, []);
};

const AsssetsModalWithData = ({ assets, label, onClose, AssetComponent }) => {
  const [selectedAssetIdx, setSelectedAssetIdx] = useState(0);
  const assetsArr = getAssetsArr(assets, label);

  useEffect(() => {
    if (assetsArr.length === 0) {
      alert(`No ${label}s found`);
      onClose();
    }
  }, []);

  return (
    <>
      {assetsArr
        .filter((p, i) => i === selectedAssetIdx)
        .map(({ title, downloadUrl, source, isMobile }, index) => {
          const isFirst = selectedAssetIdx === 0;
          const isLast = selectedAssetIdx === assetsArr.length - 1;

          const leftArrow = (
            <Div mr_34 w_40>
              {!isFirst && (
                <IconWrapper
                  rotate180
                  onClick={() => setSelectedAssetIdx(selectedAssetIdx - 1)}
                >
                  <Icon name="Right" />
                </IconWrapper>
              )}
            </Div>
          );

          const rightArrow = (
            <Div ml_34 w_40>
              {!isLast && (
                <IconWrapper
                  onClick={() => setSelectedAssetIdx(selectedAssetIdx + 1)}
                >
                  {!isLast && <Icon name="Right" />}
                </IconWrapper>
              )}
            </Div>
          );

          return (
            <Div key={index}>
              <AssetModal
                downloadUrl={downloadUrl}
                title={title}
                onClose={onClose}
              >
                <Div dflex alignCenter>
                  {leftArrow}
                  <AssetComponent {...{ isMobile, source }} />
                  {rightArrow}
                </Div>
              </AssetModal>
            </Div>
          );
        })}
    </>
  );
};
