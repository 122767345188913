import { MeetGreetIndex } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { SessionEvent, EventFilterOptions } from "./index";

/**
 * Return true if `v` is the first occurence in `a`.  Useful for removing
 * duplicates from an array.
 */
export const unique = (v: unknown, i: number, a: unknown[]) =>
  a.indexOf(v) === i;

const match = (field: string, searchText: string) =>
  field?.toLowerCase().includes(searchText?.toLowerCase());

export const compareSessions = (session: MeetGreetIndex, searchText = "") =>
  match(session.eventName, searchText) ||
  match(session.name, searchText) ||
  match(session.artist, searchText);

export const compareIncompleteEvents = (event: SessionEvent, searchText = "") =>
  match(event.eventName, searchText);

export const getEventFilterFunction = (selectedFilter: EventFilterOptions) => {
  return (session: MeetGreetIndex) => {
    const diff = session.endDate.diffNow().milliseconds;
    if (selectedFilter === "upcoming") return diff >= 0;
    else if (selectedFilter === "past") return diff < 0;
  };
};

export const getEventSortFunction = (selectedFilter: EventFilterOptions) => {
  if (selectedFilter === "upcoming")
    return (a: MeetGreetIndex, b: MeetGreetIndex) =>
      a.startDate.diff(b.startDate).milliseconds > 0 ? 1 : -1;
  if (selectedFilter === "past")
    return (a: MeetGreetIndex, b: MeetGreetIndex) =>
      a.startDate.diff(b.startDate).milliseconds > 0 ? -1 : 1;
};
