import { Div } from "@max/common-ui";
import { useEffect, useRef, useState } from "react";
import { Button } from "../SessionDetail/Header";
import { Icon } from "notes";
import { DetailField } from ".";
import styled from "styled-components";

const CopiedToClipboardContainer = styled(Div)`
  background-color: #07090f;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  position: absolute;
  top: -32px;
  right: 0;
  border-radius: 4px;
  color: white;
`;

const PlatformInformation = styled(Div)`
  position: relative;
  margin-left: 32px;
  @media only screen and ${(props) => props.theme.media.desktop} {
    justify-content: flex-start;
    margin-left: 55px;
  }
`;

export const JoinSessionButton = ({
  sessionId,
  startDate,
  isEventButton = false,
}) => {
  const joinUrl = `${process.env.REACT_APP_VIDEO_SITE_URL}/${sessionId}/login`;
  const intervalRef = useRef(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [timeDiff, setTimeDiff] = useState(
    startDate.diffNow(["days", "hours", "minutes", "seconds"]).values
  );

  useEffect(() => {
    clearInterval(intervalRef.current);

    const updateTimeDiff = () =>
      setTimeDiff(
        startDate.diffNow(["days", "hours", "minutes", "seconds"]).values
      );
    updateTimeDiff();

    intervalRef.current = setInterval(updateTimeDiff, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [startDate]);

  const { days, hours, minutes, seconds } = timeDiff;
  // session enabled 1 hour before start time
  const isDisabled = days * 24 + hours > 1;

  const getCountdown = () => {
    const f = (v) => ("0" + Math.ceil(v)).slice(-2);
    if (days > 0) return `${days} day${days > 1 ? "s" : ""}`;
    return `${f(hours)}:${f(minutes)}:${f(seconds)}`;
  };

  const onCopyToClipboard = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2300);
  };

  return (
    <Div>
      <DetailField icon={<Icon form name="VideoChat" />}>
        <Button disabled={isDisabled} onClick={() => window.open(joinUrl)}>
          {isDisabled
            ? `${isEventButton ? "Next " : ""}Session in ${getCountdown()}`
            : `Join${isEventButton ? " Next" : ""} Session`}
        </Button>
      </DetailField>
      <PlatformInformation dflex ml_55 mt_5 c_gray30 fs_12 lite>
        {copiedToClipboard && (
          <CopiedToClipboardContainer>Link copied</CopiedToClipboardContainer>
        )}
        <Div style={{ whiteSpace: "nowrap" }}>Video Platform:</Div>
        <Div
          alignCenter
          clickable
          onClick={() => {
            navigator.clipboard.writeText(joinUrl);
            onCopyToClipboard();
          }}
        >
          <Div
            bold
            c_gray30
            ml_5
            truncateText
            style={{ overflowWrap: "anywhere" }}
          >
            {joinUrl}
          </Div>
          <Div ml_8 alignCenter>
            <Icon tertiary name="Duplicate" />
          </Div>
        </Div>
      </PlatformInformation>
    </Div>
  );
};
