export const imageFields = [
  {
    name: "desktopImg",
    label: "Desktop image",
    aspectRatio: 2 / 1,
    description:
      "Upload an image to use as the header image on the desktop view of your store. An image that does NOT have text will work best. The image uploader below will allow you to crop your image to size.",
    dropzoneDescription:
      "(High-resolution images of 1000 x 480 pixels or with an aspect ratio of 2:1 are recommended)",
    width: "60%",
  },
  {
    name: "mobileImg",
    label: "Mobile image",
    aspectRatio: 1 / 1,
    description:
      "Upload an image to use as the header image on the mobile view of your store and in emails fans receive. An image that does NOT have text will work best. The image uploader below will allow you to crop your image to size.",
    dropzoneDescription:
      "(High-resolution images of 500 x 500 pixels or with an aspect ratio of 1:1 are recommended)",
    width: "30%",
  },
];
