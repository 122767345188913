import { Div } from "@max/common-ui";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import { fstore } from "firebase-tools";
import React from "react";
import { AssetsModal } from "./AssetsModal";

const AssetComponent = ({ isMobile, source }) => {
  return (
    <>
      {isMobile ? (
        <Div w_1000 centered>
          <img width="250px" height="500px" src={source} />
        </Div>
      ) : (
        <img width="1000px" height="500px" src={source} />
      )}
    </>
  );
};

export const PhotosModal = ({ uid, isOpen, onClose }) => {
  const { routeSessionId } = useEventDetailContext();

  return (
    <>
      {isOpen && (
        <AssetsModal
          label="Photo"
          onClose={onClose}
          AssetComponent={AssetComponent}
          query={fstore
            .collection(`/meet_greet/${routeSessionId}/rooms/${uid}/photos`)
            .where("status", "==", "approved")}
        />
      )}
    </>
  );
};
