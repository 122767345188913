import {
  Div,
  ErrorMessageWarning,
  FormikErrorFocus,
  Input,
  MobileDivider,
  SelectInput,
  TextareaInput,
  TextEditorInput,
} from "@max/common-ui";
import { priceTag } from "components/common/assets/svgs";
import { Form, Formik } from "formik";
import { Icon, Input as InputBase } from "notes";
import styled from "styled-components";
import * as yup from "yup";
import { FieldLabel, FormContentWrapper, FormSection } from "./form";

const RAlignInput = styled(InputBase)`
  text-align: right;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const Container = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const PlaceholderAlignmentHack = styled.div`
  & > div > div > div > div:first-of-type {
    left: initial;
    right: 13px;
  }
`;

const requiredFieldMsg = "This is a required field and cannot be blank.";
const yupRequired = yup.string().nullable().required(requiredFieldMsg);
const validationSchema = yup.object().shape({
  welcomeMessage: yupRequired,
  pricePerFan: yup
    .number()
    .nullable()
    .required(requiredFieldMsg)
    .min(1, "must be positive"),
  callDurationSeconds: yupRequired,
});

export const callDurationOptions = [
  { id: "30", text: "30 seconds" },
  { id: "60", text: "1 minute" },
  { id: "90", text: "90 seconds" },
  { id: "120", text: "2 minutes" },
  { id: "180", text: "3 minutes" },
  { id: "240", text: "4 minutes" },
  { id: "300", text: "5 minutes" },
  { id: "600", text: "10 minutes" },
  { id: "900", text: "15 minutes" },
];

export const TicketSiteFormFields = ({
  ticketSiteData,
  onSubmit,
  FormButtons,
}) => {
  const inputWidthShort = "183px";

  const initialValues = ticketSiteData;

  const messagingFieldsUI = (
    <>
      <FieldLabel>Welcome message</FieldLabel>
      <TextEditorInput
        name="welcomeMessage"
        placeholder="This will be an introductory message to your fans who wish to join the meet & greet. Hype them up. Get them excited…"
      />
      <FieldLabel>Rules and Limitations (optional)</FieldLabel>
      <TextareaInput
        name="rulesLimitations"
        placeholder="This is an overview of any prohibited subject matter, questions, requests, etc. along with any rules or limitations…"
      />
    </>
  );

  const pricingFieldsUI = (
    <>
      <FieldLabel>Fan call duration</FieldLabel>
      <SelectInput name="callDurationSeconds" options={callDurationOptions} />
      <FieldLabel>Price per fan call</FieldLabel>
      <PlaceholderAlignmentHack>
        <Input
          leftIcon={
            <Div mt_n2>
              <Icon form name="USD" />
            </Div>
          }
          type="number"
          width={inputWidthShort}
          name="pricePerFan"
          placeholder="00"
          Component={RAlignInput}
        />
      </PlaceholderAlignmentHack>
    </>
  );

  return (
    <Container mcontainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, errors, submitCount }) => (
          <Form>
            {Object.keys(errors).length > 0 && submitCount > 0 && (
              <Div mb_30>
                <ErrorMessageWarning />
              </Div>
            )}
            <Div>
              <FormSection
                header="Messaging"
                icon={<Icon form name="Description" />}
                description="The fields below will be used to create the sales page for your Meet & Greet including pricing, rules and the sales message."
              />
            </Div>
            <FormContentWrapper>{messagingFieldsUI}</FormContentWrapper>
            <MobileDivider mt_32 mb_n18 />
            <Div mt_40>
              <FormSection
                header="Pricing"
                icon={priceTag}
                description="Below please specify how much you like to charge fans per call and how long you would like to allot for each call."
              />
            </Div>
            <FormContentWrapper>{pricingFieldsUI}</FormContentWrapper>
            <FormButtons onSubmit={submitForm} />
            <FormikErrorFocus />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
