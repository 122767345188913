import { Div } from "@max/common-ui";
import { useState } from "react";
import InfoModal from "./InfoModal";
import { Icon } from "notes";

const LabelWithInfo = ({ label, infoHeader, info, className = "" }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <Div positionRelative>
      <Div dflex clickable alignCenter onClick={() => setIsInfoModalOpen(true)}>
        <Div fs_17 c_text bold mr_7 className={className}>
          {label}
        </Div>
        <Div alignCenter>
          <Icon indicator name="Information" />
        </Div>
      </Div>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={infoHeader}
        text={info}
      />
    </Div>
  );
};

export default LabelWithInfo;
