import { EventFilterOptions } from "components/EventsAdmin/EventsAdminContextProvider";
import { css } from "styled-components";

export const paddingLeftCss = css`
  padding-left: 22px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-left: 3%;
  }
`;

export const paddingRightCss = css`
  padding-right: 22px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-left: 3%;
  }
`;

export const getArtistAdminsList = (admins) => {
  if (admins.length === 1) return admins[0];
  else if (admins.length > 1) {
    let res = admins.slice(0, admins.length - 1).join(", ");
    res += ` or ${admins[admins.length - 1]}`;
    return res;
  } else return "your administrator";
};

export const storeSelectedFilter = (filter: EventFilterOptions) =>
  localStorage.setItem("selectedFilter", filter);
