import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
var VideoRecorderContext = createContext(null);
var getDeviceOptions = function (devices, kind) {
    var dvs = devices.filter(function (device) { return device.kind === kind; });
    return dvs.map(function (inp) { return ({
        text: inp.label,
        id: inp.deviceId,
    }); });
};
var ls = localStorage;
var LS_PREFERENCES_KEY = "preferences";
var LS_MEDIA_PREFERENCES_KEY = "media_preferences";
var lsPreferences = JSON.parse(ls.getItem(LS_PREFERENCES_KEY));
var lsMediaPreferences = JSON.parse(ls.getItem(LS_MEDIA_PREFERENCES_KEY));
export var VideoRecordContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), devices = _b[0], setDevices = _b[1];
    var _c = useState(lsMediaPreferences === null || lsMediaPreferences === void 0 ? void 0 : lsMediaPreferences.selectedAudioDeviceId), selectedAudioDeviceId = _c[0], setSelectedAudioDeviceId = _c[1];
    var _d = useState(lsMediaPreferences === null || lsMediaPreferences === void 0 ? void 0 : lsMediaPreferences.selectedVideoDeviceId), selectedVideoDeviceId = _d[0], setSelectedVideoDeviceId = _d[1];
    var _e = useState(false), userIsRecordingVideo = _e[0], setUserIsRecordingVideo = _e[1];
    var _f = useState(lsPreferences || {
        autoPlay: false,
        saveSelectedVideoAudioDeviceIds: false,
    }), preferences = _f[0], setPreferences = _f[1];
    var updateMediaPreferences = function () {
        if (preferences.saveSelectedVideoAudioDeviceIds)
            ls.setItem(LS_MEDIA_PREFERENCES_KEY, JSON.stringify({
                selectedAudioDeviceId: selectedAudioDeviceId,
                selectedVideoDeviceId: selectedVideoDeviceId,
            }));
        else
            ls.removeItem(LS_MEDIA_PREFERENCES_KEY);
    };
    useEffect(function () {
        updateMediaPreferences();
    }, [selectedAudioDeviceId, selectedVideoDeviceId]);
    useEffect(function () {
        updateMediaPreferences();
        ls.setItem(LS_PREFERENCES_KEY, JSON.stringify(preferences));
    }, [preferences]);
    var videoOptions = [];
    var audioOptions = [];
    if (devices) {
        videoOptions = getDeviceOptions(devices, "videoinput");
        audioOptions = getDeviceOptions(devices, "audioinput");
    }
    return (React.createElement(VideoRecorderContext.Provider, { value: {
            videoOptions: videoOptions,
            audioOptions: audioOptions,
            selectedAudioDeviceId: selectedAudioDeviceId,
            selectedVideoDeviceId: selectedVideoDeviceId,
            preferences: preferences,
            userIsRecordingVideo: userIsRecordingVideo,
            setDevices: setDevices,
            setUserIsRecordingVideo: setUserIsRecordingVideo,
            setPreferences: setPreferences,
            setSelectedAudioDeviceId: setSelectedAudioDeviceId,
            setSelectedVideoDeviceId: setSelectedVideoDeviceId,
        } }, children));
};
export var useVideoRecordContext = function () { return useContext(VideoRecorderContext); };
