import { WatermarkSource as _WatermarkSource } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ThemeContext } from "styled-components";

export const WatermarkSource = _WatermarkSource;

const ticketSiteDataInitialValues = (artistName) => ({
  welcomeMessage: `This is your opportunity to schedule a video call with ${artistName} and take a virtual photo together!`,
  rulesLimitations:
    "This is an opportunity to have a video chat with the artist and should not be used to ask for endorsements. Please be courteous to the artist.  The artist or a member of their team may end the call early at their discretion.",
  pricePerFan: "",
  callDurationSeconds: "90",
});

export const watermarkInitialValues = {
  watermarkConfig: {
    includesGradient: false,
    desktopPosition: null,
    mobilePosition: null,
  },
  watermarkImgUrl: null,
  watermarkImages: {
    desktopImg: { blob: null, isInitialValue: false },
    mobileImg: { blob: null, isInitialValue: false },
  },
};

const mediaDataInitialValues = {
  includeVideo: false,
  includeWatermark: false,
  recordingBlob: { blob: null, isInitialValue: false },
  selectedWatermarkOption: WatermarkSource.custom,
  ...watermarkInitialValues,
  artistImages: {
    desktopImg: {
      blob: null,
      isInitialValue: false,
    },
    mobileImg: {
      blob: null,
      isInitialValue: false,
    },
  },
};

type State = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  completedSteps: number;
  setCompletedSteps: React.Dispatch<any>;
  ticketSiteData: {
    welcomeMessage: string;
    rulesLimitations: string;
    pricePerFan: string;
    callDurationSeconds: string;
  };
  setTicketSiteData: React.Dispatch<any>;
  mediaData: typeof mediaDataInitialValues;
  setMediaData: React.Dispatch<any>;
  sessionData: any;
  setSessionData: React.Dispatch<any>;
  eventId: string;
  setEventId: React.Dispatch<string>;
  artistGroupId: any;
};
const ScheduleMeetGreetContext = createContext<State>({} as State);

const useFormData = (artistName: string) => {
  const [ticketSiteData, setTicketSiteData] = useState<any>(
    ticketSiteDataInitialValues(artistName)
  );
  const [mediaData, setMediaData] = useState<any>(mediaDataInitialValues);
  const [sessionData, setSessionData] = useState<any>({
    sessions: [],
  });

  const value = {
    ticketSiteData,
    setTicketSiteData,
    mediaData,
    setMediaData,
    sessionData,
    setSessionData,
  };

  return value;
};

export const ScheduleMeetGreetContextProvider = ({ artistName, children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { artistGroupId } = useParams<any>();
  const [completedSteps, setCompletedSteps] = useState(0);
  const [eventId, setEventId] = useState<string | null>(
    localStorage.getItem("eventId")
  );

  const {
    colors: { white: whiteColor },
  } = useContext(ThemeContext);

  useEffect(() => {
    if (eventId) localStorage.setItem("eventId", eventId);
  }, [eventId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.backgroundColor = whiteColor;
    setCompletedSteps(Math.max(currentStep, completedSteps));
  }, [currentStep]);

  const value = {
    currentStep,
    setCurrentStep,
    completedSteps,
    setCompletedSteps,
    eventId,
    setEventId,
    artistGroupId,
    ...useFormData(artistName),
  };
  return (
    <ScheduleMeetGreetContext.Provider value={value}>
      {children}
    </ScheduleMeetGreetContext.Provider>
  );
};

export const useScheduleMeetGreetContext = () =>
  useContext(ScheduleMeetGreetContext);
