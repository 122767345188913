import {
  AppLoading,
  Div,
  IconWrapper as IconWrapperBase,
} from "@max/common-ui";
import {
  IndexedRole,
  RoleType,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { fstore } from "firebase-tools";
import { Icon } from "notes";
import { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import styled, { css } from "styled-components";
import { useEventDetailContext } from "../../EventDetailContextProvider";
import ChatModal from "./ChatModal";
import { PhotosModal } from "./PhotosModal";
import { VideosModal } from "./VideosModal";

const IconWrapper = styled(IconWrapperBase)`
  margin: 0 3px;
  :hover {
    background: ${(props) => props.theme.colors.gray10};
  }
`;

const FanWrapper = styled(Div)`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
  padding: 5px;
  ${(props) =>
    props.isSelected &&
    css`
      background: ${(props) => props.theme.colors.background};
    `}
`;

export const AttendeesSummary = () => {
  const { routeSessionId } = useEventDetailContext();
  const [rooms, isLoadingRooms] = useCollection(
    fstore.collection(`/meet_greet/${routeSessionId}/rooms`)
  );

  return (
    <>
      {isLoadingRooms ? (
        <AppLoading />
      ) : (
        <AttendeesSummaryWithData
          rooms={rooms.docs.map((r) => ({ id: r.id, doc: r.data() }))}
        />
      )}
    </>
  );
};

const AttendeesSummaryWithData = ({ rooms }) => {
  const {
    session: { slotCount, roles },
  } = useEventDetailContext();
  const fans = roles.filter((r) => r.role === RoleType.fan);
  const attended = rooms.filter((r) => r?.doc?.totalTime > 0).length;
  const [selectedFanId, setSelectedFanId] = useState<string | null>(null);
  const _fans = fans.map((f) => ({
    ...f,
    attended: rooms.find((r) => r.id === f.uid)?.doc?.totalTime > 0,
  }));

  const headerUI = (
    <>
      <Div mt_15 alignCenter>
        <Div mr_3 mt_2>
          Attendees({fans.length}/{slotCount})
        </Div>
        {/* <IconWrapper title="Email" svg_gray30>
          <Icon tertiary name="Email" />
        </IconWrapper> */}
      </Div>
      <Div lite c_gray30 fs_12>
        {attended} attendeed, {fans.length - attended} no shows
      </Div>
    </>
  );

  return (
    <Div grow mcontainer>
      {headerUI}
      <Div mt_16>
        {_fans
          .sort((a, b) => (a.attended ? -1 : 1))
          .map((fan, index) => (
            <Div key={index} dflex>
              <Fan
                {...{ ...fan, rooms, setSelectedFanId }}
                isSelected={selectedFanId === fan.uid}
              />
            </Div>
          ))}
      </Div>
    </Div>
  );
};

type FanProps = IndexedRole & {
  rooms: any;
  isSelected: boolean;
  setSelectedFanId: React.Dispatch<React.SetStateAction<string>>;
};
const Fan = ({
  uid,
  name,
  email,
  rooms,
  isSelected,
  setSelectedFanId,
}: FanProps) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const room = rooms.find((r) => r.id === uid)?.doc;
  const attended = room?.totalTime > 0;

  const nameUI = (
    <Div alignCenter>
      {attended ? (
        <Div svg_green20>
          <Icon badge name="FacebookVerified" />
        </Div>
      ) : (
        <Div svg_red20>
          <Icon form name="RemoveCircle" />
        </Div>
      )}
      <Div ml_12 fs_15 c_text>
        <Div truncateText mr_15>
          {name || email}
        </Div>
      </Div>
    </Div>
  );

  const iconsUI = (
    <Div svg_gray30 c_gray30 dflex>
      {room?.chatCount > 0 && (
        <IconWrapper
          title="Chat History"
          onClick={() => setIsChatModalOpen(true)}
        >
          <Icon tertiary name="Description" />
        </IconWrapper>
      )}
      {room?.photoCount > 0 && (
        <IconWrapper
          title="Pictures"
          onClick={() => setIsPhotosModalOpen(true)}
        >
          <Icon selector name="Picture" height="18" />
        </IconWrapper>
      )}
      {room?.videoCount > 0 && (
        <IconWrapper
          title="Recordings"
          onClick={() => setIsVideoModalOpen(true)}
        >
          <Icon tertiary name="Video" />
        </IconWrapper>
      )}
      {/* <IconWrapper title="Text">
        <Icon tertiary name="Message" />
      </IconWrapper>
      <IconWrapper title="Email">
        <Icon tertiary name="Email" />
      </IconWrapper> */}
    </Div>
  );

  const modalsUI = (
    <>
      <PhotosModal
        uid={uid}
        isOpen={isPhotosModalOpen}
        onClose={() => setIsPhotosModalOpen(false)}
      />
      <VideosModal
        uid={uid}
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
      />
      {isChatModalOpen && (
        <ChatModal
          fanName={name}
          uid={uid}
          onClose={() => setIsChatModalOpen(false)}
        />
      )}
    </>
  );

  return (
    <>
      {modalsUI}
      <FanWrapper
        isSelected={isSelected}
        alignCenter
        w100
        spaceBetween
        onClick={() => setSelectedFanId(uid)}
      >
        {nameUI}
        {iconsUI}
      </FanWrapper>
    </>
  );
};
