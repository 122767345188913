import { Div } from "@max/common-ui";
import { useEventDetailContext } from "../EventDetailContextProvider";
import { useEventsAdminContext } from "../EventsAdminContextProvider";
import UpcomingAttendees from "./UpcomingAttendees";
import Header from "./Header";
import SessionTeammates from "./SessionTeammates";
import { AttendeesSummary } from "./AttendeesSummary";
import { TeammatesSummary } from "./TeammatesSummary";
import styled from "styled-components";

const Container = styled(Div)`
  @media only screen and ${(props) => props.theme.media.desktop} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Section = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-bottom: 32px;
  @media only screen and ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    padding-top: 24px;
    padding-right: 16px;
    padding-bottom: 0;
  }
`;

const SessionDetail = () => {
  const { session } = useEventDetailContext();
  return <>{session && <SessionDetailWithData />}</>;
};

const SessionDetailWithData = () => {
  const { selectedFilter } = useEventsAdminContext();

  return (
    <Container yscroll h100 grow>
      <Header />
      {selectedFilter === "upcoming" ? (
        <Section>
          <UpcomingAttendees />
          <SessionTeammates />
        </Section>
      ) : (
        <Div pr_25>
          <AttendeesSummary />
          <Div mt_50>
            <TeammatesSummary />
          </Div>
        </Div>
      )}
    </Container>
  );
};

export default SessionDetail;
