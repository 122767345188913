import { AppContainer } from "@max/common-ui";
import { MeetGreetIndex } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useAppContext } from "AppContextProvider";
import FormLayout from "components/common/FormLayout";
import { shoutoutI } from "firebase-tools";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";
import { EVENT_ADMIN_ROUTES } from ".";
import SessionFormFields from "../common/SessionFormFields";
import {
  SessionEvent,
  useEventsAdminContext,
} from "./EventsAdminContextProvider";
export type CreateSessionRequest = shoutoutI.functions.meetgreet.CreateSessionRequest;
export type CreateSessionRequestMember = shoutoutI.functions.meetgreet.CreateSessionRequestMember;

const getDate = (date, time) =>
  DateTime.fromJSDate(new Date(date.getTime() + time * 1000));

const AddSession = () => {
  const { sessions, incompleteEvents } = useEventsAdminContext();
  return <>{sessions && incompleteEvents && <AddSessionWithData />}</>;
};

const getSavedSessions = (
  sessions,
  eventId,
  pricePerFan,
  callDurationSeconds
) =>
  sessions
    .filter((s) => s.eventId === eventId)
    .map((s) => ({
      startDate: s.startDate.toLocal().toString(),
      startTime: s.startDate
        .toLocal()
        .diff(s.startDate.toLocal().startOf("day"), "seconds").seconds,
      maximumAttendees: s.slotCount,
      teammates: s.roles.map((r) => ({
        uid: r.uid,
        name: r.name ? r.name : r.email,
        email: r.email,
        isAdmin: r.isAdmin,
        role: r.role,
      })),
      id: s.sessionId,
      callDurationSeconds: callDurationSeconds,
      pricePerFan: pricePerFan,
    }));

const AddSessionWithData = () => {
  const { sessions, incompleteEvents } = useEventsAdminContext();
  const { eventId, artistGroupId } = useParams<any>();
  let event: MeetGreetIndex | SessionEvent = sessions.find(
    (s) => s.eventId === eventId
  );
  if (!event) event = incompleteEvents.find((e) => e.eventId === eventId);
  const history = useHistory();

  const { setAppIsLoading } = useAppContext();

  const {
    eventName,
    unitPrice: pricePerFan,
    sessionDuration: callDurationSeconds,
  } = event;

  const [sessionData, setSessionData] = useState(
    getSavedSessions(sessions, eventId, pricePerFan, callDurationSeconds)
  );

  const onGoBack = () =>
    history.push(
      `${generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`,
        {
          artistGroupId,
          eventId,
        }
      )}`
    );

  const onSubmit = async (values, { resetForm }) => {
    setAppIsLoading(true);

    const getMembers = (): CreateSessionRequestMember[] =>
      values.teammates.map((t) => ({
        uid: t.uid,
        role: t.role,
        isAdmin: !!t.isAdmin,
      }));

    const session: CreateSessionRequest = {
      eventId,
      slots: parseInt(values.maximumAttendees),
      startDate: getDate(values.startDate, parseInt(values.startTime)),
      members: getMembers(),
    };
    const res = await shoutoutI.functions().meetgreet.createSession(session);
    setAppIsLoading(false);
    if (!res.error) {
      const sessionId = res.result.id;
      console.log({ sessionId });
      setSessionData([
        ...sessionData,
        { ...values, id: sessionId, callDurationSeconds, pricePerFan },
      ]);
      resetForm();
    }
    return true;
  };

  return (
    <FormLayout label="ADD SESSION" title={eventName} onGoBack={onGoBack}>
      <AppContainer>
        <SessionFormFields
          {...{
            pricePerFan,
            callDurationSeconds,
            eventId,
            sessionData,
            onSubmit,
            setSessionData,
          }}
        />
      </AppContainer>
    </FormLayout>
  );
};

export default AddSession;
