import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type State = {
  dragAreaRef: React.MutableRefObject<any>;
  dragAreaRect: any;
};
const DragAreaContext = createContext<State>({} as State);

export const DragAreaContextProvider = ({ children }) => {
  const dragAreaRef = useRef(null);
  const [dragAreaRect, setdragAreaRect] = useState(null);
  const value = { dragAreaRef, dragAreaRect };

  useEffect(() => {
    setdragAreaRect(dragAreaRef?.current?.getBoundingClientRect());
  }, [dragAreaRef]);

  return (
    <DragAreaContext.Provider value={value}>
      {children}
    </DragAreaContext.Provider>
  );
};

export const useDragAreaContext = () => useContext(DragAreaContext);
