var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useEffect, useState } from "react";
import ImageDropzone from "./ImageDropzone";
import styled, { css } from "styled-components";
import { ButtonPrimary, ButtonSecondary } from "notes";
import ImageCrop from "./ImageCrop";
import { Div, ModalContainer, ModalWrapper, Modal as ModalBase, } from "../../../..";
import { useIsMobile } from "../../../../..";
var imageStylesDesktop = {
    height: "300px",
};
var imageStylesMobile = {
    height: "250px",
};
var ImageWrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  overflow: hidden;\n  border: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n  @media all and ", " {\n    ", "\n  }\n"], ["\n  width: 100%;\n  overflow: hidden;\n  border: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n  @media all and ", " {\n    ", "\n  }\n"])), function (props) { return props.theme.colors.gray30; }, imageStylesDesktop, function (props) { return props.theme.media.mobile; }, imageStylesMobile);
var Modal = styled(ModalBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", " {\n    margin-top: 7vh;\n  }\n  ", " {\n    max-width: 800px;\n    overflow-y: scroll;\n    max-height: 80vh;\n    margin-bottom: 100px;\n    @media all and ", " {\n      width: 70%;\n    }\n  }\n"], ["\n  ", " {\n    margin-top: 7vh;\n  }\n  ", " {\n    max-width: 800px;\n    overflow-y: scroll;\n    max-height: 80vh;\n    margin-bottom: 100px;\n    @media all and ", " {\n      width: 70%;\n    }\n  }\n"])), ModalContainer, ModalWrapper, function (props) { return props.theme.media.mobile; });
var HeaderText = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media all and ", " {\n    font-size: 17px;\n  }\n"], ["\n  @media all and ", " {\n    font-size: 17px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var DescriptionText = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media all and ", " {\n    font-size: 17px;\n  }\n"], ["\n  @media all and ", " {\n    font-size: 17px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var ButtonsWrapper = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n  margin-top: 52px;\n  @media all and ", " {\n    margin-top: 35px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n  margin-top: 52px;\n  @media all and ", " {\n    margin-top: 35px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var MobileButton = styled(Div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n  user-select: none;\n  ", ";\n  @media all and ", " {\n    display: none;\n  }\n"], ["\n  color: ", ";\n  font-weight: bold;\n  user-select: none;\n  ", ";\n  @media all and ", " {\n    display: none;\n  }\n"])), function (props) { return props.theme.colors.blue20; }, function (props) {
    return props.disabled
        ? css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          pointer-events: none;\n          opacity: 0.5;\n        "], ["\n          pointer-events: none;\n          opacity: 0.5;\n        "]))) : css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          :hover {\n            cursor: pointer;\n            opacity: 0.8;\n          }\n        "], ["\n          :hover {\n            cursor: pointer;\n            opacity: 0.8;\n          }\n        "])));
}, function (props) { return props.theme.media.desktop; });
var SelectImageModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, _b = _a.onSelect, onSelect = _b === void 0 ? null : _b, props = __rest(_a, ["isOpen", "onClose", "onSelect"]);
    useEffect(function () {
        if (isOpen)
            document.body.style.overflow = "hidden";
        else
            document.body.style.overflow = "auto";
    }, [isOpen]);
    return (React.createElement(Modal, { isOpen: isOpen, withCloseIcon: false, onClose: onClose, withBackdropClose: false },
        React.createElement(ModalContent, __assign({}, __assign({ onClose: onClose, onSelect: onSelect }, props)))));
};
var ModalContent = function (_a) {
    var onClose = _a.onClose, onSelect = _a.onSelect, props = __rest(_a, ["onClose", "onSelect"]);
    var _b = useState(null), image = _b[0], setImage = _b[1];
    var _c = useState(null), croppedImg = _c[0], setCroppedImg = _c[1];
    var label = props.label, aspectRatio = props.aspectRatio, description = props.description, dropzoneDescription = props.dropzoneDescription;
    var headerUI = (React.createElement(Div, { c_text: true },
        React.createElement(HeaderText, { fs_25: true, extraBold: true },
            "Add ",
            label),
        React.createElement(DescriptionText, { fs_17: true, lite: true, mt_5: true }, description)));
    var saveButtonUI = (React.createElement(Div, { onClick: function () {
            if (croppedImg) {
                onSelect(croppedImg);
                onClose();
            }
        } },
        React.createElement(Div, { forDesktop: true },
            React.createElement(ButtonPrimary, { disabled: !croppedImg }, "Save")),
        React.createElement(MobileButton, { ml_30: true, disabled: !croppedImg }, "Save")));
    var cancelButtonUI = (React.createElement(Div, { onClick: onClose },
        React.createElement(Div, { forDesktop: true, mr_28: true },
            React.createElement(ButtonSecondary, null, "Cancel")),
        React.createElement(MobileButton, null, "Cancel")));
    var isMobile = useIsMobile();
    var imageStyles = isMobile ? imageStylesMobile : imageStylesDesktop;
    return (React.createElement(React.Fragment, null,
        headerUI,
        React.createElement(Div, { mb_20: true },
            React.createElement(Div, { c_text: true, fs_15: true, mb_5: true, mt_20: true }, image ? "Preview" : "Image Upload"),
            !image ? (React.createElement(ImageDropzone, { onSelect: function (img) { return setImage(img); }, Wrapper: ImageWrapper, placeholderText: dropzoneDescription })) : (React.createElement(ImageCrop, { srcImg: image, options: { aspect: aspectRatio }, onCrop: function (img) { return setCroppedImg(img); }, Wrapper: ImageWrapper, style: imageStyles })),
            React.createElement(ButtonsWrapper, { flexRowReverse: true, mb_n15: true },
                saveButtonUI,
                cancelButtonUI))));
};
export default SelectImageModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
