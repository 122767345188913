import {
  AppLoading,
  Div,
  MobileDivider,
  Videojs,
  VideoRecorderWrapper,
} from "@max/common-ui";
import { priceTag } from "components/common/assets/svgs";
import { useEventNavigation } from "components/common/useEventNavigation";
import { Icon } from "notes";
import styled, { css } from "styled-components";
import EventHeader from "../common/EventHeader";
import { useEventDetailContext } from "../EventDetailContextProvider";
import { useEventsAdminContext } from "../EventsAdminContextProvider";
import maleSvg from "./assets/male.svg";
import femaleSvg from "./assets/female.svg";

const Container = styled(Div)`
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  @media only screen and ${(props) => props.theme.media.desktop} {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Label = styled(Div)`
  font-size: 11px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 300;
  font-style: italic;
`;

const ImageWrapper = styled(Div)`
  height: 142px;
  width: 71px;
  position: relative;
  ${(props) =>
    props.isDesktop &&
    css`
      width: 284px;
    `}
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  background: lightblue;
`;

const DividerVertical = styled(Div)`
  background: white;
  width: 2px;
  height: 100%;
`;

const DividerHorizontal = styled(Div)`
  background: white;
  height: 2px;
  width: 100%;
`;

const Img = styled("img")`
  height: 142px;
`;

const Svg = styled(Div)`
  height: 100%;
  width: calc(100% - 1px);
  background-position: 50% 15%;
  background-size: 140% 140%;
  pointer-events: none;
  background-image: url(${(props) => props.src});
`;

const HorizontalSvg = styled(Svg)`
  height: calc(50% - 1px);
  width: 100%;
`;

const WatermarkImg = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
`;

const CategoryTitle = styled(Div)`
  font-weight: 800;
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-grow: 1;
  }
`;

const ResponsiveWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.media.mobile} {
    & > ${Div}:last-child {
      margin-top: 24px;
    }
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    & > ${Div}:last-child {
      margin-left: 24px;
    }
  }
`;

const DetailsContainer = styled(Div)`
  padding-top: 22px;
  padding-left: 20px;
  @media only screen and ${(props) => props.theme.media.desktop} {
    border-top: 2px solid ${(props) => props.theme.palette.gray.lightest};
    margin-top: 30px;
    padding-top: 24px;
  }
`;

const IconContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-right: 16px;
  }
`;

const ContentContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-left: 32px;
  }
`;

const EditButtonContainer = styled(Div)`
  color: ${(props) => props.theme.palette.action.primary};
  @media only screen and ${(props) => props.theme.media.mobile} {
    color: ${(props) => props.theme.palette.gray.primary};
  }
`;

const Category = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-right: 20px;
    & + & {
      border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
      margin-top: 32px;
      padding-top: 22px;
    }
  }
`;

const SubsectionTitle = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const SubsectionContent = styled(Div)`
  p {
    margin: 0;
  }
`;

const EventDetail = () => {
  const { isLoadingEvent } = useEventDetailContext();
  return <>{isLoadingEvent ? <AppLoading /> : <EventDetailWithData />}</>;
};

const editButtonUI = (
  <EditButtonContainer dflex c_action alignCenter link>
    <Icon tertiary name="Pencil" />
    <Div ml_8 fs_17 bold forDesktop>
      Edit
    </Div>
  </EditButtonContainer>
);

const Section = ({ label, icon, children }) => {
  return (
    <Div mb_30>
      <Div dflex>
        <IconContainer c_gray30 w_16 mr_12 alignCenter>
          {icon}
        </IconContainer>
        <Div c_text fs_15 extraBold>
          {label}
        </Div>
      </Div>
      <ContentContainer mt_12 ml_28>
        {children}
      </ContentContainer>
    </Div>
  );
};

const Subsection = ({ label, children }) => {
  return (
    <Div mb_20>
      <SubsectionTitle fs_14 c_gray30>
        {label}
      </SubsectionTitle>
      <SubsectionContent fs_15 c_text mt_4>
        {children}
      </SubsectionContent>
    </Div>
  );
};

const EventDetailWithData = () => {
  const { selectedFilter } = useEventsAdminContext();
  const { event } = useEventDetailContext();
  const { gotoEditTicketSite, gotoEditMedia } = useEventNavigation();

  const getSessionDurationLabel = () => {
    const duration = event?.sessionDuration;
    if (duration === 30 || duration === 90) return `${duration} seconds`;
    const minutes = Math.ceil(event?.sessionDuration / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  };

  const pricingUI = (
    <Section label="Pricing" icon={priceTag}>
      <Subsection label="Fan call duration">
        {getSessionDurationLabel()}
      </Subsection>
      <Subsection label="Price per fan call">
        ${event?.unitPrice / 100}
      </Subsection>
    </Section>
  );

  const messagingUI = (
    <Section label="Messaging" icon={<Icon form name="Description" />}>
      <Subsection label="Welcome Message">
        <Div dangerouslySetInnerHTML={{ __html: event?.checkoutDescription }} />
      </Subsection>
      {event?.checkoutWhatToExpect && (
        <Subsection label=" Rules and limitations">
          {event?.checkoutWhatToExpect?.map((rule, index) => (
            <Div key={index}>
              <Div fs_15 c_text mt_5>
                {rule}
              </Div>
            </Div>
          ))}
        </Subsection>
      )}
    </Section>
  );

  const ticketSiteUI = (
    <Category>
      <Div dflex alignCenter mb_24>
        <CategoryTitle fs_17 c_text mr_20>
          Ticket Site
        </CategoryTitle>
        {selectedFilter === "upcoming" && (
          <Div onClick={gotoEditTicketSite}>{editButtonUI}</Div>
        )}
      </Div>
      {messagingUI}
      {pricingUI}
    </Category>
  );

  const mobileWatermarkPreviewUI = (
    <>
      <ImageWrapper>
        <HorizontalSvg src={maleSvg} />
        <DividerHorizontal />
        <HorizontalSvg src={femaleSvg} />
        <WatermarkImg>
          <Img src={event?.assets?.watermarkPortrait?.path} />
        </WatermarkImg>
      </ImageWrapper>
      <Label>Watermark (Mobile)</Label>
    </>
  );

  const desktopWatermarkPreviewUI = (
    <>
      <ImageWrapper isDesktop dflex>
        <Svg src={maleSvg} />
        <DividerVertical />
        <Svg src={femaleSvg} />
        <WatermarkImg>
          <Img src={event?.assets?.watermarkLandscape?.path} />
        </WatermarkImg>
      </ImageWrapper>
      <Label>Watermark (Desktop)</Label>
    </>
  );

  const watermarkPreviewUI = (
    <>
      {event?.assets?.watermarkLandscape?.path && (
        <Section
          label="Custom Watermark"
          icon={
            <Div svg_gray30>
              <Icon badge name="InstagramVerified" />
            </Div>
          }
        >
          <ResponsiveWrapper>
            <Div>{desktopWatermarkPreviewUI}</Div>
            <Div>{mobileWatermarkPreviewUI}</Div>
          </ResponsiveWrapper>
        </Section>
      )}
    </>
  );

  const artistImagesUI = (
    <Section label="Arist Images" icon={<Icon form name="Gallery" />}>
      <ResponsiveWrapper>
        <Div>
          <Img src={event?.assets?.preshowBanner?.path} />
          <Label>Header Image (Desktop)</Label>
        </Div>
        <Div>
          <Img src={event?.assets?.squareBanner?.path} />
          <Label>Header Image (Mobile)</Label>
        </Div>
      </ResponsiveWrapper>
    </Section>
  );

  const mediaUI = (
    <Category mt_40 mb_60>
      <Div dflex>
        <CategoryTitle fs_17 c_text mb_24 mr_20>
          Media
        </CategoryTitle>
        {selectedFilter === "upcoming" && (
          <Div onClick={gotoEditMedia}>{editButtonUI}</Div>
        )}
      </Div>
      {artistImagesUI}
      {event?.assets?.salesVideo?.path && (
        <Section
          label="Promotional Video Message"
          icon={<Icon form name="Video" />}
        >
          <VideoRecorderWrapper>
            <Videojs
              withObjectFitCover={true}
              width="100%"
              height="100%"
              src={event?.assets?.salesVideo?.path}
            />
          </VideoRecorderWrapper>
        </Section>
      )}
      {watermarkPreviewUI}
    </Category>
  );

  return (
    <Container h100 yscroll grow>
      <EventHeader />
      <MobileDivider />
      <DetailsContainer>
        {ticketSiteUI}
        {mediaUI}
      </DetailsContainer>
    </Container>
  );
};

export default EventDetail;
