import { AppLoading, Div, useIsMobile } from "@max/common-ui";
import styled from "styled-components";
import { Autocomplete, Icon } from "notes";
import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from ".";
import { ROUTES as APP_ROUTES } from "../../Routes";
import { fstore } from "firebase-tools";
import { useUserContext } from "UserContextProvider";

const InputWrapper = styled(Div)`
  width: ${(props) => props.width};
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
  }
`;

const Item = styled<any>("a")`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  display: block;
  ${(props) => props.theme.fonts.default}
  font-weight: ${(props) => (props.selected ? 400 : 300)};
  line-height: 22px;
  position: relative;
  padding: 17px 12px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const ArtistGroupSearch = () => {
  const { user, isAdmin } = useUserContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      if (isAdmin !== null) {
        const res = isAdmin
          ? await fstore.collection("artist_groups").get()
          : await fstore
              .collection("artist_groups")
              .where(`members.${user.uid}`, "!=", "")
              .get();
        setData(
          res?.docs?.map((d) => {
            const docData = d.data();
            const docId = d.id;
            return {
              artistGroupId: docId,
              name: docData.name,
            };
          })
        );
      }
    })();
  }, [isAdmin]);

  return (
    <Div>
      {!data ? <AppLoading /> : <ArtistGroupSearchWithData data={data} />}
    </Div>
  );
};

const ArtistGroupSearchWithData = ({ data }) => {
  const path = APP_ROUTES.ARTIST_MANAGEMENT;
  const { isAdmin } = useUserContext();
  const isMobile = useIsMobile();
  const [text, setText] = useState("");

  const search = async (searchTerm) =>
    new Promise((resolve, _) => {
      setTimeout(() => {
        const res = data.filter(
          ({ name }) =>
            name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
        if (!isAdmin && res.length === 0)
          res.push({ name: "No results found" });
        if (isAdmin)
          res.push({
            name: searchTerm,
            action: `Add "${searchTerm}" as new Artist`,
          });
        resolve(res);
      }, 450);
    });

  const leftIconUI = (
    <Div mt_n3>
      <Icon form name="Search" />
    </Div>
  );

  const ResultItem = ({ result: { name, action, artistGroupId } }) => {
    const history = useHistory();
    return (
      <Div>
        {action ? (
          <Item
            onClick={() =>
              history.push(path + ROUTES.ADD_ARTIST_GROUP, {
                artistGroupName: name,
              })
            }
          >
            <Div alignCenter>
              <Div mt_3 mr_14>
                <Icon tertiary name="Add" />
              </Div>
              <Div fs_17 bold>
                {action}
              </Div>
            </Div>
          </Item>
        ) : (
          <Item
            onClick={() => {
              if (artistGroupId)
                history.push(
                  generatePath(
                    `${APP_ROUTES.ARTIST_MANAGEMENT}${ROUTES.ARTIST_GROUP}`,
                    {
                      artistGroupId,
                    }
                  )
                );
            }}
          >
            {name}
          </Item>
        )}
      </Div>
    );
  };

  return (
    <Div mcontainer>
      <InputWrapper mt_50 width={isMobile ? "100%" : "704px"}>
        <Autocomplete
          placeholder="Search or add an artist by name..."
          selected={text}
          onChange={(v) => setText(v.label)}
          search={search}
          leftIcon={leftIconUI}
          Result={ResultItem}
        />
      </InputWrapper>
    </Div>
  );
};

export default ArtistGroupSearch;
