import { Div, shareMenuItems } from "@max/common-ui";
import styled from "styled-components";

export const ShareUrl = ({ url }) => {
  const CopyLinkComponent = shareMenuItems("24")[2].shareComponent;
  const copyLinkUI = (
    <Div centered mt_30 w100>
      <UrlWrapper dflex spaceBetween>
        <UrlText truncateText>{url}</UrlText>
        <CopyLinkComponent url={url}>
          <CopyLink link mt_4>
            Copy
          </CopyLink>
        </CopyLinkComponent>
      </UrlWrapper>
    </Div>
  );

  return (
    <Div w100>
      <Div centered>
        <Div dflex svg_text>
          {shareMenuItems("24")
            .slice(0, 2)
            .map((mi, index) => {
              const Share = mi.shareComponent;
              return (
                <Div key={index} clickable mr_12 ml_12>
                  <Share
                    style={{ outline: "none" }}
                    url={url}
                    onShareWindowClose={null}
                  >
                    {mi.icon}
                  </Share>
                </Div>
              );
            })}
        </Div>
      </Div>
      {copyLinkUI}
    </Div>
  );
};
const UrlWrapper = styled(Div)`
  padding: 10px 12px;
  color: ${(props) => props.theme.colors.text};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray20};
  width: 100%;
  display: flex;
  align-items: center;
`;
const UrlText = styled(Div)`
  width: calc(100% - 60px);
`;
const CopyLink = styled(Div)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.action};
  font-size: 15px;
  text-transform: uppercase;
  width: 45px;
  font-weight: 700;
`;
