var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Div } from "../../../../Div";
var DefaultWrapper = function (_a) {
    var children = _a.children;
    return React.createElement(Div, null, children);
};
var ImageCrop = function (_a) {
    var srcImg = _a.srcImg, _b = _a.options, options = _b === void 0 ? {} : _b, _c = _a.onCrop, onCrop = _c === void 0 ? null : _c, _d = _a.Wrapper, Wrapper = _d === void 0 ? DefaultWrapper : _d, _e = _a.style, style = _e === void 0 ? {} : _e;
    var imageRef = useRef(null);
    var _f = useState(__assign({ unit: "%", width: 70, x: 15, y: 15, aspect: 16 / 9 }, options)), crop = _f[0], setCrop = _f[1];
    var onCropEnd = function (_crop) {
        var image = imageRef.current;
        var canvas = document.createElement("canvas");
        var scaleX = image.naturalWidth / image.width;
        var scaleY = image.naturalHeight / image.height;
        canvas.width = _crop.width * scaleX;
        canvas.height = _crop.height * scaleY;
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, _crop.x * scaleX, _crop.y * scaleY, _crop.width * scaleX, _crop.height * scaleY, 0, 0, _crop.width * scaleX, _crop.height * scaleY);
        canvas.toBlob(function (blob) {
            onCrop(blob);
        }, "image/jpeg");
    };
    return (React.createElement(Wrapper, null,
        React.createElement(ReactCrop, { src: srcImg, crop: crop, onImageLoaded: function (img) { return (imageRef.current = img); }, onChange: function (c) { return setCrop(c); }, onComplete: function (c) { return onCropEnd(c); }, style: style, imageStyle: style })));
};
export default ImageCrop;
