import { Div, getDisplayPrice, useFormikField } from "@max/common-ui";
import { DatePicker as DatePickerBase, Icon, Input as InputBase } from "notes";
import styled, { css } from "styled-components";
import { useSessionFormContext } from "../../../SessionFormContextProvider";

const StyledInput = styled(InputBase)`
  border: none;
  width: 157px;
  :focus {
    box-shadow: none;
  }
`;

const DatePickerWrapper = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    input {
      padding-left: 28px;
    }
    svg {
      position: relative;
      left: -12px;
    }
  }
`;

const FieldWrapper = styled(Div)`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
  padding: 0 12px;
  ${(props) =>
    props.hasError &&
    css`
      border-bottom: solid 1px ${(props) => props.theme.colors.red20};
      @media only screen and ${(props) => props.theme.media.mobile} {
        border-width: 2px;
      }
    `}
  :focus {
    outline: none;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    height: 64px;
    padding: 0;
  }
`;

const MobileColumn = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

export const dropIconUI = (
  <Div dflex ml_10>
    <Div alignCenter>
      <Icon indicator name="Dropdown" />
    </Div>
  </Div>
);

export const Field = ({ name, children, hasError, errorMsg, ...props }) => {
  return (
    <FieldWrapper
      hasError={hasError}
      h_56
      alignCenter
      name={name}
      tabIndex="0"
      {...props}
    >
      <Div spaceBetween w100>
        <Div w100>{children}</Div>
        {hasError && (
          <>
            <Div alignCenter justifyEnd fs_15 c_red20 w_150 mr_10>
              {errorMsg}
            </Div>
          </>
        )}
      </Div>
    </FieldWrapper>
  );
};

export const DatePickerUI = ({
  disabled = false,
  value,
  setValue = null,
  placeholder = "",
}) => {
  return (
    <DatePickerWrapper>
      <DatePickerBase
        {...{ disabled, placeholder }}
        InternalField={StyledInput}
        selected={value}
        onSelect={(v) => setValue(v)}
      />
    </DatePickerWrapper>
  );
};

export const DatePicker = ({ name, placeholder }) => {
  const { value, setValue, hasError, errorMsg } = useFormikField(name);
  return (
    <Field style={{ paddingLeft: 0 }} {...{ name, hasError, errorMsg }}>
      <Div dflex>
        <DatePickerUI {...{ value, setValue, placeholder }} />
        {dropIconUI}
      </Div>
    </Field>
  );
};

export const HTMLInput = styled<any>("input")`
  outline: none;
  border: none;
  font-size: 17px;
  font-family: Overpass;
  color: ${(props) => props.theme.colors.text};
  background-color: transparent;
  padding: 0;
  width: 100%;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    font-size: 12px;
  }
  height: 22px;
  box-sizing: border-box;
  width: 265px;
  -moz-appearance: textfield;
  ${(props) => props.css};
  ${(props) =>
    props.isDisabled
      ? css`
          cursor: not-allowed;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
        `}
`;

export const getNumberLocaleString = (v) => {
  const res =
    typeof v === "number" ? v.toLocaleString() : parseFloat(v).toLocaleString();
  return res === "NaN" ? "-" : res;
};

export const NumberInputUI = ({
  value = null,
  isDisabled = false,
  placeholder = "",
  setValue = null,
  pricePerFan = "0",
}) => {
  const leftIconUI = (
    <Div h100 mr_12 svg_gray30 alignCenter>
      <Icon form name="Audience" />
    </Div>
  );

  return (
    <Div dflex alignCenter w100>
      {leftIconUI}
      <MobileColumn dflex spaceBetween w100>
        <Div dflex>
          <HTMLInput
            type="number"
            {...{ isDisabled, placeholder }}
            value={value || ""}
            onChange={(e) => setValue(e.target.value)}
          />
        </Div>
        {value && (
          <Div dflex lite c_gray30 fs_10 lh_14 alignCenter mr_15>
            <Div>Estimated revenue:</Div>
            <Div bold ml_5>
              {getDisplayPrice(value * parseFloat(pricePerFan))}
            </Div>
          </Div>
        )}
      </MobileColumn>
    </Div>
  );
};

export const NumberInput = ({ name, placeholder }) => {
  const { value, setValue, hasError, errorMsg } = useFormikField(name);
  const { pricePerFan } = useSessionFormContext();

  return (
    <Field {...{ name, hasError, errorMsg }}>
      <NumberInputUI {...{ pricePerFan, value, setValue, placeholder }} />
    </Field>
  );
};
