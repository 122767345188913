var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import styled, { css } from "styled-components";
import ImageInput from "./ImageInput";
import { Div } from "../..";
var ImageInputContainer = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  @media all and ", " {\n    margin-top: 30px;\n    ", "\n  }\n"], ["\n  ", "\n  @media all and ", " {\n    margin-top: 30px;\n    ", "\n  }\n"])), function (props) {
    return !props.isFirst && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-left: 20px;\n    "], ["\n      margin-left: 20px;\n    "])));
}, function (props) { return props.theme.media.mobile; }, function (props) {
    return !props.isFirst && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        margin-left: 0;\n      "], ["\n        margin-left: 0;\n      "])));
});
var ImgContainer = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  height: 188px;\n  @media only screen and ", " {\n    width: ", ";\n    height: 146px;\n  }\n"], ["\n  position: relative;\n  width: ", ";\n  height: 188px;\n  @media only screen and ", " {\n    width: ", ";\n    height: 146px;\n  }\n"])), function (props) { return (props.isMobileImage ? "188px" : "392px"); }, function (props) { return props.theme.media.mobile; }, function (props) { return (props.isMobileImage ? "146px" : "304px"); });
var ImageInputsWrapper = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  @media all and ", " {\n    display: block;\n  }\n"], ["\n  display: flex;\n  @media all and ", " {\n    display: block;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export var SelectImageFields = function (_a) {
    var values = _a.values, setValues = _a.setValues, fields = _a.fields;
    return (React.createElement(ImageInputsWrapper, { mt_24: true }, fields.map(function (input, index) {
        var name = input.name, label = input.label, width = input.width;
        var isFirst = index === 0;
        return (React.createElement(ImageInputContainer, __assign({ dflexColumn: true, key: index }, { width: width, isFirst: isFirst }),
            React.createElement(ImgContainer, { isMobileImage: name === "mobileImg" },
                React.createElement(ImageInput, __assign({}, input, { value: values[name].blob, onSelect: function (blob) {
                        var _a;
                        return setValues(__assign(__assign({}, values), (_a = {}, _a[name] = { blob: blob, isInitialValue: false }, _a)));
                    } }))),
            React.createElement(Div, { fs_11: true, mt_8: true, c_gray30: true, lite: true, italic: true, truncateText: true }, label)));
    })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
