var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Icon } from "notes";
import { useEffect, useState } from "react";
import { useVideoRecordContext, VideoRecordContextProvider, } from "./VideoRecordContextProvider";
import { Settings } from "./Settings";
import { MediaTroubleshoot } from "../MediaTroubleshoot";
import { Videojs } from "../Videojs";
import { DiscardRercordingModal } from "./DiscardRecordingModal";
import { VideoRecorderInput } from "./VideoRecorderInput";
import { Div, AppContainer } from "../..";
import { useIsMobile } from "../../..";
export * from "./DiscardRecordingModal";
export * from "./Settings";
export * from "./VideoRecordContextProvider";
export * from "./VideoRecorderInput";
export var VideoRecorderWrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 500px;\n  width: 300px;\n  border-radius: 5px;\n  position: relative;\n  @media all and ", " {\n    border-radius: 0;\n    height: 454.54px;\n    width: 271px;\n  }\n"], ["\n  height: 500px;\n  width: 300px;\n  border-radius: 5px;\n  position: relative;\n  @media all and ", " {\n    border-radius: 0;\n    height: 454.54px;\n    width: 271px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplay; });
var IconWrapper = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n  user-select: none;\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n  user-select: none;\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"])), function (props) { return props.theme.colors.white; });
var TroubleshootWrapper = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  background-color: white;\n  z-index: 100000000000;\n  height: 100vh;\n  overflow-y: scroll;\n  top: 0;\n  left: 0;\n  width: 100vw;\n"], ["\n  position: fixed;\n  background-color: white;\n  z-index: 100000000000;\n  height: 100vh;\n  overflow-y: scroll;\n  top: 0;\n  left: 0;\n  width: 100vw;\n"])));
var HeaderWrapper = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 80px;\n  @media all and ", " {\n    height: 70px;\n  }\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n"], ["\n  height: 80px;\n  @media all and ", " {\n    height: 70px;\n  }\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n"])), function (props) { return props.theme.media.mobile; });
var Wrapper = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  @media all and ", " {\n    margin-top: 10px;\n  }\n  margin-bottom: 100px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n  @media all and ", " {\n    margin-top: 10px;\n  }\n  margin-bottom: 100px;\n"])), function (props) { return props.theme.media.mobile; });
var BottomIconLabel = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 11px;\n  font-weight: 600;\n  :hover {\n    text-decoration: underline;\n  }\n"], ["\n  font-size: 11px;\n  font-weight: 600;\n  :hover {\n    text-decoration: underline;\n  }\n"])));
var Container = styled(Div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: black;\n  top: 0;\n  left: 0;\n  position: fixed;\n  height: 100vh;\n  width: 100%;\n  overflow-y: auto;\n"], ["\n  background-color: black;\n  top: 0;\n  left: 0;\n  position: fixed;\n  height: 100vh;\n  width: 100%;\n  overflow-y: auto;\n"])));
export var VideoRecorder = function (_a) {
    var isVideoRecorderOpen = _a.isVideoRecorderOpen, setIsVideoRecorderOpen = _a.setIsVideoRecorderOpen, setRecordingBlob = _a.setRecordingBlob;
    useEffect(function () {
        if (isVideoRecorderOpen) {
            document.body.style.overflow = "hidden";
        }
        else
            document.body.style.overflow = "auto";
    }, [isVideoRecorderOpen]);
    var onStopRecording = function (blob) {
        if (blob) {
            setIsVideoRecorderOpen(false);
            setRecordingBlob(blob);
        }
    };
    return (React.createElement(VideoRecordContextProvider, null, isVideoRecorderOpen && (React.createElement(VideoRecorderWithContext, { onClose: function () { return setIsVideoRecorderOpen(false); }, onStopRecording: onStopRecording }))));
};
var VideoRecorderWithContext = function (_a) {
    var onClose = _a.onClose, onStopRecording = _a.onStopRecording;
    var _b = useVideoRecordContext(), selectedAudioDeviceId = _b.selectedAudioDeviceId, selectedVideoDeviceId = _b.selectedVideoDeviceId, setSelectedAudioDeviceId = _b.setSelectedAudioDeviceId, setSelectedVideoDeviceId = _b.setSelectedVideoDeviceId, setDevices = _b.setDevices;
    var _c = useState(false), userInitializedCamera = _c[0], setUserInitializedCamera = _c[1];
    var _d = useState(null), recordingBlob = _d[0], setRecordingBlob = _d[1];
    var _e = useState(false), isSettingsModalOpen = _e[0], setIsSettingsModalOpen = _e[1];
    var _f = useState(false), isTroubleshootModalOpen = _f[0], setIsTroubleshootModalOpen = _f[1];
    var _g = useState(false), isDiscardRecordingModalOpen = _g[0], setIsDiscardRecordingModalOpen = _g[1];
    var isMobile = useIsMobile();
    useEffect(function () {
        if (isMobile)
            setIsSettingsModalOpen(false);
    }, [isMobile]);
    useEffect(function () {
        if (isTroubleshootModalOpen) {
            document.body.style.overflow = "hidden";
            setIsSettingsModalOpen(false);
        }
        else
            document.body.style.overflow = "auto";
    }, [isTroubleshootModalOpen]);
    var _onClose = function () {
        if (recordingBlob)
            setIsDiscardRecordingModalOpen(true);
        else
            onClose();
    };
    var onGearIconClick = function () {
        if (isMobile)
            setIsTroubleshootModalOpen(true);
        else
            setIsSettingsModalOpen(true);
    };
    var discardRecordingUI = (React.createElement(DiscardRercordingModal, { setIsDiscardRecordingModalOpen: setIsDiscardRecordingModalOpen, isDiscardRecordingModalOpen: isDiscardRecordingModalOpen, onConfirm: onClose }));
    var troubleshootUI = (React.createElement(React.Fragment, null, isTroubleshootModalOpen && (React.createElement(TroubleshootWrapper, null,
        React.createElement(MediaTroubleshoot, { onClose: function () {
                setIsTroubleshootModalOpen(false);
            } })))));
    var settingsUI = (React.createElement(React.Fragment, null, isSettingsModalOpen && (React.createElement(Div, { mt_20: true },
        React.createElement(Settings, { setIsTroubleshootModalOpen: setIsTroubleshootModalOpen, withAutoPlayOption: false, onClose: function () { return setIsSettingsModalOpen(false); } })))));
    var headerUI = (React.createElement(AppContainer, null,
        React.createElement(HeaderWrapper, { mcontainer: true },
            React.createElement(IconWrapper, { onClick: _onClose },
                React.createElement(Icon, { name: "Left", height: 32, width: 32 })),
            React.createElement(Div, null,
                settingsUI,
                React.createElement(IconWrapper, { onClick: onGearIconClick },
                    React.createElement(Icon, { name: "Gear", height: 32, width: 32 }))))));
    var BottomIcon = function (_a) {
        var label = _a.label, icon = _a.icon, _b = _a.onClick, onClick = _b === void 0 ? null : _b;
        return (React.createElement(Div, { clickable: true, c_white: true, onClick: onClick },
            React.createElement(IconWrapper, null, icon),
            React.createElement(BottomIconLabel, { mt_8: true }, label)));
    };
    var bottomControlsUI = (React.createElement(Div, { spaceAround: true, mt_30: true, mb_50: true },
        React.createElement(BottomIcon, { label: "Delete", icon: React.createElement(Icon, { name: "Delete" }), onClick: function () { return setRecordingBlob(null); } }),
        React.createElement(BottomIcon, { label: "Use This", icon: React.createElement(Icon, { badge: true, name: "FacebookVerified", width: "23px", height: "23px" }), onClick: function () { return onStopRecording(recordingBlob); } })));
    return (React.createElement(Container, null,
        discardRecordingUI,
        headerUI,
        troubleshootUI,
        React.createElement(Wrapper, null,
            React.createElement(Div, null,
                React.createElement(VideoRecorderWrapper, null, recordingBlob ? (React.createElement(Videojs, { withObjectFitCover: true, width: "100%", height: "100%", blob: recordingBlob })) : (React.createElement(VideoRecorderInput, { width: "100%", height: "100%", initializeOnMount: userInitializedCamera, onInit: function () { return setUserInitializedCamera(true); }, selectedAudioDeviceId: selectedAudioDeviceId, selectedVideoDeviceId: selectedVideoDeviceId, setSelectedAudioDeviceId: setSelectedAudioDeviceId, setSelectedVideoDeviceId: setSelectedVideoDeviceId, setDevices: setDevices, onStopRecording: function (blob) { return setRecordingBlob(blob); }, onTroubleshoot: function () { return setIsTroubleshootModalOpen(true); } }))),
                !!recordingBlob && bottomControlsUI))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
