import {
  Div,
  Overlay as OverlayBase,
  OverlayBackdrop,
  useDropdownMarginTop,
} from "@max/common-ui";
import styled, { css } from "styled-components";

const OKButton = styled(Div)`
  margin-top: 10px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Overlay = styled(OverlayBase)`
  margin-left: -20px;
  width: 329px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  padding: 16px 24px;
  ${(props) =>
    !props.marginTop &&
    css`
      opacity: 0;
    `}
  margin-top: ${(props) => props.marginTop}px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 280px;
    margin-left: 2%;
  }
`;

const InfoModal = ({ isOpen, onClose, header, text }) => {
  const { dropdownRef, dropdownMarginTop } = useDropdownMarginTop(isOpen, 10);
  return (
    <>
      {isOpen && (
        <>
          <OverlayBackdrop onClick={onClose} />
          <Overlay marginTop={dropdownMarginTop} ref={dropdownRef}>
            <Div bold mb_7>
              {header}
            </Div>
            <Div lite>{text}</Div>
            <OKButton flexRowReverse bold c_linkDefault>
              <Div c_linkDefault clickable onClick={onClose} mt_10>
                Got it
              </Div>
            </OKButton>
          </Overlay>
        </>
      )}
    </>
  );
};

export default InfoModal;
