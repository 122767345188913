import {
  Avatar as AvatarBase,
  Div,
  scrollBarDarkCss,
  SelectMenu as SelectMenuBase,
  SelectMenuBackdrop,
  SelectMenuDiv,
  useDebounce,
} from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useAppContext } from "AppContextProvider";
import { shoutoutI } from "firebase-tools";
import { Icon, Input, LoaderInline } from "notes";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import LabelWithInfo from "../../ScheduleMeetGreet/common/LabelWithInfo";
import { useTeammatesContext } from "./TeammatesContextProvider";

const colorArray = ["#FF8C00", "#39A4A1", "#E45C52", "#7C60D9"];

const Avatar = styled(AvatarBase)`
  width: 32px;
  height: 32px;
`;

const NoAvatar = styled(Div)`
  background-color: ${(props) =>
    props.color || props.theme.palette.orange.primary};
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  height: 32px;
  width: 32px;
  svg {
    width: 8px;
    height: 8px;
    path {
      fill: #ffffff;
    }
  }
`;

const SelectMenu = styled(SelectMenuBase)`
  ${SelectMenuBackdrop} {
    z-index: 1;
  }
  ${SelectMenuDiv} {
    margin-top: 8px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
    ${scrollBarDarkCss}
  }
`;

const MenuItem = styled(Div)`
  padding: 11px 12px;
  ${(props) =>
    !props.disableHover &&
    css`
      :hover {
        cursor: pointer;
        background: ${(props) => props.theme.colors.gray05};
      }
    `}
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
  }
`;

const IconWrapper = styled(Div)`
  height: 32px;
  width: 32px;
  background: ${(props) => props.theme.colors.gray20};
  border-radius: 100%;
  ${(props) =>
    props.small &&
    css`
      height: 20px;
      width: 20px;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: white;
    }
  }
`;

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));
const compare = (member, query) => {
  const match = (field) =>
    member[field].toLowerCase().includes(query.toLowerCase());
  return match("name") || match("email");
};

const TeammateSearch = () => {
  const {
    teammates,
    setTeammates,
    teamMembersOptions: teamMembers,
    isCompact,
  } = useTeammatesContext();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { _value, set_Value } = useDebounce(query, setQuery, 650);
  const { setAppIsLoading } = useAppContext();

  const _onSelect = (d) => {
    setIsDropdownOpen(false);
    setTeammates([
      ...teammates,
      { ...d, isAdmin: true, role: RoleType.manager },
    ]);
  };

  useEffect(() => {
    if (query === "") setIsDropdownOpen(false);
    else
      (async () => {
        setIsLoading(true);
        setIsDropdownOpen(true);
        await sleep(100);
        const selectedIds = teammates?.map((t) => t.uid);
        setResults(
          teamMembers
            .filter((t) => compare(t, query))
            .filter((t) => !selectedIds.includes(t.uid))
        );
        setIsLoading(false);
      })();
  }, [query]);

  useEffect(() => {
    if (!isDropdownOpen) {
      setQuery("");
      setResults([]);
    }
  }, [isDropdownOpen]);

  const sendInvitation = async () => {
    setIsDropdownOpen(false);
    setAppIsLoading(true);
    const {
      result,
      error,
    } = await shoutoutI.functions().meetgreet.getOrCreateUserByEmail({
      email: query,
    });
    setAppIsLoading(false);
    if (!error) {
      _onSelect({
        email: query,
        name: query,
        uid: result?.uid,
        photo: (
          <IconWrapper small>
            <Icon width="12px" form name="Email" />
          </IconWrapper>
        ),
      });
    } else {
      console.log({ error });
      alert("Sending invitation failed");
    }
  };

  const leftIconUI = (
    <Div mt_n3>
      <Icon form name="Search" />
    </Div>
  );

  const noResultsUI = !validateEmail(query) ? (
    <MenuItem c_gray30 fs_15 lite disableHover>
      No teammates matching <span>“{query}”</span> found. Please check your
      spelling or enter a valid email address to invite a new teammate.
    </MenuItem>
  ) : (
    <MenuItem onClick={sendInvitation}>
      <Div spaceBetween>
        <Div dflex alignCenter>
          <IconWrapper>
            <Icon form name="Email" />
          </IconWrapper>
          <Div ml_16>{query}</Div>
        </Div>
        <Div alignCenter clickable>
          <Div uppercase alignCenter c_gray30 extraBold fs_15>
            invite
          </Div>
        </Div>
      </Div>
    </MenuItem>
  );

  const dropdownResultsUI = (
    <>
      {results.length
        ? results.map((r, index) => (
            <MenuItem
              key={index}
              dflex
              alignCenter
              onClick={() => _onSelect(r)}
            >
              {/* <Avatar src={r.photo || null} alt="" /> */}
              {typeof r.photo === "string" && r.photo.length > 0 ? (
                <Avatar src={r.photo || null} alt="" />
              ) : (
                <NoAvatar color={r.name ? colorArray[index] : "#A6AEB2"}>
                  {r.name ? (
                    r.name?.charAt(0)?.toUpperCase()
                  ) : (
                    <Icon form name="Email" />
                  )}
                </NoAvatar>
              )}

              <Div dflexColumn ml_16>
                <Div w_230 truncateText fs_15 lh_19>
                  {r.name}
                </Div>
                <Div c_gray40 fs_12 lh_15>
                  {r.email}
                </Div>
              </Div>
            </MenuItem>
          ))
        : noResultsUI}
    </>
  );

  const dropdownUI = (
    <SelectMenu
      isOpen={isDropdownOpen}
      onClose={() => setIsDropdownOpen(false)}
    >
      {isLoading ? (
        <MenuItem disableHover={true}>
          <Div mt_20 mb_20>
            <LoaderInline />
          </Div>
        </MenuItem>
      ) : (
        dropdownResultsUI
      )}
    </SelectMenu>
  );

  return (
    <Div w100>
      <Div mb_10>
        {isCompact ? (
          <Div fs_17 extraBold>
            Teammates ({teammates?.length || "0"})
          </Div>
        ) : (
          <LabelWithInfo
            label="Teammates"
            infoHeader="Who Should Be Added as a Teammate?"
            info="Anyone who might be needed to moderate the call should be added as a moderator. There can be only one artist per call, and an artist user must is necessary for the meet & greet to function properly."
          />
        )}
      </Div>
      <Input
        placeholder="Add a teammate by name or email address..."
        leftIcon={leftIconUI}
        value={_value}
        onChange={(v) => set_Value(v)}
      />
      {dropdownUI}
    </Div>
  );
};

export default TeammateSearch;
