import { Div } from "@max/common-ui";
import styled from "styled-components";
import { Icon } from "notes";
import { useHistory, generatePath } from "react-router";
import EventMenu from "./EventMenu";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import { useEventsAdminContext } from "components/EventsAdmin/EventsAdminContextProvider";
import { ROUTES } from "Routes";

export const MobileHeader = ({ children, setIsShareTicketSiteModalOpen }) => {
  const history = useHistory();
  const { event } = useEventDetailContext();
  const { artistGroupId } = useEventsAdminContext();
  return (
    <Div dflexColumn>
      <EventImage
        dflex
        p_12
        backgroundImage={event?.assets?.preshowBanner?.path}
      >
        <Div grow>
          <NavigationButton
            forMobile
            centered
            onClick={() =>
              history.push(generatePath(ROUTES.EVENTS_ADMIN, { artistGroupId }))
            }
          >
            <Icon name="Left" />
          </NavigationButton>
        </Div>
        <EventMenu
          setIsShareTicketSiteModalOpen={setIsShareTicketSiteModalOpen}
        />
      </EventImage>
      <Content>{children}</Content>
    </Div>
  );
};

const NavigationButton = styled(Div)`
  background: rgba(0, 0, 0, 0.72);
  border-radius: 50%;
  color: #ffffff;
  width: 32px;
  height: 32px;
`;

const Content = styled(Div)`
  padding: 16px 20px 0;
`;

const EventImage = styled(Div)`
  background: url(${(props) => props.backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
`;
