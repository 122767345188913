import { Div } from "@max/common-ui";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDragAreaContext } from "./DragAreaContextProvider";

export const DraggableContainer = styled(Div)`
  display: flex;
  position: absolute;
  left: ${(props) => props.position.left}px;
  top: ${(props) => props.position.top}px;

  outline: none;

  :hover {
    cursor: grab;
    opacity: 0.95;
  }
  :active {
    opacity: 0.85;
  }
`;

const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

type Props = {
  children?: JSX.Element;
  className?: string;
  position: {
    left: number;
    top: number;
  };
  onChangePosition: (position: { left: number; top: number }) => void;
  dimensions?: {
    left: number;
    top: number;
  };
  onChangeDimensions?: (dim: { width: number; height: number }) => void;
};

export const Draggable: React.FC<Props> = ({
  children,
  className = "",
  position = {
    left: 0,
    top: 0,
  },
  onChangePosition,
}) => {
  const ref = useRef(null);
  const { dragAreaRect } = useDragAreaContext();

  const [boundingRect, setBoundingRect] = useState(null);
  const [mousePosition, setMousePosition] = useState<any>({});
  const [dragStartMousePosition, setDragStartMousePosition] = useState(null);
  const [dragStartPosition, setDragStartPosition] = useState(null);
  const [isUpdatingPosition, setIsUpdatingPosition] = useState(false);

  useEffect(() => {
    setBoundingRect(ref?.current?.getBoundingClientRect());
  }, [ref, children]);

  useEffect(() => {
    const onMouseMove = (e) => setMousePosition({ x: e.pageX, y: e.pageY });

    const onDragEnd = () => setDragStartMousePosition(null);

    const onTouchMove = (e) => {
      const { pageX, pageY } = e.targetTouches[0];
      setMousePosition({ x: pageX, y: pageY });
    };

    addEventListener("mousemove", onMouseMove, false);
    addEventListener("touchmove", onTouchMove, false);
    addEventListener("mouseup", onDragEnd, false);
    addEventListener("touchend", onDragEnd, false);
    return () => {
      removeEventListener("mousemove", onMouseMove);
      removeEventListener("touchmove", onTouchMove);
      removeEventListener("mouseup", onDragEnd);
      removeEventListener("touchend", onDragEnd);
    };
  }, []);

  useEffect(() => {
    const onDrag = async () => {
      setIsUpdatingPosition(true);
      const { x, y } = mousePosition;
      const { x: startX, y: startY } = dragStartMousePosition;
      const { left: startLeft, top: startTop } = dragStartPosition;

      const dx = x - startX;
      const dy = y - startY;

      let newLeft = startLeft + dx;
      let newTop = startTop + dy;

      if (newLeft <= 0) newLeft = 0;
      if (newLeft + boundingRect.width > dragAreaRect.width)
        newLeft = dragAreaRect.width - boundingRect.width;

      if (newTop <= 0) newTop = 0;
      if (newTop + boundingRect.height > dragAreaRect.height)
        newTop = dragAreaRect.height - boundingRect.height;

      onChangePosition({
        left: newLeft,
        top: newTop,
      });
      await sleep(20);
      setIsUpdatingPosition(false);
    };

    if (
      dragAreaRect &&
      boundingRect &&
      dragStartMousePosition &&
      dragStartPosition &&
      !isUpdatingPosition
    )
      onDrag();
  }, [mousePosition]);

  return (
    <>
      <DraggableContainer
        ref={ref}
        className={className}
        position={position}
        onMouseDown={({ pageX, pageY }) => {
          setDragStartMousePosition({ x: pageX, y: pageY });
          setDragStartPosition(position);
        }}
        onTouchStart={(e) => {
          const { pageX, pageY } = e.targetTouches[0];
          setDragStartMousePosition({ x: pageX, y: pageY });
          setDragStartPosition(position);
        }}
      >
        {children}
      </DraggableContainer>
    </>
  );
};
