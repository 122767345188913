import {
  DiscardRercordingModal,
  Div,
  Videojs,
  VideoRecorderWrapper,
} from "@max/common-ui";
import { Icon } from "notes";
import { useState } from "react";
import styled from "styled-components";
import { FormContentWrapper } from "../form";
import { Checkbox } from "./common";
import { useEventMediaContext } from "./EventMediaContextProvider";

const StyledFormContentWrapper = styled(FormContentWrapper)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

const StyledVideoRecorderWrapper = styled(VideoRecorderWrapper)`
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: 214px;
    height: 274px;
  }
`;

export const VideoField = ({ setIsVideoRecorderOpen, setRecordingBlob }) => {
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const { mediaData, setMediaData } = useEventMediaContext();
  const { recordingBlob, includeVideo } = mediaData;

  const discardRecordingModalUI = (
    <DiscardRercordingModal
      setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
      isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
      onConfirm={() => setRecordingBlob({ blob: null, isInitialValue: false })}
      onCancel={() => setMediaData((p) => ({ ...p, includeVideo: true }))}
    />
  );

  const videoJsProps = {};
  videoJsProps[typeof recordingBlob?.blob === "string" ? "src" : "blob"] =
    recordingBlob?.blob;
  return (
    <Div>
      <Checkbox
        onClick={(value) => {
          if (!value && recordingBlob?.blob)
            setIsDiscardRecordingModalOpen(true);
          setMediaData((p) => ({ ...p, includeVideo: value }));
        }}
        isSelected={includeVideo}
        label="Include a Promotional Video Message (optional)"
        description="Check this box like you would like to record a video message to include as part of the sales experience."
      />

      {discardRecordingModalUI}
      {includeVideo && (
        <StyledFormContentWrapper dflex alignCenter mt_30 c_action bold>
          {recordingBlob?.blob ? (
            <Div>
              <StyledVideoRecorderWrapper>
                <Videojs
                  withObjectFitCover={true}
                  width="100%"
                  height="100%"
                  {...videoJsProps}
                />
              </StyledVideoRecorderWrapper>
              <Div dflex svg_red20 c_red20 clickable link fs_17 bold mt_30>
                <Div alignCenter>
                  <Icon tertiary name="Delete" />
                </Div>
                <Div ml_13 onClick={() => setIsDiscardRecordingModalOpen(true)}>
                  Delete Message
                </Div>
              </Div>
            </Div>
          ) : (
            <>
              <Icon form name="Video" />
              <Div
                link
                fs_17
                ml_10
                onClick={() => setIsVideoRecorderOpen(true)}
              >
                Record message
              </Div>
            </>
          )}
        </StyledFormContentWrapper>
      )}
    </Div>
  );
};
