import { Div } from "@max/common-ui";
import styled from "styled-components";

export const ScrollArea = styled(Div)`
  overflow-y: auto;
  height: 92%;
  width: 400px;
`;

export const Layout = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;
