import {
  Div,
  Dropdown as DropdownBase,
  DropdownContainer,
  useIsMobile,
} from "@max/common-ui";
import { paddingLeftCss, paddingRightCss } from "components/common";
import UserMenu from "components/common/UserMenu";
import {
  eventFilterLabels,
  EventFilterOptions,
  useEventsAdminContext,
} from "components/EventsAdmin/EventsAdminContextProvider";
import { Icon } from "notes";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "./assets/LogoGradient.svg";
import { Drawer } from "./Drawer";
import { MobileDrawerTrigger } from "./MobileDrawerTrigger";

const Container = styled(Div)`
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #e6e9eb;
`;

const Wrapper = styled(Div)`
  ${paddingLeftCss};
  ${paddingRightCss};
`;

const Dropdown = styled(DropdownBase)`
  ${DropdownContainer} {
    padding: 0;
    width: 184px;
    margin-left: -53px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;

const MenuItemWrapper = styled(Div)`
  padding: 13px 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray30};
  ${(props) =>
    props.isSelected &&
    css`
      color: ${(props) => props.theme.colors.text};
      font-weight: 700;
    `}
  :hover {
    background: #f5f5f5;
  }
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
  }
`;

type Props = {
  value: EventFilterOptions;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuItem = ({ value, setIsDropdownOpen }: Props) => {
  const { selectedFilter, setSelectedFilter } = useEventsAdminContext();
  const label = eventFilterLabels[value];
  const isSelected = selectedFilter === value;
  return (
    <MenuItemWrapper
      clickable
      isSelected={isSelected}
      onClick={() => {
        setSelectedFilter(value);
        setIsDropdownOpen(false);
      }}
    >
      <Div alignCenter mr_14 svg_text c_text w_18>
        {isSelected && <Icon indicator name="Check" />}
      </Div>
      <Div mr_5>{label}</Div>
    </MenuItemWrapper>
  );
};

const EventFilterWrapper = styled(Div)`
  border: 1px solid ${(props) => props.theme.colors.gray30};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.gray30};
  display: flex;
  align-items: center;
  height: 40px;
  width: 132px;
`;

const EventFilter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedFilter, hasPastSessions } = useEventsAdminContext();
  const selectedFilterLabel = eventFilterLabels[selectedFilter];

  const dropdownUI = (
    <>
      <Dropdown isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuItem value="upcoming" setIsDropdownOpen={setIsOpen} />
        {hasPastSessions && (
          <MenuItem value="past" setIsDropdownOpen={setIsOpen} />
        )}
      </Dropdown>
    </>
  );
  return (
    <>
      {dropdownUI}
      <EventFilterWrapper clickable onClick={() => setIsOpen(true)}>
        <Div alignCenter spaceBetween w100>
          <Div fs_17 bold ml_12>
            {selectedFilterLabel}
          </Div>
          <Div alignCenter svg_gray30 ml_10 mr_12>
            <Icon indicator name="Dropdown" />
          </Div>
        </Div>
      </EventFilterWrapper>
    </>
  );
};

const Navigation = ({
  withEventFilter = false,
  mobileSearchUI,
  showMenu = false,
}: {
  withEventFilter?: boolean;
  mobileSearchUI?: ReactNode;
  showMenu?: boolean;
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  return !isMobile ? (
    <Container w100 h_72 alignCenter>
      <Wrapper spaceBetween w100 alignCenter>
        <Div clickable onClick={() => history.push("/")}>
          <Logo />
        </Div>
        <Div dflex>
          {withEventFilter && <EventFilter />}
          <Div ml_28 alignCenter>
            <UserMenu />
          </Div>
        </Div>
      </Wrapper>
    </Container>
  ) : (
    <>
      {showMenu && (
        <Div h_56 w100 pl_20 alignCenter>
          <MobileDrawerTrigger />
        </Div>
      )}
      <Drawer mobileSearchUI={mobileSearchUI} />
    </>
  );
};

export default Navigation;
