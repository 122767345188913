import { shoutoutI } from "firebase-tools";
import { useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES as ARTIST_MANAGEMENT_ROUTES } from "./ArtistManagement";
import { ROUTES } from "Routes";

export enum sRedirects {
  artistGroups = "artistGroups",
  artistGroupManagement = "artistGroupManagement",
  artistGroupMessages = "artistGroupMessages",
}

const useStripeRedirects = () => {
  const { artistGroupId } = useParams<any>();

  return {
    [sRedirects.artistGroups]: ROUTES.ARTISTS_GROUPS,
    [sRedirects.artistGroupManagement]: generatePath(
      `${ROUTES.ARTIST_MANAGEMENT}${ARTIST_MANAGEMENT_ROUTES.ARTIST_GROUP}`,
      {
        artistGroupId,
      }
    ),
    [sRedirects.artistGroupMessages]: generatePath(ROUTES.EVENTS_ADMIN, {
      artistGroupId,
    }),
  };
};

export const StripeConnectRedirect = () => {
  const { redirectTo, artistGroupId } = useParams<any>();
  const history = useHistory();
  const redirects = useStripeRedirects();

  useEffect(() => {
    (async () => {
      // used for updating stripeConnectStatus in firestore
      const { error } = await shoutoutI.functions().payments.connectAccount({
        redirectUri: window.location.origin,
        artistGroupId,
      });
      if (!error) history.push(redirects[redirectTo]);
    })();
  }, []);

  return <></>;
};
