import {
  AppLoading,
  Div,
  getDisplayPrice,
  Modal as ModalBase,
  ModalBackdrop,
  ModalCloseIcon,
  ModalContainer,
  ModalWrapper,
  useQueryParams,
} from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { fstore, shoutoutI } from "firebase-tools";
import { Button as ButtonBase, Input, LoaderInline } from "notes";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Modal = styled(ModalBase)`
  ${ModalCloseIcon} {
    display: none;
  }
  ${ModalContainer} {
    margin-top: 60px;
    z-index: 2;
    @media all and ${(props) => props.theme.media.mobile} {
      margin-top: 20px;
    }
  }
  ${ModalWrapper} {
    z-index: 2;
    width: 680px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 70%;
    }
  }
  ${ModalBackdrop} {
    z-index: 1;
  }
`;

const ConfirmButton = styled(Div)`
  margin-left: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
  }
`;

const ConfirmMessage = styled(Div)`
  span {
    font-weight: bold;
  }
`;

const Button = styled(ButtonBase)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ButtonsWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
  }
`;

const WarningMessageWrapper = styled(Div)`
  padding: 13px 16px;
  border: solid 1px ${(props) => props.theme.colors.red10};
  background: ${(props) => props.theme.colors.red05};
  color: ${(props) => props.theme.colors.red20};
  border-radius: 4px;
  span {
    font-weight: bold;
  }
`;

const WarningMessage = ({ uid }) => {
  const [refundAmount, setRefundAmount] = useState(null);
  useEffect(() => {
    (async () => {
      const res = await fstore
        .collectionGroup("private")
        .where("intent.metadata.recipientUid", "==", uid)
        .get();
      const [doc] = res.docs;
      if (doc) setRefundAmount(doc.data().intent.amount);
      else setRefundAmount(0);
    })();
  }, []);

  return (
    <>
      {refundAmount !== null ? (
        <>
          {refundAmount !== undefined && (
            <WarningMessageWrapper dflex>
              Total Amount to be Refunded: {getDisplayPrice(refundAmount)}
            </WarningMessageWrapper>
          )}
        </>
      ) : (
        <Div ml_60 alignCenter>
          <LoaderInline />
        </Div>
      )}
    </>
  );
};

const RemoveAttendeeModal = ({ name, uid, isOpen, onClose }) => {
  const [text, setText] = useState("");
  const { sId: sessionId } = useQueryParams(["sId"]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setText("");
  }, [isOpen]);

  const onRemove = async () => {
    setIsLoading(true);
    const { error } = await shoutoutI.functions().meetgreet.sessionUser({
      action: "delete",
      sessionId,
      role: { role: RoleType.fan },
      identifier: { uid },
    });
    if (error) {
      console.log({ error });
      alert("Removing attendee failed");
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      {isLoading && <AppLoading />}
      <Modal {...{ isOpen, onClose }}>
        <Div fs_25 extraBold c_text>
          Remove Attendee
        </Div>
        <Div fs_17 c_gray40 bold mt_8>
          {name}
        </Div>
        <Div lite fs_17 mt_20 c_text>
          Are you sure? This cannot be undone and attendee will receive a
          refund.
        </Div>
        <Div mt_15>
          <WarningMessage uid={uid} />
        </Div>
        <Div mt_30>
          <ConfirmMessage lite fs_17 mt_20 c_text mb_15>
            Confirm you want to cancel this event by typing:{" "}
            <span>remove fan</span>
          </ConfirmMessage>
          <Input
            placeholder="remove fan"
            onChange={(v) => setText(v)}
            value={text}
          />
        </Div>
        <ButtonsWrapper dflex mt_50>
          <Div onClick={onClose}>
            <Button type="secondary">Nevermind</Button>
          </Div>
          <ConfirmButton onClick={onRemove}>
            <Button disabled={text !== "remove fan"}>Confirm</Button>
          </ConfirmButton>
        </ButtonsWrapper>
      </Modal>
    </>
  );
};

export default RemoveAttendeeModal;
