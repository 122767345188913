import { ThemeProvider } from "styled-components";
import "./App.css";
import Routes from "Routes";
import { AppContextProvider } from "AppContextProvider";
import { UserContextProvider } from "UserContextProvider";
import { theme } from "theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default App;
