import { AppLoading, useQueryParams } from "@max/common-ui";
import { MeetGreetIndex } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useAppContext } from "AppContextProvider";
import { useEventNavigation } from "components/common/useEventNavigation";
import { fstore } from "firebase-tools";
import { createContext, useContext, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import { useEventsAdminContext } from "./EventsAdminContextProvider";
import NewEventModal from "./NewEventModal";

type State = {
  routeSessionId: any;
  routeEventId: any;
  eventSummary: any;
  session: MeetGreetIndex;
  event: any;
  isLoadingEvent: any;
};
const EventDetailContext = createContext<State>({} as State);

export const EventDetailContextProvider = ({ children }) => {
  const { newEventId, setNewEventId } = useAppContext();
  const { gotoDefault } = useEventNavigation();
  const { sessions } = useEventsAdminContext();
  const { eventId } = useParams<any>();
  const { sId: sessionId } = useQueryParams(["sId"]);
  const [event, isLoadingEvent]: any = useDocument(
    fstore.doc(`meet_greet_events/${eventId}`)
  );

  const eventSummary = sessions?.find((s) => s.eventId === eventId);
  const session = sessions?.find((s) => s.sessionId === sessionId);

  const isLoadingData = isLoadingEvent || !sessions;
  useEffect(() => {
    if (!isLoadingEvent && !event) gotoDefault();
  }, [isLoadingEvent]);

  useEffect(() => {
    if (sessionId && !session) gotoDefault();
  }, [session]);

  const value = {
    routeSessionId: sessionId,
    routeEventId: eventId,
    eventSummary,
    session,
    event: event?.data(),
    isLoadingEvent,
  };
  return (
    <EventDetailContext.Provider value={value}>
      {event && (
        <NewEventModal
          isOpen={!!newEventId}
          onClose={() => setNewEventId(null)}
        />
      )}
      {isLoadingData && <AppLoading />}
      {children}
    </EventDetailContext.Provider>
  );
};

export const useEventDetailContext = () => useContext(EventDetailContext);
