import { Avatar as AvatarBase, Div, useIsMobile } from "@max/common-ui";
import {
  MeetGreetIndex,
  RoleType,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useEventNavigation } from "components/common/useEventNavigation";
import { DateTime } from "luxon";
import { Icon } from "notes";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useEventDetailContext } from "../EventDetailContextProvider";
import {
  SessionEvent,
  useEventsAdminContext,
} from "../EventsAdminContextProvider";

const Divider = styled(Div)`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.palette.gray.lightest};
  margin: 12px 0;
`;

const Avatar = styled(AvatarBase)`
  height: 24px;
  width: 24px;
`;

const ListItem = styled(Div)`
  padding: 9px 12px;
  user-select: none;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background: #f5f5f5;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-radius: 0;
  }
`;

const AddSessionButton = styled(ListItem)`
  color: ${(props) => props.theme.palette.gray.primary};
  :hover {
    background: #f5f5f5;
    color: ${(props) => props.theme.palette.black};
  }
`;

const selectedCss = css`
  background: ${(props) => props.theme.colors.purple05};
  color: ${(props) => props.theme.colors.purple20};
  svg {
    path {
      fill: ${(props) => props.theme.colors.purple20};
    }
  }
`;

const Selectable = styled(ListItem)`
  font-size: 17px;
  outline: none;
  color: ${(props) => props.theme.colors.text};
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    & + & {
      border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
    }
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    ${(props) =>
      props.isSelected &&
      css`
        font-weight: bold;
        ${selectedCss};
      `}
    :hover {
      ${(props) =>
        props.isSelected
          ? selectedCss
          : css`
              background: #f5f5f5;
            `};
    }
  }
`;

const SelectableEvent = styled(Selectable)`
  padding: 7px 12px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 7px 12px 7px 20px;
  }
`;

const SessionsContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-left: 56px;
  }
`;

const Event = ({ eventId, eventName, eventAssets }: SessionEvent) => {
  const { sessions, eventNameUpdates } = useEventsAdminContext();
  const { routeEventId, routeSessionId } = useEventDetailContext();
  const isSelected = !routeSessionId && eventId === routeEventId;
  const { gotoAddSession, gotoEventDetail } = useEventNavigation();
  const ref = useRef(null);
  const isEventSessionSelected = !!sessions
    .filter((s) => s.eventId === eventId)
    .find((s) => s.sessionId === routeSessionId);
  const isMobile = useIsMobile();
  const updatedValue = eventNameUpdates.find((u) => u.eventId === eventId);

  useEffect(() => {
    if (isSelected) ref.current.focus();
  }, [isSelected]);

  const addSessionUI = (
    <AddSessionButton alignCenter onClick={() => gotoAddSession(eventId)}>
      <Div dflex>
        <Div alignCenter mt_n2>
          <Icon tertiary name="Add" />
        </Div>
        <Div ml_12 bold fs_15>
          Add Session
        </Div>
      </Div>
    </AddSessionButton>
  );

  const sessionsUI = (
    <SessionsContainer ml_48 mt_8>
      {sessions
        .filter((s) => s.eventId === eventId)
        .map((session, index) => (
          <Session key={index} {...session} />
        ))}
      {(isSelected || isEventSessionSelected) && !isMobile && addSessionUI}
    </SessionsContainer>
  );

  return (
    <Div selectNone>
      <SelectableEvent
        ref={ref}
        tabIndex="0"
        isSelected={isSelected}
        dflex
        alignCenter
        onClick={() => gotoEventDetail(eventId)}
      >
        <Div alignCenter>
          <Avatar src={eventAssets?.squareBanner?.path} />
        </Div>
        <Div ml_12 w100>
          <Div c_text extraBold fs_20 lh_26 truncateText>
            {updatedValue?.value || eventName}
          </Div>
        </Div>
      </SelectableEvent>
      {sessionsUI}
      <Divider mb_17 />
    </Div>
  );
};

const Session = ({ eventId, sessionId, startDate, roles }: MeetGreetIndex) => {
  const { routeSessionId } = useEventDetailContext();
  const isSelected = sessionId === routeSessionId;
  const count = roles.filter((r) => r.role === RoleType.fan).length;
  const ref = useRef(null);
  const { gotoSessionDetail } = useEventNavigation();

  useEffect(() => {
    if (isSelected) ref.current.focus();
  }, [isSelected]);

  return (
    <Selectable
      ref={ref}
      tabIndex="0"
      spaceBetween
      isSelected={isSelected}
      onClick={() => gotoSessionDetail(eventId, sessionId)}
    >
      <Div dflex>
        <Div uppercase mr_4>
          {startDate.toLocal().toFormat("EEE")},
        </Div>
        {startDate.toLocal().toFormat("MMMM d")} at{" "}
        {startDate.toLocal().toFormat("h:mm a")}
      </Div>
      <Div dflex alignCenter forDesktop>
        <Div fs_15 mr_10 style={{ fontWeight: 400 }}>
          {count}
        </Div>
        <Div alignCenter svg_gray30>
          <Icon tertiary name="Audience" />
        </Div>
      </Div>
      <Div alignCenter svg_gray20 forMobile>
        <Icon indicator name="Closed" />
      </Div>
    </Selectable>
  );
};

export default Event;
