import { createContext, useContext } from "react";

type State = {
  mediaData: any;
  setMediaData: any;
};
const EventMediaContext = createContext<State>({} as State);

export const EventMediaContextProvider = ({
  mediaData,
  setMediaData,
  children,
}) => {
  const value = { mediaData, setMediaData };
  return (
    <EventMediaContext.Provider value={value}>
      {children}
    </EventMediaContext.Provider>
  );
};

export const useEventMediaContext = () => useContext(EventMediaContext);
