import { Div } from "@max/common-ui";
import React from "react";
import TeammateList from "./TeammateList";
import { TeammatesContextProvider } from "./TeammatesContextProvider";
import TeammateSearch from "./TeammateSearch";

const Teammates = ({
  eventId,
  teammates,
  setTeammates,
  isCompact = false,
  options = null,
  withRemoveConfirmationModal = false,
}) => {
  return (
    <TeammatesContextProvider
      {...{ eventId, teammates, setTeammates, isCompact, options }}
    >
      <Div>
        <TeammateSearch />
      </Div>
      <Div mt_16>
        <TeammateList
          withRemoveConfirmationModal={withRemoveConfirmationModal}
        />
      </Div>
    </TeammatesContextProvider>
  );
};

export default Teammates;
