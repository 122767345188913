var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { Div } from "../Div";
var player;
var Wrapper = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (props) { return props.width; }, function (props) { return props.height; });
var Video = styled("video")(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  outline: none;\n  overflow: hidden;\n  background: #111113;\n  :focus {\n    outline: none;\n  }\n  .vjs-big-play-button {\n    background-color: #26a6fa;\n    opacity: 1 !important;\n    border: none;\n  }\n\n  ", "\n  ", "\n"], ["\n  outline: none;\n  overflow: hidden;\n  background: #111113;\n  :focus {\n    outline: none;\n  }\n  .vjs-big-play-button {\n    background-color: #26a6fa;\n    opacity: 1 !important;\n    border: none;\n  }\n\n  ", "\n  ", "\n"])), function (props) {
    return props.headerSkin && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      .vjs-control-bar {\n        transform: scale(1.3);\n        margin-bottom: 15px;\n        padding-left: 14%;\n        margin-top: -10px;\n        background: none !important;\n      }\n      .vjs-time-divider {\n        margin: 0;\n        min-width: auto;\n        padding: 0;\n      }\n      .vjs-control-text,\n      .vjs-progress-control,\n      .vjs-progress-holder,\n      .vjs-fullscreen-control,\n      .vjs-big-play-button {\n        display: none !important;\n      }\n      .vjs-time-control {\n        display: block;\n      }\n      .vjs-remaining-time {\n        display: none;\n      }\n    "], ["\n      .vjs-control-bar {\n        transform: scale(1.3);\n        margin-bottom: 15px;\n        padding-left: 14%;\n        margin-top: -10px;\n        background: none !important;\n      }\n      .vjs-time-divider {\n        margin: 0;\n        min-width: auto;\n        padding: 0;\n      }\n      .vjs-control-text,\n      .vjs-progress-control,\n      .vjs-progress-holder,\n      .vjs-fullscreen-control,\n      .vjs-big-play-button {\n        display: none !important;\n      }\n      .vjs-time-control {\n        display: block;\n      }\n      .vjs-remaining-time {\n        display: none;\n      }\n    "])));
}, function (props) {
    return props.withObjectFitCover && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      .vjs-tech {\n        object-fit: cover;\n      }\n    "], ["\n      .vjs-tech {\n        object-fit: cover;\n      }\n    "])));
});
var defaultOptions = {
    muted: false,
    controls: true,
};
export var Videojs = function (_a) {
    var _b = _a.src, src = _b === void 0 ? null : _b, _c = _a.width, width = _c === void 0 ? "350px" : _c, _d = _a.height, height = _d === void 0 ? "620px" : _d, _e = _a.blob, blob = _e === void 0 ? null : _e, _f = _a.autoPlay, autoPlay = _f === void 0 ? false : _f, _g = _a.muted, muted = _g === void 0 ? false : _g, _h = _a.headerSkin, headerSkin = _h === void 0 ? false : _h, _j = _a.autoHideControls, autoHideControls = _j === void 0 ? true : _j, _k = _a.options, options = _k === void 0 ? defaultOptions : _k, _l = _a.withObjectFitCover, withObjectFitCover = _l === void 0 ? false : _l;
    var videoRef = useRef();
    useEffect(function () {
        player = videojs(videoRef.current, __assign({ fill: true, autoplay: autoPlay, inactivityTimeout: autoHideControls ? 3000 : 0, controlBar: {
                pictureInPictureToggle: false,
            } }, options));
        return function () {
            player.dispose();
        };
    }, []);
    useEffect(function () {
        if (src) {
            player.src(src);
            if (muted)
                player.muted(true);
            if (autoPlay)
                player.play();
        }
    }, [src]);
    useEffect(function () {
        if (blob) {
            player.src({
                src: window.URL.createObjectURL(blob),
                type: blob.type,
            });
        }
    }, [blob]);
    return (React.createElement(Wrapper, { positionRelative: true, width: width, height: height },
        React.createElement(Video, { muted: muted, autoPlay: autoPlay, headerSkin: headerSkin, playsInline: true, withObjectFitCover: withObjectFitCover, ref: videoRef, className: "video-js vjs-big-play-centered" })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
