import { Div } from "@max/common-ui";
import React from "react";
import styled from "styled-components";

export const Link = ({ url }) => {
  const label = url.replace("https://", "");
  return (
    <LinkResponsive link onClick={() => window.open(url)}>
      {label}
    </LinkResponsive>
  );
};

export const TicketSiteLink = ({ eventId }) => {
  return (
    <Div>
      <Div bold fs_12 lh_18 c_gray30>
        Ticket Site
      </Div>
      <Div c_action bold truncateText>
        <Link url={`${process.env.REACT_APP_TICKET_SITE_URL}/${eventId}`} />
      </Div>
    </Div>
  );
};

export const DetailField = ({ icon, children }) => {
  return (
    <Div mt_24 dflex>
      <IconContainer centered svg_gray30>
        {icon}
      </IconContainer>
      {children}
    </Div>
  );
};

const IconContainer = styled(Div)`
  width: 16px;
  margin-right: 16px;
  align-items: center;
  justify-content: flex-start;
  @media only screen and ${(props) => props.theme.media.desktop} {
    justify-content: center;
    margin-left: 12px;
    margin-right: 24px;
  }
`;

const LinkResponsive = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    line-height: 19px;
  }
`;
