import {
  Div,
  Overlay as OverlayBase,
  OverlayBackdrop,
  useDropdownMarginTop,
} from "@max/common-ui";
import { shoutoutI } from "firebase-tools";
import { Icon, Toggle } from "notes";
import { useState } from "react";
import styled, { css } from "styled-components";
import { useTeammatesContext } from "../TeammatesContextProvider";
const RoleType = shoutoutI.firestore.meetgreet.RoleType;

const AdminToggleWrapper = styled(Div)`
  padding: 16px 15px;
`;

const Overlay = styled(OverlayBase)`
  width: 300px;
  margin-left: -50px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  ${(props) =>
    !props.marginTop &&
    css`
      opacity: 0;
    `}
    margin-top: ${(props) => props.marginTop}px;
`;

const ScrollArea = styled(Div)`
  overflow-y: auto;
  height: 145px;
`;

const Divider = styled(Div)`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.gray10};
`;

const RoleOption = styled(Div)`
  padding: 12px 16px;
  font-size: 17px;
  color: ${(props) => props.theme.colors.gray20};
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: bold;
      color: ${(props) => props.theme.colors.text};
    `}
  user-select: none;
  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.gray04};
    opacity: 0.9;
  }
`;

const RoleLabel = styled(Div)`
  white-space: nowrap;
`;

export const RoleSelect = ({ teammate }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    !teammate.isInitialValue
  );
  const { role, isAdmin } = teammate;
  const roles = [];
  if (role) roles.push(role);
  if (isAdmin) roles.push("Admin");

  const onClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Div positionRelative>
      <Div alignCenter>
        <Div onClick={() => setIsDropdownOpen(true)} dflex>
          <RoleLabel fs_12 c_gray40 semiBold mr_5 link capitalize>
            {roles.join(`, `)}
            {roles.length === 0 && "Select Role"}
          </RoleLabel>
          <Div alignCenter>
            <Icon height="10px" indicator name="Dropdown" />
          </Div>
        </Div>
      </Div>
      <Div justifyEnd>
        <Dropdown
          isOpen={isDropdownOpen}
          onClose={onClose}
          teammate={teammate}
        />
      </Div>
    </Div>
  );
};

const Dropdown = ({ isOpen, onClose, teammate }) => {
  const roleOptions = Object.values(RoleType).filter((r) => r !== RoleType.fan);
  const { teammates, setTeammates } = useTeammatesContext();

  const { role, uid, isAdmin } = teammate;

  const { dropdownRef, dropdownMarginTop } = useDropdownMarginTop(
    isOpen,
    10,
    80
  );

  const updateTeammateProp = (prop, value) => {
    const newTeammates = [...teammates];
    const updateIndex = teammates.findIndex((t) => t.uid === uid);
    newTeammates[updateIndex][prop] = value;
    setTeammates(newTeammates);
  };

  const toggleTeammateRole = (roleOption) =>
    updateTeammateProp("role", roleOption);

  const toggleIsAdmin = () => updateTeammateProp("isAdmin", !isAdmin);

  const roleOptionsUI = (
    <ScrollArea>
      {roleOptions.map((roleOption, index) => {
        const isSelected = role === roleOption;
        return (
          <RoleOption
            dflex
            isSelected={isSelected}
            key={index}
            onClick={() => toggleTeammateRole(roleOption)}
          >
            <Div w_20 alignCenter svg_text>
              {isSelected && <Icon height="15px" indicator name="Check" />}
            </Div>
            <Div ml_12 capitalize>
              {roleOption}
            </Div>
          </RoleOption>
        );
      })}
    </ScrollArea>
  );

  const adminToggleUI = (
    <AdminToggleWrapper>
      <Div spaceBetween alignCenter>
        <Div fs_17 c_text bold>
          Administrator
        </Div>
        <Toggle checked={isAdmin} onChange={toggleIsAdmin} />
      </Div>
      <Div italic fs_11 lh_14 c_gray30 mt_12>
        Administrators are able to make changes to events or sessions they’re a
        part of, create new sessions, and add/remove teammates,
      </Div>
    </AdminToggleWrapper>
  );

  return (
    <>
      {isOpen && (
        <>
          <OverlayBackdrop onClick={onClose} />
          <Overlay ref={dropdownRef} marginTop={dropdownMarginTop}>
            {roleOptionsUI}
            <Divider />
            {adminToggleUI}
          </Overlay>
        </>
      )}
    </>
  );
};
