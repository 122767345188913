var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Div } from "./Div";
export var useDropdownMarginTop = function (isDropdownOpen, defaultMarginTop, spaceToBottomThreshold) {
    if (defaultMarginTop === void 0) { defaultMarginTop = 30; }
    if (spaceToBottomThreshold === void 0) { spaceToBottomThreshold = 50; }
    var _a = useState(null), dropdownMarginTop = _a[0], setDropdownMarginTop = _a[1];
    var dropdownRef = useRef(null);
    useEffect(function () {
        var _a;
        var dropdownRect = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var spaceToBottom = window.innerHeight - (dropdownRect === null || dropdownRect === void 0 ? void 0 : dropdownRect.bottom);
        if (spaceToBottom < spaceToBottomThreshold) {
            setDropdownMarginTop(-dropdownRect.height);
        }
        else
            setDropdownMarginTop(defaultMarginTop);
        if (!isDropdownOpen)
            setDropdownMarginTop(null);
    }, [isDropdownOpen]);
    return {
        dropdownRef: dropdownRef,
        dropdownMarginTop: dropdownMarginTop,
    };
};
export var OverlayBackdrop = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 10000;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 10000;\n"])));
export var MenuItem = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-size: 17px;\n  color: #222222;\n  user-select: none;\n  font-weight: normal;\n  padding: 12px 10px;\n  :hover {\n    background-color: #e2e6e8;\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  font-size: 17px;\n  color: #222222;\n  user-select: none;\n  font-weight: normal;\n  padding: 12px 10px;\n  :hover {\n    background-color: #e2e6e8;\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"])));
export var Overlay = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  z-index: 100000;\n"], ["\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  z-index: 100000;\n"])));
export var OverlayContainer = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 100000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"], ["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 100000;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
