var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Div } from "./Div";
export var DropdownBackdrop = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 99999999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var DropdownContainer = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 5px;\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  overflow-y: auto;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: #d2d4e0;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #f8f9fb;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #d2d4e0;\n  }\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  z-index: 1000000000000;\n"], ["\n  padding: 5px;\n  background-color: white;\n  border: solid 1px#F9FAFB;\n  position: absolute;\n  border-radius: 4px;\n  overflow-y: auto;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 5px;\n    height: 5px;\n    opacity: 0.3;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background-color: #d2d4e0;\n    opacity: 0.3;\n    border-radius: 3px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: #f8f9fb;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    border-radius: 3px;\n    background-color: #d2d4e0;\n  }\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  z-index: 1000000000000;\n"])));
export var Dropdown = function (_a) {
    var onClose = _a.onClose, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, children = _a.children, _c = _a.className, className = _c === void 0 ? "" : _c;
    return (React.createElement(React.Fragment, null, isOpen && (React.createElement(Div, { className: className },
        React.createElement(DropdownBackdrop, { onClick: onClose }),
        React.createElement(DropdownContainer, null, children)))));
};
var templateObject_1, templateObject_2;
