import {
  Avatar as AvatarBase,
  Div,
  IconWrapper,
  Modal as ModalBase,
  ModalWrapper,
} from "@max/common-ui";
import { Icon } from "notes";
import styled from "styled-components";
import { useTeammatesContext } from "../TeammatesContextProvider";
import { RoleSelect } from "./RoleSelect";
import { Button as ButtonBase } from "notes";
import { useState } from "react";

const Button = styled(ButtonBase)`
  width: 48%;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-bottom: 20px;
    width: 100%;
  }
`;

const Modal = styled(ModalBase)`
  ${ModalWrapper} {
    width: 472px;
    @media all and ${(props) => props.theme.media.mobile} {
      width: 250px;
    }
  }
`;

const colorArray = [
  "#FF8C00",
  "#39A4A1",
  "#E45C52",
  "#7C60D9",
  "#c76d00",
  "#2B7A78",
  "#8F150B",
  "#46367A",
];

const Avatar = styled(AvatarBase)`
  height: 20px;
  width: 20px;
`;

const NoAvatar = styled(Div)`
  background-color: ${(props) =>
    props.color || props.theme.palette.orange.primary};
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 12px;
  height: 20px;
  width: 20px;
  svg {
    width: 8px;
    height: 8px;
    path {
      fill: #ffffff;
    }
  }
`;

const ListItem = styled(Div)`
  padding: 9px 8px;
`;

const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  margin-top: 20px;
  background: ${(props) => props.theme.colors.gray10};
`;

const Name = styled(Div)`
  font-size: ${(props) => (props.isCompact ? "15px" : "17px")};
  font-weight: 400;
  line-height: ${(props) => (props.isCompact ? "19px" : "22px")};
`;

const RemoveButton = styled(IconWrapper)`
  width: 32px;
  height: 32px;
  margin: -10px -4px -10px 10px;
`;

const ModalButtonsWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
  }
`;

const TeammateList = ({ withRemoveConfirmationModal = false }) => {
  const { teammates, setTeammates, isCompact } = useTeammatesContext();
  const [modalState, setModalState] = useState<any>({
    isOpen: false,
    uid: null,
  });

  const onRemoveTeammate = (uid) => {
    if (withRemoveConfirmationModal)
      setModalState({
        isOpen: true,
        uid,
      });
    else removeTeammate(uid);
  };

  const removeTeammate = (uid) => {
    setTeammates(teammates?.filter((t) => t.uid !== uid));
  };

  const onCloseModal = () => setModalState({ isOpen: false });
  const modalButtonsUI = (
    <ModalButtonsWrapper spaceBetween>
      <Button type="tertiary" onClick={onCloseModal}>
        Nevermind
      </Button>
      <Button
        onClick={() => {
          removeTeammate(modalState.uid);
          onCloseModal();
        }}
      >
        Confirm
      </Button>
    </ModalButtonsWrapper>
  );

  const modalUI = (
    <Modal isOpen={modalState.isOpen} onClose={onCloseModal}>
      <Div textAlignCenter>
        <Div fs_25 c_text extraBold>
          Remove Temmate
        </Div>
        <Div mt_10 c_gray40 fs_17>
          {teammates?.find((t) => t.uid === modalState.uid)?.name}
        </Div>
        <Div fs_17 c_text lite textAlignCenter mt_20 mb_50>
          Are you sure? Removing a teammate will automatically remove event and
          session info from their account.
        </Div>
      </Div>
      {modalButtonsUI}
    </Modal>
  );

  return (
    <Div>
      {modalUI}
      {teammates?.map((t, index) => (
        <ListItem key={index} dflex>
          <Div spaceBetween w100 alignCenter>
            <Div dflex alignCenter>
              <Div w_20 h_20>
                {typeof t.photo === "string" && t.photo.length > 0 ? (
                  <Avatar src={t.photo || null} alt="" />
                ) : (
                  <NoAvatar
                    color={
                      t.name
                        ? colorArray[
                            index > colorArray.length - 1
                              ? index - colorArray.length
                              : index
                          ]
                        : "#A6AEB2"
                    }
                  >
                    {t.name ? (
                      t.name?.charAt(0)?.toUpperCase()
                    ) : (
                      <Icon form name="Email" />
                    )}
                  </NoAvatar>
                )}
              </Div>
              <Name ml_18 truncateText mr_20 isCompact={isCompact}>
                {t.name || t.email}
              </Name>
            </Div>
            <Div dflex alignCenter>
              <RoleSelect teammate={t} />
              <RemoveButton
                ml_20
                title="Remove"
                onClick={() => onRemoveTeammate(t.uid)}
              >
                <Icon height="16px" form name="Remove" />
              </RemoveButton>
            </Div>
          </Div>
        </ListItem>
      ))}
      {!isCompact && <Divider />}
    </Div>
  );
};

export default TeammateList;
