import { getBlobFromUrl } from "@max/common-ui";
import { useEffect } from "react";
import { useEventMediaContext } from "../EventMediaContextProvider";

const getPngWithWatermarkUrl = (
  width,
  height,
  watermarkImgUrl,
  watermarkPositioning,
  includesGradient = false,
  gradientHeight,
  callback
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const getPngUrl = async () => {
    const blob = await getBlobFromUrl(canvas.toDataURL("image/png"));
    // const url = URL.createObjectURL(blob);
    callback(blob);
  };

  const w = width;
  const h = height;

  const addGradient = () => {
    const grdHeight = gradientHeight;
    const grdShift = 1;
    const grd = ctx.createLinearGradient(
      w / 2,
      h * grdShift,
      w / 2,
      h * (1 - grdHeight)
    );
    grd.addColorStop(0, "black");
    grd.addColorStop(1, "transparent");
    ctx.fillStyle = grd;
    ctx.fillRect(0, h * (1 - grdHeight), w, h * grdHeight);
  };

  canvas.width = w;
  canvas.height = h;
  if (includesGradient) addGradient();

  const watermarkImg = new Image();
  watermarkImg.src = watermarkImgUrl;
  watermarkImg.onload = () => {
    const { left, top, width, height } = watermarkPositioning;
    ctx.drawImage(watermarkImg, left * w, top * h, width * w, height * h);
    getPngUrl();
  };
};

export const useImageWithWatermarkPng = ({
  width,
  height,
  fieldName,
  includesGradient,
  config: { watermarkImgUrl, position },
  gradientHeight = 0.3,
}) => {
  const { setMediaData } = useEventMediaContext();

  useEffect(() => {
    if (!position) return;
    getPngWithWatermarkUrl(
      width,
      height,
      watermarkImgUrl,
      position,
      includesGradient,
      gradientHeight,
      (blob) =>
        setMediaData((p) => ({
          ...p,
          watermarkImages: {
            ...p.watermarkImages,
            [fieldName]: {
              blob,
              isInitialValue: false,
            },
          },
        }))
    );
  }, [position]);
};
