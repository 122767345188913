import { AppContainer, Div } from "@max/common-ui";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import { useScheduleMeetGreetContext } from "./ScheduleMeetGreetContextProvider";

const Clickable = styled(Div)`
  ${(props) =>
    props.isClickable &&
    !props.isSelected &&
    css`
      :hover {
        user-select: none;
        :hover {
          cursor: pointer;
        }
      }
    `}
`;

const StepWrapper = styled(Clickable)`
  box-sizing: border-box;
  border-radius: 100%;
  svg path {
    fill: #a6aeb2;
  }
  ${(props) =>
    props.isSelected &&
    css`
      svg path {
        fill: ${(props) => props.theme.colors.green30};
      }
    `}

  ${(props) =>
    props.isComplete &&
    css`
      user-select: none;
      ${!props.isSelected &&
      css`
        svg path {
          fill: ${(props) => props.theme.colors.green30};
        }
      `}
    `}
`;

const Divider = styled(Div)`
  background: ${(props) => props.theme.colors.gray15};
  height: 2px;
  width: 100%;
  ${(props) =>
    props.isComplete &&
    css`
      background: ${(props) => props.theme.colors.green30};
    `}
`;

const StepText = styled(Clickable)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray20};
  width: 99px;
  margin-top: 8px;
  margin-left: -42px;
  text-align: center;
  user-select: none;
  ${(props) =>
    props.isBold &&
    css`
      color: ${(props) => props.theme.colors.text};
      font-weight: bold;
      text-transform: capitalize;
    `}
  ${(props) =>
    props.isClickable &&
    !props.isSelected &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 11px;
    line-height: 14px;
  }
`;

const Wrapper = styled(Div)`
  width: 70%;
`;

const StepContainer = styled(Div)`
  ${(props) =>
    !props.isLast &&
    css`
      width: 100%;
    `}
`;

const Step = ({ index, label, isSelected }) => {
  const { setCurrentStep, completedSteps } = useScheduleMeetGreetContext();
  const isComplete = index < completedSteps;
  // const isClickable = index <= completedSteps;
  const isClickable = false;
  return (
    <Div
    // onClick={() => {
    //   if (index <= completedSteps) setCurrentStep(index);
    // }}
    >
      <StepWrapper
        {...{ isClickable, isSelected, isComplete }}
        centered
        svg_gray
      >
        {!isSelected ? (
          isComplete ? (
            <Icon badge name="FacebookVerified" />
          ) : (
            <Icon indicator name="ProgressInactive" />
          )
        ) : (
          <Icon indicator name="ProgressActive" />
        )}
      </StepWrapper>
      <Div positionRelative>
        <StepText
          isClickable={isClickable}
          isSelected={isSelected}
          isBold={isSelected || isComplete}
          positionAbsolute
        >
          {label}
        </StepText>
      </Div>
    </Div>
  );
};

const labels = ["Ticket Site", "Media", "Time Slots"];

export const Steps = ({ currentStep }) => {
  const { completedSteps } = useScheduleMeetGreetContext();

  const stepsUI = (
    <Wrapper>
      <Div dflex>
        {labels.map((label, index) => {
          const isLast = index === labels.length - 1;
          const isComplete = index < completedSteps;
          return (
            <StepContainer dflex key={index} isLast={isLast}>
              <Step
                index={index}
                label={label}
                isSelected={index === currentStep}
              />
              <Div centered w100>
                {!isLast && <Divider mr_10 ml_10 isComplete={isComplete} />}
              </Div>
            </StepContainer>
          );
        })}
      </Div>
    </Wrapper>
  );

  return (
    <Div w100>
      <Div centered>{stepsUI}</Div>
    </Div>
  );
};
