import { Div } from "@max/common-ui";
import { useState } from "react";
import Joi from "@hapi/joi";
import { validate } from "./validate";
import styled from "styled-components";
import { Label, Input, Icon, Link, FieldMessage } from "notes";
import { useUserContext } from "../../UserContextProvider";
import { fstore } from "firebase-tools";

export const ContactForm = ({ defaultValue, onComplete }) => {
  const { user } = useUserContext();
  const [state, setState] = useState(defaultValue);
  const [errors, setErrors] = useState({ fullName: false, email: false });

  const handleChange = (key: string, value: string) => {
    errors[key] = false;
    const newState = { ...state };
    newState[key] = value;
    setState(newState);
    setErrors(errors);
  };

  const profileSchema = Joi.object({
    fullName: Joi.string().required().min(3),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  });

  const handleUpdate = () => {
    const errors = validate(state, profileSchema);
    if (!errors) {
      fstore
        .doc(`profiles/${user?.uid}`)
        .update(state)
        .then(() => onComplete());
    } else {
      setErrors(errors);
    }
  };

  return (
    <ContactFormBase>
      <Div mb_24>
        <Label>Name</Label>
        <Input
          onChange={(value) => handleChange("fullName", value)}
          value={state.fullName}
          placeholder="Legal or stage name..."
        />
        <FieldMessage active={!!errors.fullName} content={errors.fullName} />
      </Div>
      <Div mb_24>
        <Label>Email address</Label>
        <Input
          leftIcon={<Icon form name="Email" />}
          onChange={(value) => handleChange("email", value)}
          value={state.email}
          placeholder="john@email.com"
        />
        <FieldMessage active={!!errors.email} content={errors.email} />
      </Div>
      <Link onClick={onComplete}>Cancel</Link>
      <StyledLink onClick={handleUpdate}>Update</StyledLink>
    </ContactFormBase>
  );
};

const ContactFormBase = styled(Div)`
  width: 100%;
  max-width: 392px;
`;

const StyledLink = styled(Link)`
  margin-left: 38px;
`;
