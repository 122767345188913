import { AppLoading, Div, Section } from "@max/common-ui";
import { Divider, TeammateFormModal } from "./common";
import { Icon } from "notes";
import { ROUTES } from "Routes";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useState } from "react";
import ArtistGroupName from "./ArtistGroupName";
import Teammate from "./Teammate";
import {
  ArtistGroupContextProvider,
  useArtistGroupContext,
} from "../../common/ArtistGroupProvider";
import { Stripe } from "./Stripe";

const Wrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
  }
`;

const AddTeammateButton = styled(Div)`
  margin-top: 35px;
  color: ${(props) => props.theme.colors.action};
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const Columns = styled(Div)`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-gap: 65px;
  margin-bottom: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    grid-gap: 0;
    flex-direction: column-reverse;
  }
`;

const ConnectSection = styled(Section)`
  padding-right: 0;
  padding-left: 0;
`;

const ArtistGroup = () => {
  return (
    <ArtistGroupContextProvider>
      <ArtistGroupWithContext />
    </ArtistGroupContextProvider>
  );
};

const ArtistGroupWithContext = () => {
  const { data } = useArtistGroupContext();
  return <>{data ? <ArtistGroupWithData /> : <AppLoading />}</>;
};

export const teammateInitialValues = {
  name: "",
  email: "",
  roles: [],
};

const ArtistGroupWithData = () => {
  const { data } = useArtistGroupContext();
  const history = useHistory();
  const {
    group: { name: artistName },
    members: teammates,
  } = data;
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const backLinkUI = (
    <Div mb_30 c_action fs_17 bold svg_action w_79>
      <Div
        onClick={() => history.push(ROUTES.ARTIST_MANAGEMENT)}
        clickable
        link
        alignCenter
      >
        <Icon tertiary name="Left" /> <Div ml_5>Back</Div>
      </Div>
    </Div>
  );

  const addTeammateButtonUI = (
    <AddTeammateButton>
      <Div clickable alignCenter dflex onClick={() => setIsFormModalOpen(true)}>
        <Div fs_20>+</Div>
        <Div ml_10 fs_17 link>
          Add Teammate
        </Div>
      </Div>
    </AddTeammateButton>
  );

  const teammatesUI = (
    <>
      {teammates.length > 0 && (
        <Div fs_17 bold c_text mt_30>
          Teammates
        </Div>
      )}
      {teammates.map((teammate, index) => (
        <Div key={index}>
          <Teammate teammate={teammate} />
        </Div>
      ))}
    </>
  );

  return (
    <Wrapper c_text mcontainer>
      <TeammateFormModal
        isOpen={isFormModalOpen}
        initialValues={teammateInitialValues}
        onClose={() => setIsFormModalOpen(false)}
      />
      {backLinkUI}
      <Columns>
        <Div>
          <Section>
            <ArtistGroupName name={artistName} />
            <Divider />
            {teammatesUI}
            {addTeammateButtonUI}
          </Section>
        </Div>
        <Div>
          <ConnectSection>
            <Stripe />
          </ConnectSection>
        </Div>
      </Columns>
    </Wrapper>
  );
};

export default ArtistGroup;
