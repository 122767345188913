import { Avatar as AvatarBase, Div } from "@max/common-ui";
import {
  IndexedRole,
  RoleType,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import styled from "styled-components";
import { useEventDetailContext } from "../EventDetailContextProvider";

const Avatar = styled(AvatarBase)`
  height: 20px;
  width: 20px;
`;

const Wrapper = styled(Div)`
  width: 392px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 5%;
    width: 95%;
  }
`;

export const TeammatesSummary = () => {
  const { session } = useEventDetailContext();
  const teammates = session.roles
    .filter((r) => r.role !== RoleType.fan)
    .map((t) => ({ ...t, photo: t.image, isInitialValue: true }));
  return (
    <Wrapper mb_60>
      <Div mr_3 mb_12 fs_17 extraBold>
        Teammates ({teammates.length})
      </Div>
      {teammates.map((teammate, index) => (
        <Div key={index}>
          <Teammate {...teammate} />
        </Div>
      ))}
    </Wrapper>
  );
};

const Teammate = ({ email, name, image, role, isAdmin }: IndexedRole) => {
  const roles = `${role}${isAdmin ? ", Admin" : ""}`;
  return (
    <Div dflex spaceBetween h_45 alignCenter>
      <Div dflex alignCenter>
        {typeof image === "string" ? (
          <Avatar src={image || null} alt="" />
        ) : (
          image
        )}
        <Div c_text fs_15 ml_10 truncateText w_240>
          {name || email}
        </Div>
      </Div>
      <Div fs_12 c_gray30 capitalize>
        {roles}
      </Div>
    </Div>
  );
};
