import {
  Div,
  ErrorMessage,
  FormikErrorFocus,
  Input,
  Modal as ModalBase,
  ModalCloseIcon,
  ModalContainer,
  ModalWrapper,
  PhoneInput,
  useQueryParams,
} from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useAppContext } from "AppContextProvider";
import { shoutoutI } from "firebase-tools";
import { Form, Formik } from "formik";
import { Button as ButtonBase } from "notes";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

const Modal = styled(ModalBase)`
  ${ModalCloseIcon} {
    display: none;
  }
  ${ModalContainer} {
    margin-top: 70px;
    @media all and ${(props) => props.theme.media.mobile} {
      margin-top: 20px;
    }
  }
  ${ModalWrapper} {
    width: 680px;
    @media all and ${(props) => props.theme.media.mobile} {
      overflow-y: scroll;
      width: 70%;
    }
  }
`;

const AddButton = styled(Div)`
  margin-left: 30px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
  }
`;

const Button = styled(ButtonBase)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ButtonsWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
  }
`;

const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");
const validationSchema = yup.object().shape({
  name: yupRequired,
  email: yupRequired.email(),
  phone: yup.string(),
});

const initialValues = {
  name: "",
  email: "",
  phone: "",
};

const AddAttendeeModal = ({ isOpen, onClose }) => {
  const [isValidPhone, setIsValidPhone] = useState(true);
  const { sId: sessionId } = useQueryParams(["sId"]);
  const { setAppIsLoading } = useAppContext();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [isOpen]);

  const onSubmitForm = async (values) => {
    if (!!values.phone && !isValidPhone) return;
    setAppIsLoading(true);
    onClose();
    const { error } = await shoutoutI.functions().meetgreet.sessionUser({
      action: "add",
      sessionId,
      identifier: { email: values.email },
      phoneNumber: values.phone,
      role: { role: RoleType.fan },
      displayName: values.name,
    });
    if (error) {
      console.log({ error });
      alert(error.message);
    }
    setAppIsLoading(false);
  };

  const Field = ({ label, children }) => {
    return (
      <Div mt_30>
        <Div fs_15 c_text mb_5>
          {label}
        </Div>
        {children}
      </Div>
    );
  };

  const headerUI = (
    <Div c_text>
      <Div fs_25 extraBold>
        Add Attendee
      </Div>
      <Div fs_17 lite mt_10>
        Please add the attendee&apos;s information below. Note that after
        hitting &quot;Add&quot; the attendee will be immediately emailed their
        link to the event.
      </Div>
    </Div>
  );

  const fieldsUI = (
    <>
      <Field label="Attendee name">
        <Input name="name" placeholder="First and last name..." />
      </Field>
      <Field label="Attendee email address">
        <Input name="email" placeholder="john@email.com" />
      </Field>
    </>
  );

  return (
    <Modal {...{ isOpen, onClose }}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, submitForm, submitCount }) => (
          <Form>
            {headerUI}
            {fieldsUI}
            <Field label="Attendee phone number (optional)">
              <Div w_395>
                <PhoneInput name="phone" onValidate={setIsValidPhone} />
                {submitCount > 0 && !isValidPhone && !!values.phone && (
                  <ErrorMessage
                    msg={"This does not appear to be a valid phone number."}
                  />
                )}
              </Div>
            </Field>
            <ButtonsWrapper dflex mt_40 mb_10>
              <Div onClick={onClose}>
                <Button type="secondary">Cancel</Button>
              </Div>
              <AddButton onClick={submitForm}>
                <Button>Add</Button>
              </AddButton>
            </ButtonsWrapper>
            <FormikErrorFocus />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddAttendeeModal;
