var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { Loader } from "notes";
import { Div } from "./Div";
var LoadingBackdrop = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 10000000000;\n  background: ", ";\n  opacity: 0.2;\n"], ["\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  width: 100vw;\n  height: 100vh;\n  z-index: 10000000000;\n  background: ", ";\n  opacity: 0.2;\n"])), function (props) { return props.theme.colors.border; });
var ModalContainer = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000000001;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"], ["\n  top: 0px;\n  left: 0px;\n  margin: 0px;\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 10000000001;\n  width: 100vw;\n  height: 100vh;\n  margin-top: 115px;\n  pointer-events: none;\n  div {\n    pointer-events: auto;\n  }\n"])));
var LoaderContainer = styled(ModalContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  pointer-events: none;\n"], ["\n  pointer-events: none;\n"])));
export var AppLoading = function () { return (React.createElement(React.Fragment, null,
    React.createElement(LoadingBackdrop, null),
    React.createElement(LoaderContainer, null,
        React.createElement(Div, { w_300: true, mt_100: true },
            React.createElement(Div, { positionRelative: true },
                React.createElement(Loader, null)))))); };
var templateObject_1, templateObject_2, templateObject_3;
