import { Div, IconWrapper } from "@max/common-ui";
import { FormContentWrapper } from "components/common/form";
import { WatermarkSource } from "components/ScheduleMeetGreet/ScheduleMeetGreetContextProvider";
import { Icon, Radio } from "notes";
import styled, { css } from "styled-components";
import { useEventMediaContext } from "../../EventMediaContextProvider";
import setliveLogo from "../setlive-logo.png";

export const WatermarkOptions = ({ onRepositionWatermark }) => {
  const { mediaData, setMediaData } = useEventMediaContext();
  const { selectedWatermarkOption, watermarkImgUrl } = mediaData;

  const setSelectedWatermarkOption = (opt) =>
    setMediaData((p) => ({ ...p, selectedWatermarkOption: opt }));

  return (
    <Div mt_17>
      <WatermarkOption
        label="Powered by Set.Live"
        imgUrl={setliveLogo}
        isSelected={selectedWatermarkOption === WatermarkSource.setlive}
        onClick={() => {
          setSelectedWatermarkOption(WatermarkSource.setlive);
        }}
      />
      <Divider />
      <WatermarkOption
        label="Custom"
        onUpdate={onRepositionWatermark}
        imgUrl={watermarkImgUrl}
        isSelected={selectedWatermarkOption === WatermarkSource.custom}
        onClick={() => {
          setSelectedWatermarkOption(WatermarkSource.custom);
        }}
      />
      <Divider long />
    </Div>
  );
};

const Divider = styled(Div)`
  display: flex;
  flex-shrink: 1;
  background: ${(props) => props.theme.palette.gray.lightest};
  height: 1px;
  width: ${(props) => (props.long ? "calc(100% - 32px)" : "calc(100% - 72px)")};
  margin-left: ${(props) => (props.long ? "32px" : "72px")};
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: ${(props) =>
      props.long ? "calc(100% - 52px)" : "calc(100% - 92px)"};
    margin-left: ${(props) => (props.long ? "52px" : "92px")};
  }
`;

const OptionWrapper = styled(FormContentWrapper)`
  padding: 12px 0;
  ${(props) =>
    !props.isSelected &&
    css`
      opacity: 0.5;
    `}
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 17px 0;
  }
  img {
  -webkit-filter: drop-shadow(1px 1px 1px #c4c1c1);
  filter: drop-shadow(1px 1px 1px #c4c1c1);
}
`;

const WatermarkOption = ({
  label,
  imgUrl,
  onClick,
  isSelected,
  onUpdate = null,
}) => {
  return (
    <OptionWrapper
      spaceBetween
      clickable
      isSelected={isSelected}
      onClick={onClick}
    >
      <Div dflex c_text fs_17 alignCenter>
        <Div ml_12 alignCenter>
          <Radio checked={isSelected} />
        </Div>
        {label}
      </Div>
      <Div h100 alignCenter mr_10 dflex>
        {onUpdate && (
          <IconWrapper svg_gray40 alignCenter mr_26 title="Positioning">
            <Icon name="Gear" onClick={onUpdate} />
          </IconWrapper>
        )}
        <img height="32px" src={imgUrl} />
      </Div>
    </OptionWrapper>
  );
};
