import { Div, sectionPadding } from "@max/common-ui";
import styled, { css } from "styled-components";
import { useArtistGroupContext } from "../../../common/ArtistGroupProvider";
import { stripeConnectSvg, stripeSvg } from "./svgs";
import { Icon } from "notes";
import { getArtistAdminsList } from "components/common";
import { shoutoutI } from "firebase-tools";
import { ArtistGroupRole } from "@musicaudienceexchange/shoutout-interface/lib/firestore/artists";
import { generatePath } from "react-router";
import { ROUTES } from "Routes";
import { sRedirects } from "components/StripeConnectRedirect";
import { useAppContext } from "AppContextProvider";
import { useUserContext } from "UserContextProvider";

const sectionPaddingCss = css`
  ${sectionPadding};
  padding-top: 0;
  padding-bottom: 0;
`;

const SectionArea = styled(Div)`
  ${sectionPaddingCss};
`;

const Description = styled(SectionArea)`
  line-height: 23px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 300;
  span {
    font-weight: bold;
  }
  ${(props) =>
    props.gray &&
    css`
      color: ${(props) => props.theme.colors.gray30};
    `}
`;

const StripeButtonWrapper = styled(Div)`
  background: ${(props) => props.theme.colors.purple30};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  padding: 10px 0;
  font-size: 14px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  background: #e5e9eb;
`;

const Logo = styled(Div)`
  ${sectionPaddingCss};
`;

const DataItemWrapper = styled(Div)`
  padding: 16px 0;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: solid 1px #e5e9eb;
    `}
`;

const StripeDataItem = ({ label, data, link, isLast = false }) => {
  return (
    <DataItemWrapper isLast={isLast} spaceBetween fs_15>
      <Div fs link semiBold c_text>
        {label}
      </Div>
      <Div dflex svg_gray15 centered>
        <Div c_gray30>{data}</Div>
        <Icon name="Next" height="16px" />
      </Div>
    </DataItemWrapper>
  );
};

const StripeButton = ({ text }) => {
  const { artistGroupId } = useArtistGroupContext();
  const { setAppIsLoading } = useAppContext();

  const openStripe = async () => {
    setAppIsLoading(true);

    const redirectPath = generatePath(ROUTES.STRIPE_CONNECT, {
      redirectTo: sRedirects.artistGroupManagement,
      artistGroupId,
    });

    const {
      result,
      error,
    } = await shoutoutI.functions().payments.connectAccount({
      redirectUri: `${window.location.origin}${redirectPath}`,
      artistGroupId,
    });
    if (!error && result?.url) window.location.href = result.url;
  };

  return (
    <StripeButtonWrapper fs_14 semiBold onClick={openStripe}>
      {text}
      <Div ml_5 mb_n6>
        {stripeSvg}
      </Div>
    </StripeButtonWrapper>
  );
};

const admin: ArtistGroupRole = "admin";

export const Stripe = () => {
  const {
    data: {
      group: { stripeConnectId, stripeConnectStatus },
      members,
    },
    isArtistGroupAdmin,
  } = useArtistGroupContext();
  const { isAdmin: isAdminUser } = useUserContext();
  const isAdmin = isAdminUser || isArtistGroupAdmin;

  const isStripeReady = !!stripeConnectId && stripeConnectStatus === "active";
  const artistGroupAdmins = members
    .filter((m) => m.roles.includes(admin))
    .map((a) => a?.name);

  const gotoPage = (path) => {
    window.open(`https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}${path}`);
  };

  const stripeConnectUI = isAdmin ? (
    <>
      <Description>
        We use Stripe to make sure you get paid on time and to keep your
        personal bank and details secure. Click{" "}
        <span>Get Paid With Stripe</span> to set up your payments on Stripe.
        <Div mt_32>
          <StripeButton text="Get paid with" />
        </Div>
      </Description>
    </>
  ) : (
    <Div>
      <Description mb_22>
        We use Stripe to make sure you get paid on time and to keep your
        personal bank and details secure.
      </Description>
      <Divider />
      <Description mt_20 gray>
        Set-up <span>Stripe Connect</span> to start getting paid! To proceed,
        please contact {getArtistAdminsList(artistGroupAdmins)}.
      </Description>
    </Div>
  );

  const stripeDataUI = (
    <Div>
      <Description>
        Set.Live partners with Stripe for secure payments and financial services
        so you get paid on time.
        <Div mt_22 mb_26>
          <StripeButton text="Go to" />
        </Div>
      </Description>
      <Divider />
      <SectionArea italic c_red>
        <Div fs_11 dflex alignCenter mt_22 mb_n10 c_gray30 lite>
          Need to make changes?
          <Div ml_5 link onClick={() => gotoPage("/contact")} semiBold c_gray30>
            Contact Us
          </Div>
        </Div>
      </SectionArea>
    </Div>
  );

  return (
    <Div>
      <Logo mb_10>{stripeConnectSvg}</Logo>
      {isStripeReady ? stripeDataUI : stripeConnectUI}
    </Div>
  );
};
