import { createContext, useContext, useEffect, useState } from "react";
import LogRocket from "logrocket";
import firebase, { fb } from "firebase-tools";

// All props **except** functions
export type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

// **Only ** function props
export type FunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]?: T[K] extends Function ? K : never;
}[keyof T];

export type FunctionsOptional<T> = Partial<Pick<T, FunctionPropertyNames<T>>> &
  Pick<T, NonFunctionPropertyNames<T>>;

type State = {
  user: FunctionsOptional<firebase.User>;
  isAdmin: boolean;
  isLoggedIn: boolean;
  setUser: (userData: any) => void;
};

const UserContext = createContext<State>({} as State);

const lsSetObj = (key, obj) => localStorage.setItem(key, JSON.stringify(obj));
const lsGetObj = (key) => JSON.parse(localStorage.getItem(key));
const USER_KEY = "user";

export const UserContextProvider = ({ children }) => {
  const [user, _setUser] = useState(lsGetObj(USER_KEY));
  const [isAdmin, setIsAdmin] = useState(null);

  const setUser = (userData) => {
    if (!userData) {
      localStorage.clear();
      _setUser(null);
      return;
    }
    lsSetObj(USER_KEY, userData);
    _setUser(userData);
  };

  useEffect(() => {
    if (user && user.getIdTokenResult) {
      (async () => {
        try {
          const res = await user.getIdTokenResult();
          if (res?.claims) setIsAdmin(res.claims.admin);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    fb.auth().onAuthStateChanged((_user) => setUser(_user));
  }, []);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  const value = {
    user,
    isAdmin,
    isLoggedIn: !!user,
    setUser,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
