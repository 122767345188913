import { Div } from "@max/common-ui";
import { NumberInputUI } from "./formikInputs";
import { StartTimeInputUI } from "./formikInputs/StartTimeInput";
import { Icon } from "notes";
import { DateTime } from "luxon";
import { FieldWrapper } from "..";

export const SessionMainFields = ({
  startDate,
  startTime,
  callDurationSeconds,
  maximumAttendees,
  pricePerFan,
}) => {
  return (
    <>
      <FieldWrapper dflex alignCenter svg_gray20>
        <Icon form name="Calendar" />
        <Div ml_10>
          {DateTime.fromJSDate(new Date(startDate)).toFormat("DDDD")}
        </Div>
      </FieldWrapper>
      <FieldWrapper>
        <StartTimeInputUI
          value={startTime}
          isDisabled={true}
          {...{ callDurationSeconds, maximumAttendees }}
          placeholder="Pick a start time..."
        />
      </FieldWrapper>
      <FieldWrapper>
        <NumberInputUI
          isDisabled={true}
          value={maximumAttendees}
          pricePerFan={pricePerFan}
        />
      </FieldWrapper>
    </>
  );
};
