var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled, { css } from "styled-components";
import { OverlayBackdrop, OverlayContainer } from "../..";
import { Div } from "../../Div";
var Button = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 38px;\n  width: 184px;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  user-select: none;\n  font-size: 17px;\n  font-weight: bold;\n  border-radius: 5px;\n  color: white;\n  background-color: #26a6fa;\n  border: solid 2px transparent;\n\n  ", "\n  :hover {\n    border: solid 2px transparent;\n    color: white;\n    background-color: #3f7595;\n    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n    cursor: pointer;\n  }\n"], ["\n  height: 38px;\n  width: 184px;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  user-select: none;\n  font-size: 17px;\n  font-weight: bold;\n  border-radius: 5px;\n  color: white;\n  background-color: #26a6fa;\n  border: solid 2px transparent;\n\n  ", "\n  :hover {\n    border: solid 2px transparent;\n    color: white;\n    background-color: #3f7595;\n    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n    cursor: pointer;\n  }\n"])), function (props) {
    return props.secondary && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: solid 2px #26a6fa;\n      background-color: white;\n      color: #26a6fa;\n    "], ["\n      border: solid 2px #26a6fa;\n      background-color: white;\n      color: #26a6fa;\n    "])));
});
var ModalWrapper = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #222222;\n  background-color: white;\n  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n  width: 400px;\n  padding: 40px;\n  @media all and (max-width: 500px) {\n    width: 70%;\n  }\n\n  @media all and ", " {\n    padding: 20px;\n    text-align: left;\n    justify-content: left;\n  }\n"], ["\n  color: #222222;\n  background-color: white;\n  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);\n  width: 400px;\n  padding: 40px;\n  @media all and (max-width: 500px) {\n    width: 70%;\n  }\n\n  @media all and ", " {\n    padding: 20px;\n    text-align: left;\n    justify-content: left;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var mobileButtonCss = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  box-shadow: none;\n  background-color: transparent;\n  color: #26a6fa;\n"], ["\n  border: none;\n  box-shadow: none;\n  background-color: transparent;\n  color: #26a6fa;\n"])));
var ModalButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  @media all and ", " {\n    width: 80px;\n    ", "\n    :hover {\n      ", "\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.7;\n      }\n    }\n  }\n"], ["\n  @media all and ", " {\n    width: 80px;\n    ", "\n    :hover {\n      ", "\n      user-select: none;\n      :hover {\n        cursor: pointer;\n        opacity: 0.7;\n      }\n    }\n  }\n"])), function (props) { return props.theme.media.mobile; }, mobileButtonCss, mobileButtonCss);
var DropShadow = styled(OverlayBackdrop)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: black;\n  opacity: 0.38;\n"], ["\n  background-color: black;\n  opacity: 0.38;\n"])));
var ButtonWrapper = styled(Div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 50px;\n  justify-content: space-between;\n  @media all and ", " {\n    margin-top: 20px;\n    justify-content: flex-end;\n  }\n"], ["\n  display: flex;\n  margin-top: 50px;\n  justify-content: space-between;\n  @media all and ", " {\n    margin-top: 20px;\n    justify-content: flex-end;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var DiscardRecordingText = styled(Div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n  @media all and ", " {\n    font-weight: 400;\n    text-align: left;\n    font-size: 20px;\n  }\n"], ["\n  text-align: center;\n  @media all and ", " {\n    font-weight: 400;\n    text-align: left;\n    font-size: 20px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var WarningText = styled(Div)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 8px;\n  @media all and ", " {\n    text-align: left;\n    font-weight: 300;\n  }\n"], ["\n  text-align: center;\n  margin-top: 8px;\n  @media all and ", " {\n    text-align: left;\n    font-weight: 300;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export var DiscardRercordingModal = function (_a) {
    var isDiscardRecordingModalOpen = _a.isDiscardRecordingModalOpen, setIsDiscardRecordingModalOpen = _a.setIsDiscardRecordingModalOpen, _b = _a.onCancel, onCancel = _b === void 0 ? null : _b, onConfirm = _a.onConfirm;
    var buttonsUI = (React.createElement(ButtonWrapper, null,
        React.createElement(ModalButton, { secondary: true, onClick: function () {
                setIsDiscardRecordingModalOpen(false);
                if (onCancel)
                    onCancel();
            } }, "Cancel"),
        React.createElement(ModalButton, { onClick: function () {
                onConfirm();
                setIsDiscardRecordingModalOpen(false);
            } }, "Discard")));
    return (React.createElement(React.Fragment, null, isDiscardRecordingModalOpen && (React.createElement(React.Fragment, null,
        React.createElement(DropShadow, { onClick: function () {
                setIsDiscardRecordingModalOpen(false);
                if (onCancel)
                    onCancel();
            } }),
        React.createElement(OverlayContainer, null,
            React.createElement(ModalWrapper, null,
                React.createElement(Div, null,
                    React.createElement(DiscardRecordingText, { bold: true, fs_25: true }, "Discard Recording"),
                    React.createElement(WarningText, { fs_17: true }, "Are you sure? This cannot be undone.")),
                buttonsUI))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
