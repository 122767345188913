import React from "react";
import { useScheduleMeetGreetContext } from "./ScheduleMeetGreetContextProvider";
import { TicketSiteFormFields } from "../common/TicketSiteFormFields";
import { Button } from "notes";
import { Div } from "@max/common-ui";
import styled from "styled-components";

const ButtonContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-top: 1px solid #a6aeb2;
    margin: 32px 0 0 0;
    padding: 22px 20px 0;
    button {
      width: 100%;
    }
  }
`;

export const TicketSiteForm = () => {
  const {
    setCurrentStep,
    ticketSiteData,
    setTicketSiteData,
  } = useScheduleMeetGreetContext();

  const onSubmit = (values) => {
    setCurrentStep((v) => v + 1);
    setTicketSiteData(values);
  };

  const FormButtons = ({ onSubmit: onSubmitForm }) => {
    return (
      <ButtonContainer mt_75 ml_32>
        <Button onClick={onSubmitForm}>Continue</Button>
      </ButtonContainer>
    );
  };

  return (
    <TicketSiteFormFields {...{ FormButtons, ticketSiteData, onSubmit }} />
  );
};
