import { Div } from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import { ReactComponent as MenuIcon } from "./assets/menuIcon.svg";

export const MobileDrawerTrigger = ({ className }: { className?: string }) => {
  const { setIsDrawerOpen } = useAppContext();
  return (
    <Div
      mt_n1
      clickable
      onClick={() => setIsDrawerOpen(true)}
      className={className}
    >
      <MenuIcon />
    </Div>
  );
};
