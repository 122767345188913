import { Div, useMediaQuery } from "@max/common-ui";
import { useContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import Navigation from "../common/Navigation";
import AddSession from "./AddSession";
import AttendeOptions from "./AttendeeOptions";
import EditTicketSite from "./EditTicketSite";
import EventDetail from "./EventDetail";
import {
  EventDetailContextProvider,
  useEventDetailContext,
} from "./EventDetailContextProvider";
import EditEventMedia from "./EditEventMedia";
import {
  EventsAdminContextProvider,
  useEventsAdminContext,
} from "./EventsAdminContextProvider";
import EventsList from "./EventsList";
import SessionDetail from "./SessionDetail";
import { BlankState } from "./BlankState";

export const Layout = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Content = styled(Div)`
  overflow-y: hidden;
`;

export const EVENT_ADMIN_ROUTES = {
  EVENT_DETAIL: "/:eventId",
  ATTENDEE_OPTIONS: "/:eventId/attendee-options",
  ADD_SESSION: "/:eventId/add-session",
};

const EventsAdmin = () => {
  const themeBase = useContext(ThemeContext);
  const theme = { ...themeBase };
  theme.media = { ...theme.media, mobile: "(max-width: 1200px)" };
  theme.media = { ...theme.media, desktop: "(min-width: 1201px)" };
  return (
    <ThemeProvider theme={theme}>
      <EventsAdminWithTheme />
    </ThemeProvider>
  );
};

const EventsAdminWithTheme = () => {
  const match = useRouteMatch();
  const baseUrl = match.path;
  const {
    media: { desktop },
  } = useContext(ThemeContext);
  const isDesktop = useMediaQuery(desktop);
  return (
    <EventsAdminContextProvider>
      <Switch>
        <Route exact path={`${baseUrl}${EVENT_ADMIN_ROUTES.ATTENDEE_OPTIONS}`}>
          <AttendeOptions />
        </Route>
        <Route exact path={`${baseUrl}${EVENT_ADMIN_ROUTES.ADD_SESSION}`}>
          <AddSession />
        </Route>
        <Route path={[`${baseUrl}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`]}>
          <EventsAdminWithContext />
        </Route>
        {isDesktop ? (
          <Route>
            <BlankState />
          </Route>
        ) : (
          <Route>
            <Div w100>
              <Navigation />
              <EventsList />
            </Div>
          </Route>
        )}
      </Switch>
    </EventsAdminContextProvider>
  );
};

export const EVENT_ROUTES = {
  EDIT_TICKET_SITE: "/edit-ticket-site",
  EDIT_MEDIA: "/edit-media",
};

const EventsAdminWithContext = () => {
  const { sessions } = useEventsAdminContext();
  const match = useRouteMatch();
  const baseUrl = match.path;
  const {
    media: { desktop },
  } = useContext(ThemeContext);
  const isDesktop = useMediaQuery(desktop);

  return (
    <>
      {sessions && (
        <EventDetailContextProvider>
          <Switch>
            <Route exact path={`${baseUrl}${EVENT_ROUTES.EDIT_TICKET_SITE}`}>
              <EditTicketSite />
            </Route>
            <Route exact path={`${baseUrl}${EVENT_ROUTES.EDIT_MEDIA}`}>
              <EditEventMedia />
            </Route>
            <Route>
              {!isDesktop ? (
                <EventSessionDetailMobile />
              ) : (
                <EventSessionDetailDesktop />
              )}
            </Route>
          </Switch>
        </EventDetailContextProvider>
      )}
    </>
  );
};

const EventSessionDetailMobile = () => {
  const { routeEventId, routeSessionId } = useEventDetailContext();

  return (
    <Layout>
      <Content w100 dflex>
        {routeSessionId ? (
          <SessionDetail />
        ) : routeEventId ? (
          <EventDetail />
        ) : (
          <EventsList />
        )}
      </Content>
    </Layout>
  );
};

const EventSessionDetailDesktop = () => {
  const { routeSessionId } = useEventDetailContext();
  return (
    <Layout>
      <Navigation withEventFilter={true} />
      <Content w100 dflex>
        <EventsList />
        {routeSessionId ? <SessionDetail /> : <EventDetail />}
      </Content>
    </Layout>
  );
};

export default EventsAdmin;
