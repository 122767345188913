import { Div, FormikErrorFocus, MobileDivider } from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Icon } from "notes";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { useUserContext } from "UserContextProvider";
import * as yup from "yup";
import LabelWithInfoBase from "../../ScheduleMeetGreet/common/LabelWithInfo";
import { FormContentWrapper, FormSection } from "../form";
import Teammates from "../Teammates";
import { useEventNavigation } from "../useEventNavigation";
import {
  SessionFormContextProvider,
  useSessionFormContext,
} from "./SessionFormContextProvider";
import SessionSummary from "./SessionSummary";
import {
  DatePicker,
  NumberInput,
} from "./SessionSummary/SessionMainFields/formikInputs";
import { StartTimeInput } from "./SessionSummary/SessionMainFields/formikInputs/StartTimeInput";

const StyledFormContentWrapper = styled(FormContentWrapper)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const PaddedMobileWrapper = styled(StyledFormContentWrapper)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-left: 20px;
  }
`;

const SummaryWrapper = styled(Div)`
  border: 1px solid ${(props) => props.theme.colors.gray08};
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #f6f9fc;
`;

const AddSessionWrapper = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 21px 20px;
  }
`;

const FieldsWrapper = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const SessionTitle = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    line-height: 22px;
    margin: 22px 0 8px 0;
  }
`;

const LabelWithInfo = styled(LabelWithInfoBase)`
  font-size: 12px;
`;

const Divider = styled(Div)`
  height: 24px;
  background-color: ${(props) => props.theme.colors.gray20};
  width: 1px;
`;

const ButtonContainer = styled(Div)`
  button {
    margin: 0 45px 0 0;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 0;
    padding: 22px 20px 0;
    flex-direction: column;
    button {
      width: 100%;
      margin: 0 0 24px 0;
    }
  }
`;

const SessionFormWrapper = styled(Div)`
  ${(props) =>
    !props.isDisplayingForm &&
    css`
      display: none;
    `}
`;

export const requiredFieldMessage = "Required field";
const yupRequired = yup.string().nullable().required(requiredFieldMessage);
const yupRequiredPositiveNumber = yup
  .number()
  .nullable()
  .required(requiredFieldMessage)
  .min(1, "must be positive");
const validationSchema = yup.object().shape({
  startDate: yup
    .date()
    .required(requiredFieldMessage)
    .min(
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      "Date cannot be in the past"
    )
    .nullable()
    .default(null),
  startTime: yupRequired,
  maximumAttendees: yupRequiredPositiveNumber.max(300, "should be < 300"),
});

const Fields = () => {
  const { callDurationSeconds } = useSessionFormContext();
  const {
    values: { maximumAttendees },
  } = useFormikContext();

  return (
    <FieldsWrapper mt_40>
      <DatePicker name="startDate" placeholder="Pick a session date..." />
      <StartTimeInput
        {...{ callDurationSeconds, maximumAttendees }}
        name="startTime"
        placeholder="Pick a start time..."
      />
      <NumberInput
        name="maximumAttendees"
        placeholder="Maximum number of attendees..."
      />
    </FieldsWrapper>
  );
};

const SessionFormFields = ({
  eventId,
  pricePerFan,
  callDurationSeconds,
  sessionData,
  setSessionData,
  onSubmit,
}) => {
  return (
    <SessionFormContextProvider
      {...{
        eventId,
        pricePerFan,
        callDurationSeconds,
        sessionData,
        setSessionData,
      }}
    >
      <SessionFormWithContext {...{ onSubmit }} />
    </SessionFormContextProvider>
  );
};

const SessionFormWithContext = ({ onSubmit }) => {
  const { sessionData: sessions, eventId } = useSessionFormContext();
  const [isDisplayingForm, setIsDisplayingForm] = useState(true);
  const [isAddingFirstSession, setIsAddingFirstSession] = useState(true);
  const { user } = useUserContext();
  const { artistGroupId } = useParams<any>();

  const initialValues = {
    startDate: null,
    startTime: null,
    maximumAttendees: null,
    teammates: [
      {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
        isInitialValue: true,
        isAdmin: true,
        role: RoleType.manager,
      },
    ],
  };

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "show warning";
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // const getCallDuration = () => {
  //   const seconds = parseInt(callDurationSeconds);
  //   const minutes = Math.floor(seconds / 60);
  //   return `${minutes + (seconds % 60) / 60} minutes`;
  // };
  // const summaryUI = (
  //   <SummaryWrapper>
  //     <Div spaceBetween alignCenter h100 fs_17 c_text semiBold>
  //       <Div dflex alignCenter>
  //         <Icon form name="Ticket" />
  //         <Div ml_16>{getCallDuration()} per call</Div>
  //         <Divider ml_16 mr_16 />
  //         <Div>${pricePerFan} per Attendee</Div>
  //       </Div>
  //     </Div>
  //   </SummaryWrapper>
  // );

  const addSessionButtonUI = (
    <Div bold c_gray30 fs_17 clickable dflex alignCenter>
      <Div c_gray20 alignCenter>
        <Icon tertiary name="Add" />
      </Div>
      <Div ml_32>Add Additional Session</Div>
    </Div>
  );

  const savedSessionsUI = (
    <StyledFormContentWrapper>
      {sessions.map((session, index) => (
        <Div key={index} mt_24>
          <SessionSummary {...session} index={index} />
          <MobileDivider />
        </Div>
      ))}
    </StyledFormContentWrapper>
  );

  const headerUI = (
    <>
      <FormSection
        header="Sessions"
        icon={<Icon form name="Series" />}
        description="Please choose the date and time that the session will begin as well as the number of fans you want to virtually meet during this session. We will estimate the session end time based on the number of meeting slots available during the session and how long you have allocated per conversation."
      />
      <MobileDivider style={{ marginTop: "22px" }} />
    </>
  );

  const whenToDoThisUI = (
    <LabelWithInfo
      label="When to do this"
      infoHeader="Additional Sessions"
      info="If you would like to offer fans more than one window during which to schedule their meet & greet, you may add additional sessions. Fans will choose which session they will attend at the time they purchase their ticket."
    />
  );
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, resetForm }) => (
          <Form>
            <Div>
              {headerUI}
              {savedSessionsUI}

              <SessionFormWrapper isDisplayingForm={isDisplayingForm}>
                <PaddedMobileWrapper>
                  {/* {summaryUI} */}
                  {sessions.length > 0 && (
                    <SessionTitle bold fs_20 c_text mt_40 mb_n25>
                      Session {sessions.length + 1}
                    </SessionTitle>
                  )}
                  <Fields />
                  <SessionTeammates />
                  {!isAddingFirstSession && sessions.length > 0 && (
                    <Div
                      dInline
                      onClick={() => {
                        setIsDisplayingForm(false);
                        resetForm();
                      }}
                    >
                      <Div mt_35 c_red20 link semiBold>
                        Remove Session {sessions.length + 1}
                      </Div>
                    </Div>
                  )}
                </PaddedMobileWrapper>
              </SessionFormWrapper>
              <MobileDivider />

              <StyledFormContentWrapper>
                <AddSessionWrapper spaceBetween alignCenter mt_50>
                  <Div
                    onClick={async () => {
                      if (isDisplayingForm) {
                        const submitRes = await (submitForm as any)();
                        if (submitRes) setIsAddingFirstSession(false);
                      } else setIsDisplayingForm(true);
                    }}
                  >
                    {addSessionButtonUI}
                  </Div>
                  <Div forDesktop>{whenToDoThisUI}</Div>
                </AddSessionWrapper>
                <Div forMobile ml_20 mb_20>
                  {whenToDoThisUI}
                </Div>
              </StyledFormContentWrapper>

              <MobileDivider />
            </Div>
            <FormikErrorFocus />
            <StyledFormContentWrapper>
              <FormButtons
                {...{ eventId, artistGroupId, sessions, isDisplayingForm }}
              />
            </StyledFormContentWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};

const FormButtons = ({ eventId, sessions, isDisplayingForm }) => {
  const { submitForm } = useFormikContext<any>();
  const { gotoEventDetail } = useEventNavigation();

  const onClick = async () => {
    let res = false;
    const _submitForm: any = submitForm;
    if (isDisplayingForm) res = await _submitForm();
    if (!isDisplayingForm || res) {
      gotoEventDetail(eventId);
    }
  };

  return (
    <ButtonContainer dflex mt_80 alignCenter>
      <Button onClick={onClick}>Done</Button>
      {sessions.length === 0 && (
        <Button type="tertiary" onClick={() => gotoEventDetail(eventId)}>
          {"I'll do this later"}
        </Button>
      )}
    </ButtonContainer>
  );
};

const SessionTeammates = () => {
  const {
    values: { teammates },
    setValues,
  } = useFormikContext<any>();
  const { eventId } = useSessionFormContext();

  return (
    <Div mt_30>
      <Teammates
        {...{ eventId, teammates }}
        setTeammates={(t) => {
          setValues((p) => ({ ...p, teammates: t }));
        }}
      />
    </Div>
  );
};

export default SessionFormFields;
