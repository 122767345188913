import {
  Div,
  scrollBarDarkCss,
  SelectMenu as SelectMenuBase,
  SelectMenuDiv,
  useFormikField,
} from "@max/common-ui";
import { Icon } from "notes";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Field, dropIconUI } from "./index";

const Placeholder = styled(Div)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray30};
`;
const SelectMenu = styled(SelectMenuBase)`
  ${SelectMenuDiv} {
    max-height: 300px;
    ${scrollBarDarkCss}
    margin-top: -27px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;
const MenuItemWrapper = styled(Div)`
  font-size: 17px;
  height: 48px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
  user-select: none;
  color: ${(props) => props.theme.colors.gray30};
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 600;
      color: ${(props) => props.theme.colors.text};
    `}
  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.gray05};
  }
`;
const To = styled(Div)`
  font-size: 12px;
  font-weight: 300;
  margin: 0 7px;
`;
const Time = styled(Div)`
  letter-spacing: 0.5px;
`;
const CheckIcon = styled(Div)`
  opacity: 0;
  ${(props) =>
    props.isSelected &&
    css`
      opacity: 1;
    `};
`;

const Info = styled(Div)`
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.gray30};
`;

const Container = styled(Div)`
  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const MobileColumn = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const getHoursMinutes = (seconds) => {
  const m = seconds / 60;
  const hours = Math.floor(m / 60);
  const minutes = m % 60;
  return `${hours > 0 ? hours + "h" : ""} ${Math.ceil(minutes)}min.`;
};

const getTime = (s) => {
  const timeToString = (v) => (v >= 10 ? `${v}` : `0${v}`);

  const m = s / 60;
  let hours = Math.floor(m / 60);
  const minutes = m % 60;
  let isAm = true;
  if (hours >= 12) isAm = false;
  if (hours > 12) {
    hours -= 12;
  }

  return `${hours === 0 ? 12 : hours}:${timeToString(Math.floor(minutes))} ${
    isAm ? "AM" : "PM"
  }`;
};

const DURATION_BASE_PADDING_SECONDS = 15 * 60;

export const StartTimeInput = (props) => {
  const { name } = props;
  const { value, setValue, hasError, errorMsg } = useFormikField(name);
  return (
    <Field {...{ name, hasError, errorMsg }}>
      <StartTimeInputUI {...{ value, setValue, ...props }} />
    </Field>
  );
};

export const StartTimeInputUI = ({
  value,
  setValue = null,
  placeholder,
  maximumAttendees,
  callDurationSeconds,
  isDisabled = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const timezone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const estimatedDurationSeconds = (() => {
    if (!maximumAttendees || !callDurationSeconds) return null;
    const idealDuration =
      parseFloat(callDurationSeconds) * parseFloat(maximumAttendees);
    const padding = DURATION_BASE_PADDING_SECONDS + idealDuration * 0.1;
    return idealDuration + padding;
  })();

  const getTimeRangeUI = (s, displayTimezone = true) => (
    <Div dflex alignCenter>
      <Time>{getTime(s)}</Time>
      {estimatedDurationSeconds && (
        <>
          <To>to</To>
          <Time>
            {getTime((s + estimatedDurationSeconds) % (60 * 60 * 24))}
          </Time>
        </>
      )}
      {displayTimezone && estimatedDurationSeconds && (
        <Div lite fs_12 c_text ml_10 mt_4>
          {timezone}
        </Div>
      )}
    </Div>
  );

  const options = (() => {
    const res = [];

    for (let m = 0; m < 1440; m += 15)
      res.push({
        label: getTimeRangeUI(m * 60, false),
        value: m * 60,
      });

    return res;
  })();

  const onClose = () => setIsMenuOpen(false);

  const menuUI = (
    <SelectMenu isOpen={isMenuOpen} onClose={onClose}>
      {options.map((opt, index) => {
        const isSelected = value === opt.value;
        const { label } = opt;
        return (
          <Div key={index}>
            <MenuItem
              {...{ isSelected, setValue, label, onClose }}
              value={opt.value}
            />
          </Div>
        );
      })}
    </SelectMenu>
  );

  const leftIconUI = (
    <Div mr_12 svg_gray30 alignCenter>
      <Icon form name="Clock" />
    </Div>
  );

  const valueUI = (
    <Div dflex>
      <Div mr_10 dflex>
        <Div clickable onClick={() => setIsMenuOpen(true)}>
          {(value !== null && getTimeRangeUI(value)) || (
            <Placeholder alignCenter>{placeholder}</Placeholder>
          )}
        </Div>
        {!isDisabled && dropIconUI}
      </Div>
    </Div>
  );

  return (
    <Container dflex isDisabled={isDisabled} w100>
      {leftIconUI}
      <MobileColumn spaceBetween w100>
        <Div w_270>
          {valueUI}
          {menuUI}
        </Div>
        <Info dflex alignCenter mr_15>
          {!estimatedDurationSeconds && <Div bold>{timezone}</Div>}
          {value && estimatedDurationSeconds && (
            <>
              <Div>Total estimated duration (including downtime):</Div>
              <Div ml_5 bold>
                {getHoursMinutes(estimatedDurationSeconds)}
              </Div>
            </>
          )}
        </Info>
      </MobileColumn>
    </Container>
  );
};

const MenuItem = ({ isSelected, setValue, label, value, onClose }) => {
  const ref = useRef(null);
  useEffect(() => {
    // 12PM
    if (value === 43200) ref.current.focus();
  }, []);
  return (
    <MenuItemWrapper
      tabIndex={0}
      ref={ref}
      isSelected={isSelected}
      dflex
      onClick={() => {
        console.log({ value });
        setValue(value);
        onClose();
      }}
    >
      <CheckIcon isSelected={isSelected} p_16 alignCenter svg_text>
        <Icon indicator name="Check" />
      </CheckIcon>
      {label}
    </MenuItemWrapper>
  );
};
