import { useAppContext } from "AppContextProvider";
import { shoutoutI } from "firebase-tools";
import { useScheduleMeetGreetContext } from "./ScheduleMeetGreetContextProvider";
import { DateTime } from "luxon";
import SessionFormFields from "../common/SessionFormFields";
export type CreateSessionRequest = shoutoutI.functions.meetgreet.CreateSessionRequest;
export type CreateSessionRequestMember = shoutoutI.functions.meetgreet.CreateSessionRequestMember;

const getDate = (date, time) =>
  DateTime.fromJSDate(new Date(date.getTime() + time * 1000));

const SessionsForm = () => {
  const {
    sessionData,
    setSessionData,
    eventId,
  } = useScheduleMeetGreetContext();
  const { ticketSiteData } = useScheduleMeetGreetContext();
  const { setAppIsLoading } = useAppContext();
  const { pricePerFan, callDurationSeconds } = ticketSiteData;
  const pricePerFanCents = parseFloat(pricePerFan) * 100;

  const onSubmit = async (values, { resetForm }) => {
    setAppIsLoading(true);

    const getMembers = (): CreateSessionRequestMember[] =>
      values.teammates.map((t) => ({
        uid: t.uid,
        role: t.role,
        isAdmin: !!t.isAdmin,
      }));

    const session: CreateSessionRequest = {
      eventId,
      slots: parseInt(values.maximumAttendees),
      startDate: getDate(values.startDate, parseInt(values.startTime)),
      members: getMembers(),
    };
    const res = await shoutoutI.functions().meetgreet.createSession(session);
    setAppIsLoading(false);
    if (!res.error) {
      const sessionId = res.result.id;
      console.log({ sessionId });
      setSessionData({
        sessions: [
          ...sessionData.sessions,
          {
            ...values,
            id: sessionId,
            callDurationSeconds,
            pricePerFan: pricePerFanCents,
          },
        ],
      });
      resetForm();
    }
    return true;
  };

  const _setSessionData = (data) => {
    setSessionData((p) => ({
      ...p,
      sessions: data,
    }));
  };

  return (
    <SessionFormFields
      {...{
        pricePerFan: pricePerFanCents,
        callDurationSeconds,
        eventId,
        sessionData: sessionData.sessions,
        onSubmit,
      }}
      setSessionData={_setSessionData}
    />
  );
};

export default SessionsForm;
