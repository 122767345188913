import { Div, Modal } from "@max/common-ui";
import { fb } from "firebase-tools";
import { useReducer, useState } from "react";
import styled from "styled-components";
import { useUserContext } from "UserContextProvider";

const Meter = styled("meter")`
  width: 100%;
`;

const getBlobUploadPromise = async (userId, blob, key, dispatch) => {
  let promiseResolve;
  const promise = new Promise((resolve) => (promiseResolve = resolve));

  const mimeType = blob.type;
  const ext = mimeType.split(";")[0].split("/")[1];

  const storageRef = fb
    .storage()
    .ref(
      `/meetgreet_media/${userId}/${Math.random()
        .toString(36)
        .substring(2)}.${ext}`
    );
  const task = storageRef.put(blob);

  task.on(
    "state_changed",
    (s) =>
      dispatch({
        type: actions.uploadProgress,
        key,
        value: (s.bytesTransferred / s.totalBytes) * 100,
      }),
    () => console.log("uploadError"),
    async () =>
      promiseResolve({
        url: await task.snapshot.ref.getDownloadURL(),
        urlKey: `${key}Url`,
      })
  );

  return promise;
};

enum actions {
  uploadStart,
  uploadEnd,
  uploadProgress,
}

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case actions.uploadStart:
      return { ...state, [key]: { isUploading: true, progress: 0 } };
    case actions.uploadProgress:
      return {
        ...state,
        [key]: { isUploading: true, progress: value },
      };
    case actions.uploadEnd:
      return {
        ...state,
        [key]: {
          isUploading: false,
          progress: 100,
        },
      };
    default:
      throw new Error();
  }
};

export const useBlobUploads = (blobs) => {
  const { user } = useUserContext();
  const [state, dispatch] = useReducer(reducer, {});
  const [isUploadProgressModalOpen, setIsUploadProgressModalOpen] = useState(
    false
  );

  const uploadBlobs = async () => {
    setIsUploadProgressModalOpen(true);
    blobs.forEach(({ key }) => dispatch({ type: actions.uploadStart, key }));

    const urls: any = await Promise.all(
      blobs.map(({ key, blob }) =>
        getBlobUploadPromise(user.uid, blob, key, dispatch)
      )
    );

    setIsUploadProgressModalOpen(false);
    blobs.forEach(({ key }) => dispatch({ type: actions.uploadEnd, key }));

    return urls.reduce(
      (obj, curr) => ({ ...obj, [curr.urlKey]: curr.url }),
      {}
    );
  };

  const UploadProgress = ({ label, progress }) => {
    return (
      <Div fs_14 c_text mt_30 mb_10 semibold>
        {label}:
        <Div bold centered mt_5>
          <Meter value={progress} min="0" max="100" />
        </Div>
      </Div>
    );
  };

  const progressModalUI = (
    <>
      <Modal
        isOpen={isUploadProgressModalOpen}
        withBackdropClose={false}
        withCloseIcon={false}
      >
        <Div mb_20>
          <Div bold fs_16>
            Uploading...
          </Div>
          {blobs.map(
            ({ key, label }, index) =>
              state[key]?.isUploading && (
                <Div key={index}>
                  <UploadProgress
                    label={label}
                    progress={state[key].progress}
                  />
                </Div>
              )
          )}
        </Div>
      </Modal>
    </>
  );

  return {
    progressModalUI,
    uploadBlobs,
  };
};
