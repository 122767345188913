import { AppLoading, Div } from "@max/common-ui";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import { fstore } from "firebase-tools";
import { DateTime } from "luxon";
import React from "react";
import { CSVLink } from "react-csv";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled, { css } from "styled-components";
import AssetModal from "./AssetsModal/AssetModal";

const ChatModal = ({ onClose, fanName, uid }) => {
  const { routeSessionId } = useEventDetailContext();
  const [chat, isLoadingChat] = useCollectionData(
    fstore.collection(`/meet_greet/${routeSessionId}/rooms/${uid}/chats/`)
  );

  return (
    <>
      {isLoadingChat ? (
        <AppLoading />
      ) : (
        <ChatModalWithData {...{ onClose, fanName, chat, uid }} />
      )}
    </>
  );
};

const Container = styled(Div)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 48px 56px;
  width: 1000px;
  max-height: 400px;
  overflow-y: auto;
`;

const SET_LIVE_TEAM = "Set.Live Team";

const ChatModalWithData = ({ onClose, uid, fanName, chat }) => {
  const chatData = chat?.map((c: any) => ({
    sender: c.sender === uid ? fanName : SET_LIVE_TEAM,
    sent: DateTime.fromJSDate(new Date(c.sent.seconds * 1000))
      .toLocal()
      .toFormat("D T"),
    message: c.message,
  }));

  const DownloadIconWrapper = ({ children }) => (
    <CSVLink data={chatData} filename={"chat.csv"}>
      {children}
    </CSVLink>
  );

  return (
    <>
      <AssetModal onClose={onClose} DownloadIconWrapper={DownloadIconWrapper}>
        <Container>
          <Div fontweight_800 fs_25 c_text mb_30>
            Chat History
          </Div>
          {chatData.map((message, index) => (
            <Div key={index}>
              <Message {...message} />
            </Div>
          ))}
        </Container>
      </AssetModal>
    </>
  );
};

const Name = styled<any>("span")`
  font-weight: 600;
  color: ${(props) => props.theme.colors.text};
  ${(props) =>
    props.isSetlive &&
    css`
      color: ${(props) => props.theme.colors.purple20};
    `}
`;

const Span = styled<any>("span")`
  margin-left: 12px;
  color: ${(props) => props.theme.colors.gray40};
`;

const SpanLight = styled(Span)`
  font-weight: 300;
  color: ${(props) => props.theme.colors.gray30};
`;

const Message = ({ sender, message, sent }) => {
  return (
    <Div fs_15 mb_17>
      <p>
        <Name isSetlive={sender === SET_LIVE_TEAM}>{sender}</Name>
        <SpanLight>{sent}</SpanLight>
        <Span>{message}</Span>
      </p>
    </Div>
  );
};

export default ChatModal;
