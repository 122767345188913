import React, { createContext, useContext } from "react";

type State = {
  eventId: any;
  sessionData: any;
  setSessionData: any;
  pricePerFan: any;
  callDurationSeconds: any;
};
const SessionFormContext = createContext<State>({} as State);

export const SessionFormContextProvider = ({
  children,
  eventId,
  sessionData,
  setSessionData,
  pricePerFan,
  callDurationSeconds,
}) => {
  const value = {
    eventId,
    sessionData,
    setSessionData,
    pricePerFan,
    callDurationSeconds,
  };
  return (
    <SessionFormContext.Provider value={value}>
      {children}
    </SessionFormContext.Provider>
  );
};

export const useSessionFormContext = () => useContext(SessionFormContext);
