var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import SelectImageModal from "./SelectImageModal";
import { Icon, Button } from "notes";
import { Div } from "../../../Div";
var ImageButtons = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"])));
var Options = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  visibility: hidden;\n  opacity: 0;\n  transition: all ease 0.2s;\n"], ["\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  visibility: hidden;\n  opacity: 0;\n  transition: all ease 0.2s;\n"])));
var ImgContainer = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  box-sizing: border-box;\n  border: 1px solid\n    ", ";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: #ffffff;\n  border-radius: 4px;\n  overflow: hidden;\n  @media only screen and ", " {\n    border-style: solid;\n    border-color: ", ";\n    ", ";\n  }\n  @media only screen and ", " {\n    :hover {\n      cursor: pointer;\n      ", " {\n        visibility: visible;\n        opacity: 1;\n      }\n    }\n  }\n"], ["\n  box-sizing: border-box;\n  border: 1px solid\n    ", ";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: #ffffff;\n  border-radius: 4px;\n  overflow: hidden;\n  @media only screen and ", " {\n    border-style: solid;\n    border-color: ", ";\n    ", ";\n  }\n  @media only screen and ", " {\n    :hover {\n      cursor: pointer;\n      ", " {\n        visibility: visible;\n        opacity: 1;\n      }\n    }\n  }\n"])), function (props) { return props.theme.palette.gray[props.src ? "lightest" : "primary"]; }, function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.palette.gray.lightest; }, function (props) {
    return props.src
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            border-width: 1px;\n          "], ["\n            border-width: 1px;\n          "]))) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            border-width: 2px;\n          "], ["\n            border-width: 2px;\n          "])));
}, function (props) { return props.theme.media.desktop; }, Options);
var Img = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border: none;\n  margin: 0;\n  padding: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  border: none;\n  margin: 0;\n  padding: 0;\n"])));
var Label = styled(Div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #222222;\n  @media only screen and ", " {\n    color: ", ";\n    margin-top: 4px;\n    font-size: 12px;\n    line-height: 15px;\n  }\n"], ["\n  color: #222222;\n  @media only screen and ", " {\n    color: ", ";\n    margin-top: 4px;\n    font-size: 12px;\n    line-height: 15px;\n  }\n"])), function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.palette.gray.primary; });
var MediaIcon = styled(Icon)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 36px;\n  height: 36px;\n  path {\n    fill: ", ";\n  }\n  @media only screen and ", " {\n    width: 22px;\n    height: 22px;\n  }\n"], ["\n  width: 36px;\n  height: 36px;\n  path {\n    fill: ", ";\n  }\n  @media only screen and ", " {\n    width: 22px;\n    height: 22px;\n  }\n"])), function (props) { return props.theme.palette.gray.lighter; }, function (props) { return props.theme.media.mobile; });
var StyledButton = styled(Button)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: 20px;\n  ", ";\n  &:not(:disabled):hover {\n    background-color: ", ";\n    border-color: ", ";\n    color: #ffffff;\n  }\n"], ["\n  border-radius: 20px;\n  ", ";\n  &:not(:disabled):hover {\n    background-color: ", ";\n    border-color: ", ";\n    color: #ffffff;\n  }\n"])), function (props) {
    return props.isMobileImage && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      min-width: 108px;\n    "], ["\n      min-width: 108px;\n    "])));
}, function (props) { return props.theme.palette.purple.primary; }, function (props) { return props.theme.palette.purple.primary; });
var CircleButton = styled(Button)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border-radius: 50%;\n  min-width: 32px;\n  width: 32px;\n  height: 32px;\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  color: #ffffff;\n  &:not(:disabled):hover {\n    background: ", ";\n    color: #ffffff;\n  }\n"], ["\n  border-radius: 50%;\n  min-width: 32px;\n  width: 32px;\n  height: 32px;\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  color: #ffffff;\n  &:not(:disabled):hover {\n    background: ", ";\n    color: #ffffff;\n  }\n"])), function (props) { return props.theme.palette.gray.primary; });
var ImageInput = function (_a) {
    var value = _a.value, onSelect = _a.onSelect, name = _a.name, props = __rest(_a, ["value", "onSelect", "name"]);
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = useState(null), imgUrl = _c[0], setImgUrl = _c[1];
    useEffect(function () {
        if (value)
            setImgUrl(typeof value === "string" ? value : URL.createObjectURL(value));
    }, [value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectImageModal, __assign({ isOpen: isModalOpen, onSelect: onSelect, onClose: function () { return setIsModalOpen(false); } }, props)),
        React.createElement(ImgContainer, { dflex: true, justifyCenter: true, alignCenter: true, src: imgUrl, onClick: function () { return setIsModalOpen(true); } }, imgUrl ? (React.createElement(React.Fragment, null,
            React.createElement(Options, null,
                React.createElement(CircleButton, { type: "link" },
                    React.createElement(Icon, { tertiary: true, name: "Delete" })),
                React.createElement(StyledButton, { isMobileImage: name === "mobileImg", type: "secondaryReverse" }, "Replace")),
            React.createElement(Img, { src: imgUrl }))) : (React.createElement(ImageButtons, { dflexColumn: true, alignCenter: true },
            React.createElement(MediaIcon, { form: true, name: "Picture" }),
            React.createElement(Label, { fs_15: true, bold: true, mt_18: true }, "Add Image"))))));
};
export default ImageInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
