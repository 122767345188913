import { Div } from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { useAppContext } from "AppContextProvider";
import AlertModal from "components/common/AlertModal";
import Teammates from "components/common/Teammates";
import { shoutoutI } from "firebase-tools";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useEventDetailContext } from "../EventDetailContextProvider";

const actions = ["add", "update", "delete"] as const;
type Actions = typeof actions[number];

export const usePreviousObj = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value && JSON.parse(JSON.stringify(value));
  });
  return ref.current;
};

const SessionTeammates = () => {
  const { session, routeEventId, routeSessionId } = useEventDetailContext();
  const [isRemovedAlertModalOpen, setIsRemovedAlertModalOpen] = useState(false);
  const { setAppIsLoading } = useAppContext();
  const [teammates, setTeammates] = useState(null);
  const staleTeammateEmail = useRef(null);

  useEffect(() => {
    setTeammates(null);
    setTimeout(() => {
      setTeammates(
        session.roles
          .filter((r) => r.role !== RoleType.fan)
          .map((t) => ({ ...t, photo: t.image, isInitialValue: true }))
      );
    }, 10);
  }, [routeSessionId]);

  const previousTeammates = usePreviousObj(teammates);
  useEffect(() => {
    if (
      !!previousTeammates &&
      !!teammates &&
      JSON.stringify(previousTeammates) !== JSON.stringify(teammates)
    ) {
      (async () => {
        const getDiff = (): { action: Actions; payload: any } => {
          const pTeammates = previousTeammates || [];
          if (pTeammates.length === teammates.length) {
            return {
              action: "update",
              payload: teammates.find(
                (t, index) =>
                  pTeammates[index].role !== t.role ||
                  pTeammates[index].isAdmin !== t.isAdmin
              ),
            };
          }

          const newTeammates = teammates.filter(
            (t) => !pTeammates.map((pt) => pt.uid).includes(t.uid)
          );
          if (newTeammates.length > 0)
            return { action: "add", payload: newTeammates[0] };

          const removedTeammates = pTeammates.filter(
            (t) => !(teammates || []).map((pt) => pt.uid).includes(t.uid)
          );
          if (removedTeammates.length > 0)
            return {
              action: "delete",
              payload: removedTeammates[0],
            };

          return null;
        };

        const diff = getDiff();
        if (!diff) return;
        const { action, payload } = diff;

        if (payload.email === staleTeammateEmail.current) {
          staleTeammateEmail.current = null;
          return;
        }

        setAppIsLoading(true);
        const { error } = await shoutoutI.functions().meetgreet.sessionUser({
          action: action,
          sessionId: routeSessionId,
          identifier: { email: payload.email },
          role: { role: payload.role, isAdmin: payload.isAdmin },
          displayName: payload.name,
        });
        if (action === "delete") setIsRemovedAlertModalOpen(true);
        if (error) {
          setTeammates(previousTeammates);
          staleTeammateEmail.current = payload.email;
          console.log({ error });
          alert(error.message);
        }
        setAppIsLoading(false);
      })();
    }
  }, [teammates]);

  return (
    <Container>
      <AlertModal
        message="Teammate Removed"
        isOpen={isRemovedAlertModalOpen}
        onClose={() => setIsRemovedAlertModalOpen(false)}
      />
      <Div w100>
        <Div>
          <Teammates
            withRemoveConfirmationModal={true}
            options={session.artistGroupMembers}
            eventId={routeEventId}
            {...{ teammates, setTeammates, isCompact: true }}
          />
        </Div>
      </Div>
    </Container>
  );
};

export default SessionTeammates;

const Container = styled(Div)`
  box-sizing: border-box;
  width: 100%;
  padding-right: 20px;
  @media only screen and ${(props) => props.theme.media.desktop} {
    width: 392px;
    padding-right: 0;
  }
`;
