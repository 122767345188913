import { AppLayout, Div, Footer } from "@max/common-ui";
import { useEventNavigation } from "components/common/useEventNavigation";
import { Icon, H1, H3 } from "notes";
import { useContext, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";
import styled, { ThemeContext } from "styled-components";
import Navigation from "../common/Navigation";
import { useEventsAdminContext } from "./EventsAdminContextProvider";
import { ReactComponent as BlankStateImage } from "./common/assets/blankStateImage.svg";

const ScheduleButton = styled(Div)`
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
  color: ${(props) => props.theme.palette.action.primary};
  width: 184px;
  height: 40px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media only screen and ${(props) => props.theme.media.desktop} {
    color: ${(props) => props.theme.palette.black};
    border-radius: 28px;
    font-size: 20px;
    line-height: 26px;
    width: 392px;
    height: 56px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

export const BlankState = () => {
  const { eventIds } = useEventsAdminContext();
  return <>{eventIds && <BlankStateWithData />} </>;
};

const BlankStateWithData = () => {
  const {
    eventIds,
    defaultEventId,
    setSelectedFilter,
  } = useEventsAdminContext();
  const { gotoEventDetail } = useEventNavigation();
  const { artistGroupId } = useParams<any>();
  const history = useHistory();
  const {
    colors: { white: whiteColor },
  } = useContext(ThemeContext);

  useEffect(() => {
    if (eventIds.length > 0) gotoEventDetail(defaultEventId);
  }, [eventIds]);

  const scheduleButtonUI = (
    <ScheduleButton
      onClick={() => {
        setSelectedFilter("upcoming");
        history.push(generatePath(ROUTES.SCHEDULE_MEET, { artistGroupId }));
      }}
    >
      <Div alignCenter svg_purple20>
        <Icon form name="CalendarAdd" />
      </Div>
      <Div ml_9>Schedule</Div>
    </ScheduleButton>
  );

  return (
    <AppLayout>
      <Navigation withEventFilter />
      <Div>
        {eventIds.length === 0 && (
          <>
            <Content dflexColumn>
              <Header>You Don’t Have Any Upcoming Meet &amp; Greets...</Header>
              <Subheader>Schedule one now!</Subheader>
              {scheduleButtonUI}
            </Content>
            <BackgroundImage />
          </>
        )}
      </Div>
      <Footer background={whiteColor} />
    </AppLayout>
  );
};

const Content = styled(Div)`
  margin: 100px 0 0 100px;
  width: 100%;
  max-width: 600px;
  position: relative;
  z-index: 1;
  @media only screen and ${(props) => props.theme.media.mobile} {
    align-items: center;
    margin: 64px auto 0 auto;
    max-width: 242px;
  }
`;

const BackgroundImage = styled(BlankStateImage)`
  position: absolute;
  bottom: 96px;
  right: 72px;
  z-index: 0;
  width: min(50vw, 600px);
  @media only screen and ${(props) => props.theme.media.mobile} {
    right: 40px;
    bottom: 40px;
    width: 243px;
  }
`;

const Header = styled(H1)`
  margin-bottom: 8px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;

const Subheader = styled(H3)`
  color: ${(props) => props.theme.palette.gray.primary};
  margin: 0 0 40px 0;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    line-height: 22px;
    text-align: center;
  }
`;
