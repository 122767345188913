import { AppContainer, Div, IconWrapper, useQueryParams } from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import FormLayout from "components/common/FormLayout";
import { SessionMainFields } from "components/common/SessionFormFields/SessionSummary/SessionMainFields";
import { Icon } from "notes";
import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";
import styled from "styled-components";
import { EVENT_ADMIN_ROUTES } from "..";
import { FormContentWrapper, FormSection } from "../../common/form";
import { useEventsAdminContext } from "../EventsAdminContextProvider";
import AddAttendeeModal from "./AddAttendeeModal";
import RemoveAttendeeModal from "./RemoveAttendeeModal";

const Divider = styled(Div)`
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.colors.gray10};
`;

const AttendeeOptions = () => {
  const { sessions } = useEventsAdminContext();
  return <>{sessions && sessions.length > 0 && <AttendeOptionsWithData />}</>;
};

const AttendeOptionsWithData = () => {
  const { sessions } = useEventsAdminContext();
  const { sId: routeSessionId } = useQueryParams(["sId"]);
  const { eventId, artistGroupId } = useParams<any>();

  const [removeAttendeeUid, setRemoveAttendeeUid] = useState(null);
  const [isAddAttendeeModalOpen, setIsAddAttendeeModalOpen] = useState(false);
  const [isRemoveAttendeModalOpen, setIsRemoveAttendeModalOpen] = useState(
    false
  );
  const session = sessions.find((s) => s.sessionId === routeSessionId);
  const fans = session.roles.filter((r) => r.role === RoleType.fan);
  const history = useHistory();
  const jsStartDate = session.startDate.toJSDate();

  const removeTeammate = (uid) => {
    setRemoveAttendeeUid(uid);
    setIsRemoveAttendeModalOpen(true);
  };

  const onGoBack = () =>
    history.push(
      `${generatePath(
        `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.EVENT_DETAIL}`,
        {
          artistGroupId,
          eventId,
        }
      )}?sId=${routeSessionId}`
    );

  const modalsUI = (
    <>
      <AddAttendeeModal
        isOpen={isAddAttendeeModalOpen}
        onClose={() => setIsAddAttendeeModalOpen(false)}
      />
      <RemoveAttendeeModal
        name={fans.find((f) => f.uid === removeAttendeeUid)?.name}
        uid={removeAttendeeUid}
        isOpen={isRemoveAttendeModalOpen}
        onClose={() => {
          setIsRemoveAttendeModalOpen(false);
          setRemoveAttendeeUid(null);
        }}
      />
    </>
  );

  const addAttendeeButtonUI = (
    <Div inlineBlock onClick={() => setIsAddAttendeeModalOpen(true)}>
      <Div dflex c_action>
        <Div alignCenter>
          <Icon tertiary name="Add" />
        </Div>
        <Div ml_10 link fs_17 semiBold>
          Add Attendee
        </Div>
      </Div>
    </Div>
  );

  const addAttendeeUI = (
    <FormContentWrapper mb_50>
      <Div mt_20 mb_20>
        {addAttendeeButtonUI}
      </Div>
      {fans.map((fan, index) => (
        <Div key={index} dflex h_40 alignCenter>
          <IconWrapper onClick={() => removeTeammate(fan.uid)}>
            <Icon height="8px" platform name="Close" />
          </IconWrapper>
          <Div ml_12 fs_15 c_text>
            <Div truncateText mr_15>
              {fan.name || fan.email}
            </Div>
          </Div>
        </Div>
      ))}
      <Div h_20 />
    </FormContentWrapper>
  );

  const sessionSummaryUI = (
    <Div>
      <FormSection
        header="Sessions Overview"
        icon={<Icon form name="Series" />}
        description="The session has already been scheduled for the time below. If you would like to make an update to the session details, please contact support@set.live"
      />
      <FormContentWrapper mt_25>
        <SessionMainFields
          {...{
            startDate: jsStartDate,
            startTime:
              jsStartDate.getHours() * 60 * 60 + jsStartDate.getMinutes() * 60,
            maximumAttendees: session.slotCount,
            callDurationSeconds: session?.sessionDuration,
            pricePerFan: session?.unitPrice,
          }}
        />
      </FormContentWrapper>
    </Div>
  );

  return (
    <>
      {modalsUI}
      <FormLayout
        label="Attendee Options"
        title={session?.eventName}
        onGoBack={onGoBack}
      >
        <AppContainer>{sessionSummaryUI}</AppContainer>
        <Divider mt_50 />
        <AppContainer>
          <Div mt_40>
            <FormSection
              header="Attendees"
              icon={<Icon form name="Audience" />}
              description="You may add guest list attendees below. Please note that attendees will be emailed a pass to their session immediately upon being added to the event."
            />
          </Div>
          {addAttendeeUI}
        </AppContainer>
      </FormLayout>
    </>
  );
};

export default AttendeeOptions;
