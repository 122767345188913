import { Div } from "@max/common-ui";
import styled from "styled-components";
import { H4, Link } from "notes";

export const EditCard = ({
  title,
  action,
  setEdit,
  edit = false,
  children,
  ...props
}) => {
  return (
    <CardBase {...props}>
      <CardHeader spaceBetween>
        <H4>{title}</H4>
        {!edit && <Link onClick={() => setEdit(true)}>{action}</Link>}
      </CardHeader>
      {children}
    </CardBase>
  );
};

export const CardBase = styled(Div)`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  padding: 32px 52px 40px;
  ${H4} {
    font-weight: 700;
    margin: 0;
  }
  & + & {
    margin-top: 24px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    background-color: transparent;
    box-shadow: none;
    padding: 22px 20px 32px 20px;
    ${H4}, ${Link} {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const CardHeader = styled(Div)`
  margin-bottom: 16px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-bottom: 26px;
  }
`;
