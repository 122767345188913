import { ArtistGroups } from "components/ArtistGroups";
import ArtistManagement from "components/ArtistManagement";
import ScheduleMeetGreet from "components/ScheduleMeetGreet";
import { StripeConnectRedirect } from "components/StripeConnectRedirect";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import EventsAdmin from "./components/EventsAdmin";
import { Login } from "./components/Login";
import { MyAccount } from "./components/Account";
import { useUserContext } from "./UserContextProvider";

export const ROUTES = {
  STRIPE_CONNECT: "/stripe-connect/:artistGroupId/:redirectTo",
  ARTISTS_GROUPS: "/artists",
  LOGIN: "/login",
  ACCOUNT: "/account",
  EVENTS_ADMIN: "/artist/:artistGroupId/events",
  SCHEDULE_MEET: "/artist/:artistGroupId/schedule-meet",
  ARTIST_MANAGEMENT: "/artist-management",
};

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

const RouterScrollToTop = withRouter(ScrollToTop);

const ProtectedRoute = ({ path, children, exact = false }) => {
  const { isLoggedIn } = useUserContext();
  return (
    <>
      {isLoggedIn ? (
        <Route exact={exact} path={path}>
          {children}
        </Route>
      ) : (
        <Redirect to={ROUTES.LOGIN} />
      )}
    </>
  );
};

const Routes = () => {
  const { isLoggedIn } = useUserContext();

  const DefaultRoute = () => (
    <Redirect to={isLoggedIn ? ROUTES.ARTISTS_GROUPS : ROUTES.LOGIN} />
  );
  return (
    <Router>
      <RouterScrollToTop />
      <Switch>
        <ProtectedRoute exact path={ROUTES.SCHEDULE_MEET}>
          <ScheduleMeetGreet />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.STRIPE_CONNECT}>
          <StripeConnectRedirect />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.ARTIST_MANAGEMENT}>
          <ArtistManagement />
        </ProtectedRoute>
        <Route path={ROUTES.LOGIN}>
          <DefaultRoute />
          <Login />
        </Route>
        <ProtectedRoute path={ROUTES.EVENTS_ADMIN}>
          <EventsAdmin />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.ARTISTS_GROUPS}>
          <ArtistGroups />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.ACCOUNT}>
          <MyAccount />
        </ProtectedRoute>
        <Route component={() => <DefaultRoute />} />
      </Switch>
    </Router>
  );
};

export default Routes;
