import {
  AppContainer,
  AppLayout,
  AppLoading,
  Div,
  Footer,
  useIsMobile,
} from "@max/common-ui";
import { useContext, useEffect, useState, ReactNode } from "react";
import styled, { ThemeContext } from "styled-components";
import { Input, Icon } from "notes";
import { fstore, shoutoutI } from "firebase-tools";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from "Routes";
import { StripeConnectMessage as StripeConnectMessageBase } from "./common/StripeConnectMessage";
import { ArtistGroupRole } from "@musicaudienceexchange/shoutout-interface/lib/firestore/artists";
import { sRedirects } from "./StripeConnectRedirect";
import Navigation from "./common/Navigation";
import { useUserContext } from "UserContextProvider";
import { storeSelectedFilter } from "./common";
import { MobileDrawerTrigger } from "./common/MobileDrawerTrigger";

const InputWrapper = styled(Div)`
  margin-top: 56px;
  width: 392px;
  margin-bottom: 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
    margin-bottom: 25px;
    width: 100%;
  }
`;

const StripeConnectMessage = styled(StripeConnectMessageBase)`
  display: flex;
  margin: 20px;
  padding: 8px 16px;
  border: solid 1px ${(props) => props.theme.colors.yellow40};
  border-radius: 4px;
  font-size: 12px;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.24),
    0 6px 18px 0 rgba(0, 0, 0, 0.5);
  @media all and ${(props) => props.theme.media.mobile} {
    margin: 10px;
    padding: 5px;
  }
`;

const ArtistsContainer = styled(Div)`
  width: 100%;
  display: grid;
  grid-gap: 17px;
  grid-row-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  margin-bottom: 70px;
  @media all and ${(props) => props.theme.media.mobile} {
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
  }
`;

const Layout = ({
  children,
  mobileSearchUI,
}: {
  children?: ReactNode;
  mobileSearchUI?: ReactNode;
}) => {
  const {
    colors: { white: whiteColor },
  } = useContext(ThemeContext);

  useEffect(() => {
    document.body.style.backgroundColor = whiteColor;
  }, []);

  return (
    <AppLayout>
      <Navigation mobileSearchUI={mobileSearchUI} />
      <AppContainer>{children}</AppContainer>
      <Footer background={whiteColor} />
    </AppLayout>
  );
};

export const ArtistGroups = () => {
  const { user, isAdmin } = useUserContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      if (isAdmin !== null) {
        const res = isAdmin
          ? await fstore.collection("artist_groups").get()
          : await fstore
              .collection("artist_groups")
              .where(`members.${user.uid}`, "!=", "")
              .get();

        setData(
          res?.docs?.map((d) => {
            const docData = d.data();
            const docId = d.id;
            return {
              groupId: docId,
              name: docData.name,
              isStripeReady:
                docData.stripeConnectId &&
                docData.stripeConnectStatus === "active",
              imageUrl: docData?.assets?.icon?.path,
              members: docData?.members,
            };
          })
        );
      }
    })();
  }, [isAdmin]);

  return <>{!data ? <AppLoading /> : <ArtistGroupsWithData data={data} />}</>;
};

const ArtistGroupsWithData = ({ data }) => {
  const [query, setQuery] = useState("");
  const [artists, setArtists] = useState(data);

  useEffect(() => {
    setArtists(
      data?.filter((a) => a.name.toLowerCase().includes(query.toLowerCase()))
    );
  }, [query]);

  return (
    <Layout
      mobileSearchUI={
        <Input
          leftIcon={<MobileDrawerTrigger />}
          placeholder="Search for an artist by name..."
          value={query}
          onChange={(v) => setQuery(v)}
        />
      }
    >
      <Div h_86 forMobile />
      <Div mcontainer>
        <InputWrapper forDesktop>
          <Input
            leftIcon={
              <Div mt_n2>
                <Icon name="Search" form />
              </Div>
            }
            placeholder="Search for an artist by name..."
            value={query}
            onChange={(v) => setQuery(v)}
          />
        </InputWrapper>
        <ArtistsContainer>
          {artists.map((a, index) => (
            <Div key={index}>
              <Artist {...a} />
            </Div>
          ))}
          {artists.length === 0 && (
            <Div w100 centered mt_100 fs_20 c_gray30>
              No results found
            </Div>
          )}
        </ArtistsContainer>
      </Div>
    </Layout>
  );
};

const ArtistName = styled(Div)`
  color: white;
  position: absolute;
  left: 18px;
  bottom: 24px;
  font-family: Work Sans;
  font-weight: bold;
  font-size: 25px;
  padding-right: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    color: #222222;
    font-size: 17px;
    line-height: 22px;
    position: relative;
    bottom: initial;
    left: initial;
    padding: 12px 0 0 0;
    text-align: center;
  }
`;

const ArtistImage = styled(Div)`
  padding-bottom: 100%;
  position: relative;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 180%),
    url(${(props) => props.url});
  background-size: cover;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.11);
`;

const ArtistWrapper = styled(Div)`
  border-radius: 8px;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24), 0 1px 8px 0 rgba(0, 0, 0, 0.32);
  overflow: hidden;
  position: relative;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const StripeConnectMessageWrapper = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
`;

const admin: ArtistGroupRole = "admin";

const Artist = ({ name, groupId, imageUrl, members, isStripeReady }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { user } = useUserContext();
  const isArtistGroupAdmin = members[user.uid]?.includes(admin);
  const [
    data,
    setData,
  ] = useState<shoutoutI.functions.artists.GetArtistGroupResponse | null>(null);

  useEffect(() => {
    if (!data)
      (async () => {
        const {
          result,
          error,
        } = await shoutoutI.functions().artists.getArtistGroup({ groupId });
        if (!error) setData(result);
      })();
  }, []);

  const getArtistGroupAdmins = () => {
    if (data)
      return data.members
        .filter((m) => m.roles.includes("admin"))
        .map((m) => m.name);
    else return null;
  };

  return (
    <>
      <ArtistWrapper
        positionRelative
        onClick={() => {
          storeSelectedFilter("upcoming");
          history.push(
            generatePath(`${ROUTES.EVENTS_ADMIN}`, {
              artistGroupId: groupId,
            })
          );
        }}
      >
        <>
          <ArtistImage url={imageUrl}>
            {!isMobile && <ArtistName>{name}</ArtistName>}
          </ArtistImage>
          <StripeConnectMessageWrapper>
            <StripeConnectMessage
              artistGroupAdmins={getArtistGroupAdmins()}
              stripeRedirectTo={sRedirects.artistGroups}
              artistGroupId={groupId}
              isArtistGroupAdmin={isArtistGroupAdmin}
              isStripeReady={isStripeReady}
            />
          </StripeConnectMessageWrapper>
        </>
      </ArtistWrapper>
      {isMobile && <ArtistName>{name}</ArtistName>}
    </>
  );
};
