import {
  Div,
  Dropdown as DropdownBase,
  DropdownContainer,
  IconWrapper as IconWrapperBase,
} from "@max/common-ui";
import AlertModal from "components/common/AlertModal";
import { useEventNavigation } from "components/common/useEventNavigation";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import { Icon } from "notes";
import React, { useState } from "react";
import styled, { css } from "styled-components";

const IconWrapper = styled(IconWrapperBase)`
  transform: rotate(90deg);
`;

const Dropdown = styled(DropdownBase)`
  ${DropdownContainer} {
    border: none;
    padding: 0;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
    margin-left: -210px;
    @media only screen and ${(props) => props.theme.media.desktop} {
      margin-top: -20px;
      margin-left: -256px;
    }
  }
`;

const MenuItemWrapper = styled(Div)`
  box-sizing: border-box;
  font-weight: 600;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  width: 288px;
  user-select: none;
  color: ${(props) => props.theme.colors.text};
  :hover {
    cursor: pointer;
    background: #f5f5f5;
    ${(props) =>
      props.isDelete &&
      css`
        background: ${(props) => props.theme.colors.red20};
        color: ${(props) => props.theme.colors.white};
      `}
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: 242px;
  }
`;

const Divider = styled(Div)`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.gray10};
`;

const MenuItem = ({ text, isDelete = false, onClick = null, icon = null }) => {
  return (
    <MenuItemWrapper {...{ onClick, isDelete }}>
      <Div dflex>
        {icon && (
          <Div alignCenter svg_text mr_18>
            {icon}
          </Div>
        )}
        <Div fs_17>{text}</Div>
      </Div>
    </MenuItemWrapper>
  );
};

const EventMenu = ({ setIsShareTicketSiteModalOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { routeEventId } = useEventDetailContext();
  const {
    gotoAddSession,
    gotoEditMedia,
    gotoEditTicketSite,
  } = useEventNavigation();

  const iconUI = (
    <TriggerContainer centered>
      <IconWrapper w_100 onClick={() => setIsOpen(true)}>
        <Icon platform name="More" />
      </IconWrapper>
    </TriggerContainer>
  );

  const modalUI = (
    <>
      <AlertModal
        isBold={false}
        message="Please contact support@set.live for event cancellation"
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
    </>
  );

  return (
    <>
      {modalUI}
      <Dropdown isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuItem
          text="Share Ticket Site"
          icon={<Icon form name="Share" />}
          onClick={() => {
            setIsOpen(false);
            setIsShareTicketSiteModalOpen(true);
          }}
        />
        <MenuItem
          text="Add Session"
          icon={<Icon tertiary name="Add" />}
          onClick={() => gotoAddSession(routeEventId)}
        />
        <MenuItem
          onClick={gotoEditTicketSite}
          text="Edit Ticket Site"
          icon={<Icon tertiary name="Pencil" />}
        />
        <MenuItem
          text="Edit Media"
          onClick={gotoEditMedia}
          icon={<Icon tertiary name="Pencil" />}
        />
        <Divider />
        {/* <MenuItem text="Registration Status" /> */}
        <MenuItem
          onClick={() => {
            setIsOpen(false);
            setIsInfoModalOpen(true);
          }}
          isDelete
          text="Cancel Event"
        />
      </Dropdown>
      {iconUI}
    </>
  );
};

export default EventMenu;

const TriggerContainer = styled(Div)`
  svg path {
    fill: #ffffff;
  }
  @media only screen and ${(props) => props.theme.media.desktop} {
    svg path {
      fill: ${(props) => props.theme.palette.gray.primary};
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    background: rgba(0, 0, 0, 0.72);
    border-radius: 50%;
    color: #ffffff;
    margin: 0;
    width: 32px;
    height: 32px;
  }
`;
