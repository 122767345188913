import {
  watermarkInitialValues,
  WatermarkSource,
} from "components/ScheduleMeetGreet/ScheduleMeetGreetContextProvider";
import styled, { css } from "styled-components";
import { Checkbox } from "../common";
import { useEventMediaContext } from "../EventMediaContextProvider";
import setliveLogo from "./setlive-logo.png";
import { useImageWithWatermarkPng } from "./useImageWithWatermarkPng";
import WatermarkInput from "./WatermarkInput";

const desktopDefaultWatermarkPosition = {
  top: 0.74,
  left: 0.862,
  width: 0.1,
  height: 0.2,
};

const mobileDefaultWatermarkPosition = {
  top: 0.872,
  left: 0.742,
  width: 0.2,
  height: 0.1,
};

export const WatermarkField = () => {
  const { mediaData, setMediaData } = useEventMediaContext();
  const {
    selectedWatermarkOption,
    watermarkImgUrl,
    watermarkConfig: { includesGradient, desktopPosition, mobilePosition },
    includeWatermark,
  } = mediaData;

  useImageWithWatermarkPng({
    width: 1280,
    height: 640,
    fieldName: "desktopImg",
    includesGradient,
    config:
      selectedWatermarkOption === WatermarkSource.custom
        ? { watermarkImgUrl, position: desktopPosition }
        : {
            watermarkImgUrl: setliveLogo,
            position: desktopDefaultWatermarkPosition,
          },
  });

  useImageWithWatermarkPng({
    width: 640,
    height: 1280,
    fieldName: "mobileImg",
    includesGradient,
    config:
      selectedWatermarkOption === WatermarkSource.custom
        ? { watermarkImgUrl, position: mobilePosition }
        : {
            watermarkImgUrl: setliveLogo,
            position: mobileDefaultWatermarkPosition,
          },
    gradientHeight: 0.15,
  });

  return (
    <>
      <StyledCheckbox
        isSelected={includeWatermark}
        label="Upload a Custom Watermark (optional)"
        description="Check this box if you would like to upload and include a custom watermark such as the artist’s logo in the video screen. By default video calls will include a Set.Live watermark."
        onClick={(value) =>
          setMediaData((p) => {
            let newMediaData = { ...p, includeWatermark: value };
            if (!value)
              newMediaData = { ...newMediaData, ...watermarkInitialValues };
            return newMediaData;
          })
        }
      />
      {includeWatermark && <WatermarkInput />}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${(props) =>
      props.isSelected &&
      css`
        padding-bottom: 0;
      `};
  }
`;
