import { AppContainer, Div } from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import FormLayout from "components/common/FormLayout";
import { TicketSiteFormFields } from "components/common/TicketSiteFormFields";
import { useEventNavigation } from "components/common/useEventNavigation";
import { fstore } from "firebase-tools";
import { Button } from "notes";
import React from "react";
import { useEventDetailContext } from "./EventDetailContextProvider";

const EditTicketSite = () => {
  const { event } = useEventDetailContext();
  return <>{event && <EditTicketSiteWithData />}</>;
};

const EditTicketSiteWithData = () => {
  const { event, routeEventId } = useEventDetailContext();
  const { setAppIsLoading } = useAppContext();
  const { gotoCurrentEventDetail } = useEventNavigation();

  const ticketSiteData = {
    welcomeMessage: event?.checkoutDescription,
    rulesLimitations:
      event?.checkoutWhatToExpect && event?.checkoutWhatToExpect[0],
    pricePerFan: event.unitPrice / 100,
    callDurationSeconds: event?.sessionDuration,
  };

  const onSubmit = async (values) => {
    const {
      welcomeMessage,
      pricePerFan,
      callDurationSeconds,
      rulesLimitations,
    } = values;

    const event: any = {
      checkoutDescription: welcomeMessage,
      unitPrice: parseFloat(pricePerFan) * 100,
      sessionDuration: parseFloat(callDurationSeconds),
    };
    if (rulesLimitations) event.checkoutWhatToExpect = [rulesLimitations];

    setAppIsLoading(true);
    await fstore
      .doc(`meet_greet_events/${routeEventId}`)
      .set(event, { merge: true });
    setAppIsLoading(false);
    gotoCurrentEventDetail();
  };

  const FormButtons = ({ onSubmit }) => {
    return (
      <Div mt_75 dflex>
        <Div ml_30>
          <Button onClick={onSubmit}>Save Changes</Button>
        </Div>
      </Div>
    );
  };

  return (
    <FormLayout
      label="Edit Ticket Site"
      title={event.artist}
      onGoBack={gotoCurrentEventDetail}
    >
      <AppContainer>
        <TicketSiteFormFields {...{ ticketSiteData, onSubmit, FormButtons }} />
      </AppContainer>
    </FormLayout>
  );
};

export default EditTicketSite;
