import { AppLoading } from "@max/common-ui";
import { shoutoutI } from "firebase-tools";
import React, { createContext, useContext, useEffect, useState } from "react";

type State = {
  isLoading: boolean;
  teammates: any;
  setTeammates: any;
  teamMembersOptions: any;
  isCompact: boolean;
};
const TeammatesContext = createContext<State>({} as State);

export const TeammatesContextProvider = ({
  children,
  teammates,
  setTeammates,
  eventId,
  options = null,
  isCompact,
}) => {
  const [teamMembersOptions, setTeamMembersOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);
  const value = {
    isLoading,
    teammates,
    setTeammates,
    teamMembersOptions,
    isCompact,
  };

  useEffect(() => {
    if (!options)
      (async () => {
        setIsLoading(true);
        const res = await shoutoutI
          .functions()
          .meetgreet.getTeamMembers({ eventId });
        setTeamMembersOptions(res?.result?.members);
        setIsLoading(false);
      })();
  }, [eventId]);

  return (
    <TeammatesContext.Provider value={value}>
      {isLoading ? <AppLoading /> : children}
    </TeammatesContext.Provider>
  );
};

export const useTeammatesContext = () => useContext(TeammatesContext);
