import React from "react";

export const priceTag = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.148 1.148a.507.507 0 01.717 0l.756.755c.101-.07.208-.135.319-.192.486-.25.999-.323 1.432-.204l3.516.958c.28.077.525.216.72.41L14.76 9.03c.396.396.289 1.145-.239 1.672l-3.82 3.821c-.528.528-1.277.635-1.673.24L2.875 8.606a1.603 1.603 0 01-.41-.719l-.958-3.516c-.152-.556.015-1.205.397-1.752l-.756-.755a.507.507 0 010-.717zm2.227 1.509l.281.283a.507.507 0 11-.716.716l-.283-.282a.803.803 0 00-.06.472l.8 3.676a.735.735 0 00.195.369l6.154 6.154 4.299-4.3L7.89 3.593a.735.735 0 00-.37-.195l-3.675-.8a.805.805 0 00-.471.06zM7.88 5.014l4.732 4.732-2.866 2.866L5.014 7.88 7.88 5.014z"
      fill="currentColor"
    />
  </svg>
);
