import { Div, Videojs } from "@max/common-ui";
import { useEventDetailContext } from "components/EventsAdmin/EventDetailContextProvider";
import { fstore } from "firebase-tools";
import React from "react";
import { AssetsModal } from "./AssetsModal";

const AssetComponent = ({ isMobile, source }) => {
  return (
    <>
      {isMobile ? (
        <Div w_1000 centered>
          <Videojs width="250px" height="500px" src={source} />
        </Div>
      ) : (
        <Videojs width="1000px" height="500px" src={source} />
      )}
    </>
  );
};

export const VideosModal = ({ isOpen, uid, onClose }) => {
  const { routeSessionId } = useEventDetailContext();

  return (
    <>
      {isOpen && (
        <AssetsModal
          label="Video"
          onClose={onClose}
          AssetComponent={AssetComponent}
          query={fstore
            .collection(`/meet_greet/${routeSessionId}/rooms/${uid}/sessions`)
            .where("source.landscape", "!=", "")}
        />
      )}
    </>
  );
};
