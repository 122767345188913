import { Div } from "@max/common-ui";
import styled from "styled-components";
import {
  DragAreaContextProvider,
  useDragAreaContext,
} from "./DragAreaContextProvider";

const DragAreaContainer = styled(Div)`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  background: lightblue;
  touch-action: none;
`;

export const DragArea = ({ children, width = 300, height = 300 }) => {
  return (
    <DragAreaContextProvider>
      <DragAreaWithContext {...{ width, height }}>
        {children}
      </DragAreaWithContext>
    </DragAreaContextProvider>
  );
};

const DragAreaWithContext = ({ children, width, height }) => {
  const { dragAreaRef, dragAreaRect } = useDragAreaContext();

  return (
    <DragAreaContainer
      {...{ width, height }}
      ref={dragAreaRef}
      positionRelative
    >
      {children}
    </DragAreaContainer>
  );
};
