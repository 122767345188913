var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled, { css } from "styled-components";
import { GridRow, Select, Icon } from "notes";
import { useVideoRecordContext } from "../VideoRecordContextProvider";
import { Div } from "../../../Div";
export var micIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M5 7v1a3 3 0 005.995.176L11 8V7h1v1a4 4 0 01-3.5 3.969V15h2a.5.5 0 110 1h-5a.5.5 0 110-1h2v-3.03A4 4 0 014 8V7h1zm3-7a2 2 0 012 2v6a2 2 0 11-4 0V2a2 2 0 012-2zm_0 1a1 1 0 00-.993.883L7 2v6a1 1 0 001.993.117L9 8V2a1 1 0 00-1-1z", fill: "#596266" })));
export var IODeviceSelector = function () {
    var _a = useVideoRecordContext(), userIsRecordingVideo = _a.userIsRecordingVideo, selectedAudioDeviceId = _a.selectedAudioDeviceId, selectedVideoDeviceId = _a.selectedVideoDeviceId, setSelectedAudioDeviceId = _a.setSelectedAudioDeviceId, setSelectedVideoDeviceId = _a.setSelectedVideoDeviceId, videoOptions = _a.videoOptions, audioOptions = _a.audioOptions;
    var isDeviceSelectionEnabled = videoOptions.length > 0 && audioOptions.length > 0 && !userIsRecordingVideo;
    return (React.createElement(Container, { isDeviceSelectionDisabled: !isDeviceSelectionEnabled },
        React.createElement(SelectVideo, { disabled: !isDeviceSelectionEnabled, icon: React.createElement(Label, { yCenter: true },
                React.createElement(Icon, { form: true, name: "Video" }),
                " Video:"), options: videoOptions || [], selected: videoOptions.find(function (vo) { return vo.id == selectedVideoDeviceId; }) || "", onChange: function (o) { return setSelectedVideoDeviceId(o.id); } }),
        React.createElement(Div, { mt_15: true },
            React.createElement(SelectMicrophone, { disabled: !isDeviceSelectionEnabled, icon: React.createElement(Label, { yCenter: true },
                    micIcon,
                    " Microphone:"), options: audioOptions || [], selected: audioOptions.find(function (vo) { return vo.id == selectedAudioDeviceId; }) || "", onChange: function (o) { return setSelectedAudioDeviceId(o.id); } }))));
};
var Container = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  margin-top: 16px;\n  z-index: 100;\n"], ["\n  ", "\n  margin-top: 16px;\n  z-index: 100;\n"])), function (props) {
    return props.isDeviceSelectionDisabled && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      pointer-events: none;\n      user-select: none;\n      opacity: 0.3;\n    "], ["\n      pointer-events: none;\n      user-select: none;\n      opacity: 0.3;\n    "])));
});
var SelectVideo = styled(Select)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: default;\n  padding-left: 94px;\n"], ["\n  cursor: default;\n  padding-left: 94px;\n"])));
var SelectMicrophone = styled(Select)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: default;\n  padding-left: 140px;\n"], ["\n  cursor: default;\n  padding-left: 140px;\n"])));
var Label = styled(GridRow)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  svg {\n    margin-right: 12px;\n  }\n"], ["\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  svg {\n    margin-right: 12px;\n  }\n"])));
export default IODeviceSelector;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
