import styled, { css } from "styled-components";
import { Div } from "@max/common-ui";
import { FlexColumn, FlexRow, Icon } from "notes";
import { ReactComponent as Logo } from "./assets/LogoGradient.svg";
import { ReactComponent as FormQuestions } from "./assets/FormQuestions.svg";
import { useUserContext } from "UserContextProvider";
import { useAppContext } from "AppContextProvider";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "Routes";
import { fb } from "firebase-tools";
import { ReactNode } from "react";

const NavItem = ({ icon, label, ...props }) => {
  return (
    <ItemContainer yCenter {...props}>
      {icon}
      {label}
    </ItemContainer>
  );
};

export const Drawer = ({ mobileSearchUI }: { mobileSearchUI?: ReactNode }) => {
  const { user } = useUserContext();
  const { isDrawerOpen, setIsDrawerOpen } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const path = location?.pathname.replace(/\//g, "");
  const signOut = async () => {
    await fb.auth().signOut();
  };

  const handleAction = (action) => {
    setIsDrawerOpen(false);
    return action;
  };
  return (
    <>
      <Wrapper isDrawerOpen={isDrawerOpen}>
        {isDrawerOpen ? (
          <>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            {/* <NavItem
          icon={<Icon tertiary name="Calendar" />}
          label="Upcoming Events"
          isSelected={false}
          onClick={() => console.log("")}
        />
        <NavItem
          icon={<Icon form name="Calendar" />}
          label="Past Events"
          isSelected={false}
          onClick={() => console.log("")}
        /> */}
            <NavItem icon={<Icon form name="Dashboard" />} label="Dashboard" />
            <Divider />
            <NavItem
              isSelected={path === "account"}
              icon={
                <AvatarContainer xCenter yCenter>
                  {user?.photoURL ? (
                    <Avatar src={user?.photoURL} />
                  ) : user?.displayName ? (
                    user?.displayName[0]
                  ) : (
                    user?.email[0].toUpperCase()
                  )}
                </AvatarContainer>
              }
              label="My Account"
              onClick={() => handleAction(history.push(ROUTES.ACCOUNT))}
            />
            <NavItem
              isSelected={path === "artists"}
              icon={<Icon form name="Gear" />}
              label="My Artists"
              onClick={() => handleAction(history.push(ROUTES.ARTISTS_GROUPS))}
            />
            <NavItem icon={<FormQuestions />} label="Help" />
            <Divider />
            <ItemContainer gray onClick={() => handleAction(signOut())}>
              Sign Out
            </ItemContainer>
          </>
        ) : (
          ""
        )}
      </Wrapper>
      <Backdrop
        isDrawerOpen={isDrawerOpen}
        onClick={() => setIsDrawerOpen(false)}
      />
      {mobileSearchUI && <SearchContainer>{mobileSearchUI}</SearchContainer>}
    </>
  );
};

const SearchContainer = styled(Div)`
  padding: 16px 16px 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  input {
    border: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
    &:focus {
      box-shadow: 0 2px 6px 0 rgb(124, 96, 217, 0.52);
    }
  }
`;

const Divider = styled.div`
  background: ${(props) => props.theme.palette.gray.lightest};
  margin: 12px 0;
  width: 100%;
  height: 1px;
`;

const Wrapper = styled(FlexColumn)`
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 296px;
  height: 100%;
  z-index: 1001;

  box-shadow: ${(props) =>
    props.isDrawerOpen ? "0 0 16px 0 rgba(0, 0, 0, 0.32)" : ""};
  transform: ${(props) => (props.isDrawerOpen ? "" : "translateX(-100%)")};
  will-change: transform;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);

  @media only screen and ${(props) => props.theme.media.desktop} {
    display: none;
  }
`;

const Backdrop = styled(FlexColumn)`
  background: rgba(0, 0, 0, 0.38);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  user-select: none;

  opacity: ${(props) => (props.isDrawerOpen ? "0.7" : "0")};
  display: ${(props) => (props.isDrawerOpen ? "block" : "none")};
  :hover {
    cursor: pointer;
  }
`;

const LogoContainer = styled(FlexRow)`
  padding: 20px 0 36px 24px;
`;

const ItemContainer = styled(FlexRow)`
  border-radius: 0 20px 20px 0;
  background-color: ${(props) =>
    props.isSelected ? props.theme.palette.purple.lightest : "#ffffff"};
  color: ${(props) =>
    props.isSelected
      ? props.theme.palette.purple.primary
      : props.theme.palette.black};
  ${(props) =>
    props.gray &&
    css`
      color: ${props.theme.palette.gray.primary};
    `};
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  margin-right: 12px;
  padding: 9px 0 9px 28px;
  height: 40px;
  user-select: none;
  cursor: pointer;
  svg {
    margin-right: 12px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AvatarContainer = styled(FlexRow)`
  background-color: ${(props) => props.theme.palette.orange.primary};
  border-radius: 100%;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin: -2px 10px -2px -2px;
  text-align: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

const Avatar = styled.img`
  height: 20px;
  width: 20px;
`;
