import { Div } from "@max/common-ui";
import styled from "styled-components";

export const FormContentWrapper = styled(Div)`
  margin-left: 34px;
  max-width: 650px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 52px;
    margin-right: 20px;
  }
`;

export const FieldLabel = styled(Div)`
  margin-top: 24px;
  margin-bottom: 4px;
  font-size: 15px;
  color: ${(props) => props.theme.colors.text};
`;

const IconWrapper = styled(Div)`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(props) => props.theme.palette.gray.primary};
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 0;
  }
`;

const TitleContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-left: 20px;
  }
`;

const Title = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const FormSection = ({ icon, header, description }) => {
  return (
    <Div c_text>
      <TitleContainer dflex alignCenter>
        <IconWrapper dflex>{icon}</IconWrapper>
        <Title fs_17 ml_16 lh_22 extraBold>
          {header}
        </Title>
      </TitleContainer>
      <FormContentWrapper mt_4>
        <Div lite fs_15>
          {description}
        </Div>
      </FormContentWrapper>
    </Div>
  );
};
