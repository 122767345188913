import { Div, MobileDivider } from "@max/common-ui";
import { Button as ButtonBase, Icon } from "notes";
import styled from "styled-components";
import { DetailField, TicketSiteLink } from "../common";
import EventHeader from "../common/EventHeader";
import { JoinSessionButton } from "../common/JoinSessionButton";
import { useEventDetailContext } from "../EventDetailContextProvider";
import { useEventsAdminContext } from "../EventsAdminContextProvider";

export const Button = styled(ButtonBase)`
  width: 288px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: 272px;
  }
`;

const Wrapper = styled(Div)`
  border-bottom: solid 2px ${(props) => props.theme.palette.gray.lightest};
  padding-bottom: 30px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-bottom: 22px;
    border-bottom: none;
  }
`;

const EventTime = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
`;

const Duration = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    margin-left: 0;
  }
`;

const DetailFieldContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-left: 20px;
  }
`;

const Content = styled(Div)`
  align-items: flex-start;
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Header = () => {
  const { session } = useEventDetailContext();
  return <>{session && <HeaderWithData />}</>;
};

const HeaderWithData = () => {
  const { session, routeEventId } = useEventDetailContext();
  const { startDate, endDate, sessionId } = session;
  const { selectedFilter } = useEventsAdminContext();

  const duration = endDate.diff(startDate, ["hours", "minutes"]);

  const hours = duration.hours ? `${duration.hours} hr ` : "";
  const minutes = `${Math.ceil(duration.minutes)} min${
    duration.minutes > 1 ? "s" : ""
  }.`;

  return (
    <Wrapper>
      <EventHeader isSessionView={true} />
      {selectedFilter === "upcoming" && (
        <Content dflexColumn>
          <JoinSessionButton {...{ startDate, sessionId }} />
          <DetailField icon={<Icon form name="Ticket" />}>
            <TicketSiteLink eventId={routeEventId} />
          </DetailField>
        </Content>
      )}
      <MobileDivider style={{ margin: "32px 0 -2px 0" }} />
      <DetailFieldContainer>
        <DetailField icon={<Icon form name="Calendar" />}>
          <EventTime c_text fs_17 bold dflex>
            {startDate.toLocal().toFormat("DDDD")} at{" "}
            {startDate.toLocal().toFormat("h:mm a ZZZZ")}
            <Duration forDesktop ml_5>
              ({hours}
              {minutes})
            </Duration>
            <Duration forMobile>
              {hours}
              {minutes}
            </Duration>
          </EventTime>
        </DetailField>
      </DetailFieldContainer>
    </Wrapper>
  );
};

export default Header;
