import { Avatar, Div, IconWrapper, useIsMobile } from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import { useEventsAdminContext } from "components/EventsAdmin/EventsAdminContextProvider";
import { fstore } from "firebase-tools";
import { Icon } from "notes";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DetailField, TicketSiteLink } from "..";
import { useEventDetailContext } from "../../EventDetailContextProvider";
import { JoinSessionButton } from "../JoinSessionButton";
import EventMenu from "./EventMenu";
import { ShareTicketSiteModal } from "./ShareTicketSiteModal";
import { MobileHeader } from "./MobileHeader";

export const HTMLInput = styled<any>("input")`
  box-sizing: border-box;
  font-family: Overpass, sans-serif;
  outline: none;
  border: none;
  height: 40px;
  font-size: 25px;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
  ::placeholder {
    font-size: 17px;
    color: ${(props) => props.theme.colors.gray30};
    font-weight: 300;
  }
  width: 100%;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
  ${(props) => props.css};
  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

const InputWrapper = styled(Div)`
  box-sizing: border-box;
  /* box-shadow: inset 0 -1px 0 0 #bcafe6, 0 0 8px 0 #bcafe6; */
  box-shadow: 0 6px 3px -3px #bcafe6;
  border-bottom: 1px solid #bcafe6;
  margin-bottom: -1px;
  margin-right: 18px;
`;

const Button = styled(Div)`
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  :hover {
    opacity: 1;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.action};
    color: ${(props) => props.theme.colors.white};
    ${(props) =>
      props.secondary &&
      css`
        color: ${(props) => props.theme.colors.gray30};
        background-color: ${(props) => props.theme.colors.gray08};
      `}
  }
`;

const EventHeader = ({ isSessionView = false }) => {
  const { event } = useEventDetailContext();
  return <>{event && <EventHeaderWithData isSessionView={isSessionView} />}</>;
};

const EventHeaderWithData = ({ isSessionView }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { sessions, onEventNameUpdate } = useEventsAdminContext();
  const { event, routeEventId, routeSessionId } = useEventDetailContext();
  const { setAppIsLoading } = useAppContext();
  const { artist: eventArtist, name, assets } = event;
  const eventName = name || eventArtist;
  const [inputValue, setInputValue] = useState(eventName || "");
  const inputValueCount = inputValue.length;
  const { selectedFilter } = useEventsAdminContext();
  const [isShareTicketSiteModalOpen, setIsShareTicketSiteModalOpen] = useState(
    false
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    setInputValue(eventName);
  }, [eventName]);

  const updateEventName = async () => {
    setAppIsLoading(true);
    onEventNameUpdate(routeEventId, inputValue);
    await fstore.doc(`meet_greet_events/${routeEventId}`).set(
      {
        name: inputValue,
      },
      { merge: true }
    );
    setAppIsLoading(false);
    setIsEditing(false);
  };

  const updateButtonsUI = (
    <Div dflex fs_17 bold ml_8>
      <Div
        c_gray30
        onClick={() => {
          setIsEditing(false);
          setInputValue(eventName);
        }}
      >
        <Button secondary>Cancel</Button>
      </Div>
      <Div c_action ml_8 onClick={updateEventName}>
        <Button>Update</Button>
      </Div>
    </Div>
  );

  const inputUI = isEditing && (
    <InputWrapper ml_12 grow dflex alignCenter>
      <Div grow>
        <HTMLInput
          placeholder="Event name..."
          autoFocus
          value={inputValue}
          onChange={({ target: { value } }) => setInputValue(value)}
        />
      </Div>
      <Div w_32 justifyCenter fs_12 lite c_gray20>
        {inputValueCount}
      </Div>
      {updateButtonsUI}
    </InputWrapper>
  );

  const defaultUI = (
    <>
      {!isEditing && (
        <Div dflex alignCenter grow>
          <Div ml_12 extraBold fs_25 c_text>
            {eventName}
          </Div>
          {selectedFilter === "upcoming" && (
            <Div onClick={() => setIsEditing(true)}>
              <IconWrapper ml_4 c_gray30 title="Edit Event Name">
                <Icon tertiary name="Pencil" />
              </IconWrapper>
            </Div>
          )}
        </Div>
      )}
    </>
  );

  // sessions is sorted by start date
  if (isMobile) {
    return (
      <MobileHeader
        setIsShareTicketSiteModalOpen={setIsShareTicketSiteModalOpen}
      >
        <Div extraBold fs_20 lh_26 c_text>
          {eventName}
        </Div>
        {!routeSessionId && (
          <Div mb_32>
            <DetailField icon={<Icon form name="Link" />}>
              <TicketSiteLink eventId={routeEventId} />
            </DetailField>
          </Div>
        )}
      </MobileHeader>
    );
  }

  const nextSession = sessions.find((s) => s.eventId === routeEventId);
  return (
    <Div>
      <Div dflex alignCenter mt_24>
        <ShareTicketSiteModal
          isOpen={isShareTicketSiteModalOpen}
          onClose={() => setIsShareTicketSiteModalOpen(false)}
        />
        <Avatar src={assets?.squareBanner?.path} alt="" />
        {defaultUI}
        {inputUI}
        <EventMenu
          setIsShareTicketSiteModalOpen={setIsShareTicketSiteModalOpen}
        />
      </Div>
      {!isSessionView && nextSession && (
        <JoinSessionButton
          isEventButton={true}
          startDate={nextSession.startDate}
          sessionId={nextSession.sessionId}
        />
      )}
      {!routeSessionId && (
        <Div>
          <DetailField icon={<Icon form name="Ticket" />}>
            <TicketSiteLink eventId={routeEventId} />
          </DetailField>
        </Div>
      )}
    </Div>
  );
};

export default EventHeader;
