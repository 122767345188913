import { Div } from "@max/common-ui";
import { FormContentWrapper } from "components/common/form";
import { Icon } from "notes";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useEventMediaContext } from "../../EventMediaContextProvider";
import { WatermarkOptions } from "./WatermarkOptions";
import WatermarkPositioningModal from "./WatermarkPositioningModal";

const HTMLInput = styled("input")`
  display: none;
`;

const StyledFormContentWrapper = styled(FormContentWrapper)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-bottom: 40px;
  }
`;

const Label = styled(Div)`
  font-size: 11px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 300;
  font-style: italic;
`;

const Img = styled("img")`
  height: 142px;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
`;

const WatermarkInput = () => {
  const { mediaData, setMediaData } = useEventMediaContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  const { watermarkImgUrl, watermarkConfig, watermarkImages } = mediaData;

  const onSelectImage = ([file]) => {
    if (!file) return;
    setIsModalOpen(false);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const res = reader.result;
      setIsModalOpen(true);
      setMediaData((p) => ({
        ...p,
        watermarkImages: {
          logo: {
            blob: file,
            isInitialValue: false,
          },
        },
        watermarkImgUrl: res,
      }));
      fileInputRef.current.value = null;
    };
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const uploadWatermarkButtonUI = (
    <StyledFormContentWrapper dflex clickable onClick={openFilePicker} mt_25>
      <Div svg_action mt_3 ml_12>
        <Icon badge name="InstagramVerified" />
      </Div>
      <Div c_action ml_12>
        <Div link bold>
          Upload Watermark
        </Div>
        <Div fs_10 lh_13 c_gray30 mt_4>
          Light colored, high-resolution JPG or PNG images recommended
        </Div>
      </Div>
    </StyledFormContentWrapper>
  );

  const initialValueUI = (
    <>
      {watermarkImages.desktopImg?.isInitialValue && (
        <StyledFormContentWrapper mt_30>
          <Div dflex>
            <Div>
              <Img src={watermarkImages.desktopImg?.blob} />
              <Label>Header Image (Desktop)</Label>
            </Div>
            <Div ml_24>
              <Img src={watermarkImages.mobileImg?.blob} />
              <Label>Header Image (Mobile)</Label>
            </Div>
          </Div>
        </StyledFormContentWrapper>
      )}
    </>
  );

  return (
    <>
      {watermarkImgUrl && (
        <WatermarkOptions onRepositionWatermark={() => setIsModalOpen(true)} />
      )}
      {initialValueUI}
      <HTMLInput
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={(e) => onSelectImage(e.target.files as any)}
      />
      {uploadWatermarkButtonUI}
      {isModalOpen && (
        <WatermarkPositioningModal
          watermarkImgUrl={watermarkImgUrl}
          initialValues={watermarkConfig}
          onSubmit={(v) => {
            setIsModalOpen(false);
            setMediaData((p) => ({ ...p, watermarkConfig: v }));
          }}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default WatermarkInput;
