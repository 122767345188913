import { Div, getScrollBarCss, useDebounce, useIsMobile } from "@max/common-ui";
import { Icon, Input, Subheader } from "notes";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from "Routes";
import styled from "styled-components";
import { useEventsAdminContext } from "../EventsAdminContextProvider";
import Event from "./Event";
import { MobileDrawerTrigger } from "components/common/MobileDrawerTrigger";

export const Layout = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 403px;
  padding-left: 24px;
  ${Subheader} {
    display: none;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 0;
    width: 100%;
    ${Subheader} {
      display: block;
      font-weight: 900;
      padding-left: 20px;
      margin-bottom: 12px;
    }
  }
`;

const ScrollArea = styled(Div)`
  overflow-y: scroll;
  ${getScrollBarCss()};
  padding-right: 6px;
  padding-bottom: 30px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding-top: 78px;
  }
`;

const ScheduleButton = styled(Div)`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
  color: ${(props) => props.theme.palette.black};
  font-weight: 600;
  width: 184px;
  height: 40px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    position: fixed;
    bottom: 32px;
    right: 40px;
    z-index: 500;
    border-radius: 24px;
    width: 156px;
    height: 48px;
    color: ${(props) => props.theme.palette.action.primary};
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
const SearchContainer = styled(Div)`
  margin-right: 11px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 0;
    padding: 16px 16px 8px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    input {
      border: none;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
      &:focus {
        box-shadow: 0 2px 6px 0 rgb(124, 96, 217, 0.52);
      }
    }
  }
`;

const EventsList = () => {
  const { sessions, eventIds, incompleteEvents } = useEventsAdminContext();
  const { setSelectedFilter, selectedFilter } = useEventsAdminContext();
  const { artistGroupId, searchText, setSearchText } = useEventsAdminContext();
  const history = useHistory();
  const { _value, set_Value } = useDebounce(searchText, setSearchText, 1200);
  const isMobile = useIsMobile();

  const searchUI = (
    <SearchContainer mr_11>
      <Input
        value={_value || ""}
        onChange={(v) => set_Value(v)}
        leftIcon={
          isMobile ? (
            <MobileDrawerTrigger />
          ) : (
            <Div mt_n2>
              <Icon form name="Search" />
            </Div>
          )
        }
        rightIcon={
          isMobile ? (
            _value.length > 0 ? (
              <Icon
                form
                name="RemoveCircle"
                onClick={() => setSearchText("")}
              />
            ) : undefined
          ) : undefined
        }
        placeholder="Search for an event or attendee by name..."
      />
    </SearchContainer>
  );

  const scheduleButtonUI = (
    <ScheduleButton
      mt_24
      mb_32
      onClick={() => {
        setSelectedFilter("upcoming");
        history.push(
          generatePath(ROUTES.SCHEDULE_MEET, {
            artistGroupId,
          })
        );
      }}
    >
      <Div alignCenter svg_purple20>
        <Icon form name="CalendarAdd" />
      </Div>
      <Div fs_17 ml_9>
        Schedule
      </Div>
    </ScheduleButton>
  );

  return (
    <Layout>
      <Div>
        {scheduleButtonUI}
        {searchUI}
      </Div>
      <ScrollArea mt_20>
        <Subheader>
          {selectedFilter === "upcoming" ? "UPCOMING" : "PAST"}
        </Subheader>
        {eventIds.map((eventId, index) => (
          <Div key={index}>
            <Event
              {...(sessions.find((s) => s.eventId === eventId) ||
                incompleteEvents.find((s) => s.eventId === eventId))}
            />
          </Div>
        ))}
        {eventIds.length === 0 && (
          <Div centered mt_100 lite c_gray20>
            No events found
          </Div>
        )}
      </ScrollArea>
    </Layout>
  );
};

export default EventsList;
