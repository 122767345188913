import { AppContainer, AppLoading, Div, getBlobFromUrl } from "@max/common-ui";
import { useAppContext } from "AppContextProvider";
import { EventMediaFields } from "components/common/EventMediaFields";
import FormLayout from "components/common/FormLayout";
import { useEventNavigation } from "components/common/useEventNavigation";
import { WatermarkSource } from "components/ScheduleMeetGreet/ScheduleMeetGreetContextProvider";
import { fb, fstore } from "firebase-tools";
import { Button } from "notes";
import React, { useEffect, useState } from "react";
import { useEventDetailContext } from "./EventDetailContextProvider";

const getInitialValues = async (event) => {
  const res: any = {
    includeVideo: false,
    includeWatermark: false,
    recordingBlob: {
      blob: null,
      isInitialValue: false,
    },
    selectedWatermarkOption: event?.watermark?.source || WatermarkSource.custom,
    watermarkConfig: {
      includesGradient: false,
      desktopPosition: null,
      mobilePosition: null,
    },
    watermarkImgUrl: null,
    watermarkImages: {
      desktopImg: {
        blob: null,
        isInitialValue: false,
      },
      mobileImg: {
        blob: null,
        isInitialValue: false,
      },
    },
    artistImages: {
      desktopImg: {
        blob: event?.assets?.preshowBanner?.path,
        isInitialValue: true,
      },
      mobileImg: {
        blob: event?.assets?.squareBanner?.path,
        isInitialValue: true,
      },
    },
  };
  if (event?.assets?.salesVideo?.path) {
    res.recordingBlob = {
      blob: event?.assets?.salesVideo?.path,
      isInitialValue: true,
    };
    res.includeVideo = true;
  }
  if (event?.assets?.watermarkLandscape?.path) {
    res.includeWatermark = true;
    res.watermarkImages = {
      desktopImg: {
        blob: event?.assets?.watermarkLandscape?.path,
        isInitialValue: true,
      },
      mobileImg: {
        blob: event?.assets?.watermarkPortrait?.path,
        isInitialValue: true,
      },
    };
  }
  if (event?.watermark) {
    let imageLoadPromiseR = null;
    const imageLoadPromise = new Promise(
      (resolve) => (imageLoadPromiseR = resolve)
    );
    let watermarkImgDataUrl = null;
    const reader = new FileReader();
    reader.readAsDataURL(await getBlobFromUrl(event?.watermark?.asset));
    reader.onload = () => {
      watermarkImgDataUrl = reader.result;
      imageLoadPromiseR();
    };
    await imageLoadPromise;

    res.artistImages = {
      ...res.artistImages,
    };
    res.watermarkConfig = event?.watermark;
    res.watermarkImgUrl = watermarkImgDataUrl;
    res.watermarkImages = {
      ...res.watermarkImages,
      logo: {
        blob: event?.watermark?.asset,
        isInitialValue: !!event?.watermark?.asset,
      },
    };
  }
  return res;
};

const EditEventMedia = () => {
  const { event } = useEventDetailContext();
  return <>{event && <EditEventMediaWithData />}</>;
};

const EditEventMediaWithData = () => {
  const { event } = useEventDetailContext();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getInitialValues(event);
      setInitialValues(res);
    })();
  }, []);

  return (
    <>
      {initialValues ? (
        <EditEventMediaWithInitialValues initialValues={initialValues} />
      ) : (
        <AppLoading />
      )}
    </>
  );
};

const EditEventMediaWithInitialValues = ({ initialValues }) => {
  const { event, routeEventId } = useEventDetailContext();
  const [mediaData, setMediaData] = useState(initialValues);
  const { setAppIsLoading } = useAppContext();
  const { gotoCurrentEventDetail } = useEventNavigation();

  const onSubmit = async (assetUrls) => {
    const {
      includeWatermark,
      watermarkConfig,
      includeVideo,
      selectedWatermarkOption,
    } = mediaData;
    const {
      desktopImgUrl,
      mobileImgUrl,
      desktopWatermarkImgUrl,
      mobileWatermarkImgUrl,
      recordingBlobUrl,
      logoWatermarkImgUrl,
    } = assetUrls;

    const payload: any = {
      assets: JSON.parse(JSON.stringify(event?.assets)),
      watermark:
        event?.watermark && JSON.parse(JSON.stringify(event?.watermark)),
    };
    const assets = payload.assets;

    if (desktopImgUrl) assets.preshowBanner = { path: desktopImgUrl };
    if (mobileImgUrl) assets.squareBanner = { path: mobileImgUrl };
    if (recordingBlobUrl) assets.salesVideo = { path: recordingBlobUrl };

    if (!includeVideo && event?.assets?.salesVideo)
      assets.salesVideo = fb.firestore.FieldValue.delete();

    if (includeWatermark && desktopWatermarkImgUrl && mobileWatermarkImgUrl) {
      assets.watermarkLandscape = { path: desktopWatermarkImgUrl };
      assets.watermarkPortrait = { path: mobileWatermarkImgUrl };
      payload.watermark = {
        ...watermarkConfig,
        asset: logoWatermarkImgUrl || event?.watermark?.asset,
        source: selectedWatermarkOption,
      };
    } else if (!includeWatermark && event?.assets?.watermarkLandscape) {
      assets.watermarkLandscape = fb.firestore.FieldValue.delete();
      assets.watermarkPortrait = fb.firestore.FieldValue.delete();
      payload.watermark = fb.firestore.FieldValue.delete();
    }

    if (!payload.watermark) delete payload.watermark;

    setAppIsLoading(true);
    await fstore
      .doc(`meet_greet_events/${routeEventId}`)
      .set(payload, { merge: true });
    setAppIsLoading(false);
    gotoCurrentEventDetail();
  };

  const FormButtons = ({ onSubmit }) => {
    return (
      <Div mt_75 dflex>
        <Div ml_30>
          <Button onClick={onSubmit}>Save Changes</Button>
        </Div>
      </Div>
    );
  };

  return (
    <FormLayout
      label="Edit Media"
      title={event.artist}
      onGoBack={gotoCurrentEventDetail}
    >
      <AppContainer>
        <EventMediaFields
          {...{ onSubmit, mediaData, setMediaData, FormButtons }}
        />
      </AppContainer>
    </FormLayout>
  );
};

export default EditEventMedia;
