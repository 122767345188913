var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
import { MAXTheme } from "notes";
import { Div } from "../../../Div";
var Container = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #ededed;\n  height: 7px;\n  border-radius: 5px;\n  overflow: hidden;\n  @media all and ", " {\n    height: 4px;\n    border-radius: 0;\n  }\n"], ["\n  background: #ededed;\n  height: 7px;\n  border-radius: 5px;\n  overflow: hidden;\n  @media all and ", " {\n    height: 4px;\n    border-radius: 0;\n  }\n"])), function (props) { return props.theme.media.verticalDisplay; });
var Level = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 5px;\n  width: ", "%;\n  background: ", ";\n  @media all and ", " {\n    border-radius: 0;\n  }\n"], ["\n  border-radius: 5px;\n  width: ", "%;\n  background: ", ";\n  @media all and ", " {\n    border-radius: 0;\n  }\n"])), function (props) { return props.level; }, function (props) { return props.color; }, function (props) { return props.theme.media.verticalDisplay; });
var Meter = function (_a) {
    var _b = _a.level, level = _b === void 0 ? 0 : _b;
    var color = MAXTheme.colors.approved;
    if (level > 60)
        color = MAXTheme.colors.pending;
    if (level > 80)
        color = MAXTheme.colors.inputErrorBorder;
    return (React.createElement(Container, { w100: true, positionRelative: true },
        React.createElement(Level, { h100: true, level: level, color: color })));
};
export default Meter;
var templateObject_1, templateObject_2;
