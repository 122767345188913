import {
  Div,
  ModalBackdrop as ModalBackdropBase,
  ModalContainer as ModalContainerBase,
} from "@max/common-ui";
import { Icon } from "notes";
import styled from "styled-components";

const ModalBackdrop = styled(ModalBackdropBase)`
  opacity: 0.8;
`;

const ModalContainer = styled(ModalContainerBase)`
  margin-top: 140px;
`;

const IconWrapper = styled(Div)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 0;
    background: ${(props) => props.theme.colors.gray30};
  }
  user-select: none;
  :hover {
    background: #4f575b;
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Header = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000000;
`;

const AssetModal = ({
  title = null,
  onClose,
  children,
  downloadUrl = null,
  DownloadIconWrapper = null,
}) => {
  const downloadAssets = () => window.open(downloadUrl);

  const downloadUI = (
    <IconWrapper
      title="Close"
      onClick={() => {
        if (downloadUrl) downloadAssets();
      }}
    >
      <Icon name="Download" height={32} width={32} />
    </IconWrapper>
  );

  const iconsUI = (
    <Div dflex svg_white c_white mr_50 w_100>
      {DownloadIconWrapper ? (
        <DownloadIconWrapper>{downloadUI}</DownloadIconWrapper>
      ) : (
        downloadUI
      )}
      <IconWrapper title="Download" onClick={onClose} ml_20>
        <Icon platform name="Close" height={20} width={20} />
      </IconWrapper>
    </Div>
  );

  return (
    <>
      <Div>
        <Header c_white h_120 alignCenter spaceBetween>
          <Div w_150 />
          <Div bold fs_20>
            {title}
          </Div>
          {iconsUI}
        </Header>
        <ModalBackdrop />
        <ModalContainer>{children}</ModalContainer>
      </Div>
    </>
  );
};

export default AssetModal;
