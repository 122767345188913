import * as yup from "yup";
import { Form as FForm, Formik } from "formik";
import {
  Div,
  FormikErrorFocus,
  Input,
  Section as SectionBase,
} from "@max/common-ui";
import styled from "styled-components";
import { generatePath, useHistory, useLocation } from "react-router";
import { ButtonPrimary, ButtonSecondary } from "notes";
import { ROUTES as APP_ROUTES } from "Routes";
import { artists } from "@musicaudienceexchange/shoutout-interface/lib/firestore";
import { DateTime } from "luxon";
import { fstore } from "firebase-tools";
import { Converter } from "@musicaudienceexchange/toccata";
import { ROUTES } from ".";
import { yupStringRequired } from "./common";

const Section = styled(SectionBase)`
  max-width: 700px;
  width: 100%;
`;

const validationSchema = yup.object({
  name: yupStringRequired,
});

const AddArtistGroupForm = () => {
  const location = useLocation<any>();
  const history = useHistory();

  const onSubmitForm = async (values) => {
    const artistGroup = artists.ArtistGroup.load({
      name: values.name,
      updatedAt: DateTime.now(),
      createdAt: DateTime.now(),
    });
    const doc = await fstore
      .collection("artist_groups")
      .withConverter(Converter(artists.ArtistGroup))
      .add(artistGroup);
    history.push(
      generatePath(`${APP_ROUTES.ARTIST_MANAGEMENT}${ROUTES.ARTIST_GROUP}`, {
        artistGroupId: doc.id,
      })
    );
  };

  const initialValues = { name: "" };
  const artistGroupName = location?.state?.artistGroupName;
  if (artistGroupName) initialValues.name = artistGroupName;

  return (
    <Section>
      <Div mcontainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ submitForm, isSubmitting }) => (
            <FForm>
              <Div bold c_text fs_20 mb_10>
                Add New Artist
              </Div>
              <Input width="100%" name="name" placeholder="Artist Name..." />
              <Div dflex mt_40>
                <Div mr_25>
                  <ButtonSecondary
                    onClick={() => history.push(APP_ROUTES.ARTIST_MANAGEMENT)}
                  >
                    Cancel
                  </ButtonSecondary>
                </Div>
                <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                  Create Artist
                </ButtonPrimary>
              </Div>
              <FormikErrorFocus />
            </FForm>
          )}
        </Formik>
      </Div>
    </Section>
  );
};

export default AddArtistGroupForm;
