import { ThemeContext } from "styled-components";
import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router";
export function useMediaQuery(query) {
    var _a = useState(false), matches = _a[0], setMatches = _a[1];
    useEffect(function () {
        var media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        var listener = function () {
            setMatches(media.matches);
        };
        media.addEventListener("change", listener);
        return function () { return media.removeEventListener("change", listener); };
    }, [matches, query]);
    return matches;
}
export var useIsMobile = function () {
    var mobile = useContext(ThemeContext).media.mobile;
    return useMediaQuery(mobile);
};
export var useInput = function (value, onChange, onChangeTimeout) {
    if (onChangeTimeout === void 0) { onChangeTimeout = 400; }
    var _a = useState(value), text = _a[0], setText = _a[1];
    var tid = useRef(null);
    // improves performance in forms
    useEffect(function () {
        if (text !== value) {
            clearTimeout(tid.current);
            tid.current = setTimeout(function () { return onChange(text); }, onChangeTimeout);
        }
    }, [text]);
    useEffect(function () {
        setText(value);
    }, [value]);
    return { text: text, setText: setText };
};
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};
export var useDivTop = function () {
    var ref = useRef(null);
    var _a = useState(null), divTop = _a[0], setDivTop = _a[1];
    useEffect(function () {
        var setTop = function () { var _a; return setDivTop((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top); };
        setTop();
        window.addEventListener("resize", setTop);
        return function () {
            window.removeEventListener("resize", setTop);
        };
    }, []);
    return { ref: ref, divTop: divTop };
};
export var useWindowInnerHeight = function () {
    var _a = useState(window.innerHeight), windowInnerHeight = _a[0], setInnerHeight = _a[1];
    useEffect(function () {
        var _setInnerHeight = function () { return setInnerHeight(window.innerHeight); };
        _setInnerHeight();
        window.addEventListener("resize", _setInnerHeight);
        return function () {
            window.removeEventListener("resize", _setInnerHeight);
        };
    }, []);
    return windowInnerHeight;
};
export var useWindowInnerSize = function () {
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), innerSize = _a[0], setInnerSize = _a[1];
    useEffect(function () {
        var _setInnerSize = function () {
            setInnerSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        _setInnerSize();
        window.addEventListener("resize", _setInnerSize);
        return function () {
            window.removeEventListener("resize", _setInnerSize);
        };
    }, []);
    return innerSize;
};
export var useDebounce = function (value, onChange, timeoutMs) {
    if (timeoutMs === void 0) { timeoutMs = 1000; }
    var _a = useState(value), _value = _a[0], set_Value = _a[1];
    var tid = useRef(null);
    useEffect(function () {
        if (_value !== value) {
            clearTimeout(tid.current);
            tid.current = setTimeout(function () { return onChange(_value); }, timeoutMs);
        }
    }, [_value]);
    useEffect(function () {
        set_Value(value);
    }, [value]);
    return { _value: _value, set_Value: set_Value };
};
export var useQueryParams = function (queryParams) {
    var res = {};
    var params = new URLSearchParams(useLocation().search);
    queryParams.forEach(function (p) { return (res[p] = params.get(p)); });
    return res;
};
