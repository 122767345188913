import { useAppContext } from "AppContextProvider";
import { shoutoutI } from "firebase-tools";
import { Button } from "notes";
import styled from "styled-components";
import { FormContentWrapper } from "../common/form";
import { useScheduleMeetGreetContext } from "./ScheduleMeetGreetContextProvider";
import { EventMediaFields } from "../common/EventMediaFields";
type CreateEventRequest = shoutoutI.functions.meetgreet.CreateEventRequest;

const useSubmitForm = (): ((
  assetUrls: any
) => shoutoutI.functions.meetgreet.CreateEventRequest) => {
  const {
    ticketSiteData,
    artistGroupId,
    mediaData: { watermarkConfig, selectedWatermarkOption },
  } = useScheduleMeetGreetContext();
  const {
    welcomeMessage,
    pricePerFan,
    callDurationSeconds,
    rulesLimitations,
  } = ticketSiteData;

  const getEventPayload = (assetUrls) => {
    const assets: any = {};
    const {
      desktopImgUrl,
      mobileImgUrl,
      desktopWatermarkImgUrl,
      mobileWatermarkImgUrl,
      recordingBlobUrl,
      logoWatermarkImgUrl,
    } = assetUrls;

    if (desktopImgUrl) assets.preshowBanner = { path: desktopImgUrl || "" };
    if (mobileImgUrl) assets.squareBanner = { path: mobileImgUrl || "" };
    if (desktopWatermarkImgUrl)
      assets.watermarkLandscape = { path: desktopWatermarkImgUrl || "" };
    if (mobileWatermarkImgUrl)
      assets.watermarkPortrait = { path: mobileWatermarkImgUrl || "" };
    if (recordingBlobUrl) assets.salesVideo = { path: recordingBlobUrl || "" };

    const event: CreateEventRequest = {
      event: {
        artistGroupId,
        checkoutDescription: welcomeMessage,
        unitPrice: parseFloat(pricePerFan) * 100,
        sessionDuration: parseFloat(callDurationSeconds),
        assets,
      },
    };
    if (logoWatermarkImgUrl)
      event.event.watermark = {
        ...watermarkConfig,
        asset: logoWatermarkImgUrl,
        source: selectedWatermarkOption,
      };
    if (rulesLimitations) event.event.checkoutWhatToExpect = [rulesLimitations];

    return event;
  };

  return getEventPayload;
};

export const MediaForm = () => {
  const {
    mediaData,
    setMediaData,
    setEventId,
    setCurrentStep,
  } = useScheduleMeetGreetContext();
  const { setAppIsLoading, setNewEventId } = useAppContext();
  const getEventPayload = useSubmitForm();

  const onSubmit = async (assetUrls) => {
    const eventRequest = getEventPayload(assetUrls);
    setAppIsLoading(true);
    const res = await shoutoutI.functions().meetgreet.createEvent(eventRequest);
    if (!res.error) {
      const eventId = res.result.id;
      console.log({ eventId });
      setEventId(eventId);
      setNewEventId(eventId);
    }
    setCurrentStep((p) => p + 1);
    setAppIsLoading(false);
  };

  const FormButtons = ({ onSubmit: onFormSubmit }) => {
    return (
      <StyledFormContentWrapper mt_75>
        <Button type="tertiary" onClick={() => setCurrentStep((p) => p - 1)}>
          Back
        </Button>
        <Button onClick={onFormSubmit}>Continue</Button>
      </StyledFormContentWrapper>
    );
  };

  return (
    <EventMediaFields {...{ onSubmit, mediaData, setMediaData, FormButtons }} />
  );
};

const StyledFormContentWrapper = styled(FormContentWrapper)`
  display: flex;
  flex-direction: row;
  button + button {
    margin: 0 0 0 25px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lighter};
    margin: 0;
    padding: 22px 20px 0;
    flex-direction: column;
    button {
      width: 100%;
    }
    button:first-of-type {
      order: 2;
      margin-top: 8px;
    }
    button + button {
      margin: 0;
      order: 1;
    }
  }
`;
