import _ from "lodash";
export const validate = (data, schema) => {
  const { error } = schema.validate(data, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errors = null;

  _.get(error, "details", []).forEach(({ message, path, type, context }) => {
    const { key, limit } = context;

    if (!errors || !errors[key]) {
      if (!errors) {
        errors = {};
      }
      switch (type) {
        case "any.required":
          errors[key] = "This is a required field and cannot be blank.";
          break;
        case "string.empty":
          errors[key] = "This is a required field and cannot be blank.";
          break;
        case "string.min":
          errors[key] = `This field must contain at least ${limit} characters.`;
          break;
        case "string.email":
          errors[key] = "This does not appear to be a valid email address.";
          break;
        default:
          errors[key] = message;
          break;
      }
    }
  });

  return errors;
};
