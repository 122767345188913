import {
  AppContainer as AppContainerBase,
  AppLayout,
  Div,
  Footer,
  IconWrapper,
} from "@max/common-ui";
import { Icon } from "notes";
import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

const Title = styled(Div)`
  font-weight: 800;
`;

const FormLayout = ({ label, title, children, onGoBack }) => {
  const {
    colors: { white: whiteColor },
  } = useContext(ThemeContext);

  const navigationUI = (
    <AppContainerBase>
      <Div mcontainer>
        <IconWrapper c_gray30 clickable onClick={onGoBack}>
          <Icon name="Left" />
        </IconWrapper>
      </Div>
    </AppContainerBase>
  );

  const contentUI = (
    <Div mb_50>
      <AppContainerBase>
        <Div mcontainer>
          <Title fs_20 extraBold c_gray20 mt_50 uppercase>
            {label}
          </Title>
          <Title c_text fs_25 mt_15>
            {title}
          </Title>
        </Div>
      </AppContainerBase>
      <Div mt_27>{children}</Div>
    </Div>
  );

  return (
    <AppLayout>
      <Div mt_20>{navigationUI}</Div>
      {contentUI}
      <Footer background={whiteColor} />
    </AppLayout>
  );
};

export default FormLayout;
