import { Div, IconWrapper } from "@max/common-ui";
import { RoleType } from "@musicaudienceexchange/shoutout-interface/lib/firestore/meetgreet";
import { Icon } from "notes";
import { generatePath, useHistory, useParams } from "react-router";
import { ROUTES } from "Routes";
import styled from "styled-components";
import { EVENT_ADMIN_ROUTES } from "..";
import { useEventDetailContext } from "../EventDetailContextProvider";
import { Layout as LayoutBase } from "./common";

const Layout = styled(LayoutBase)`
  box-sizing: border-box;
  width: 248px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-top: 1px solid ${(props) => props.theme.palette.gray.lightest};
    padding-top: 22px;
    padding-right: 20px;
    width: 100%;
  }
`;

const Label = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    line-height: 22px;
  }
`;

const UpcomingAttendees = () => {
  const {
    session: { slotCount, roles },
    routeSessionId,
  } = useEventDetailContext();

  const history = useHistory();
  const { eventId, artistGroupId } = useParams<any>();
  const fans = roles.filter((r) => r.role === RoleType.fan);
  return (
    <Layout>
      <Label fs_17 extraBold c_text mb_20 spaceBetween alignCenter>
        <Div dflex>
          <Div forMobile alignCenter w_16 mr_16>
            <Icon form name="Audience" />
          </Div>
          Attendees ({fans.length}/{slotCount})
        </Div>
        <IconWrapper
          ml_5
          c_gray30
          onClick={() =>
            history.push(
              `${generatePath(
                `${ROUTES.EVENTS_ADMIN}${EVENT_ADMIN_ROUTES.ATTENDEE_OPTIONS}`,
                { artistGroupId, eventId }
              )}?sId=${routeSessionId}`
            )
          }
        >
          <Icon tertiary name="Gear" />
        </IconWrapper>
      </Label>
      <Div ml_10 mb_60>
        {fans.map((fan, index) => (
          <Div key={index} dflex h_40>
            <Div svg_green20>
              <Icon badge name="FacebookVerified" />
            </Div>
            <Div ml_12 fs_15 c_text>
              <Div truncateText mr_15>
                {fan.name || fan.email}
              </Div>
            </Div>
          </Div>
        ))}
        {fans.length === 0 && (
          <Div lite fs_12 centered mt_30 c_gray30 grow>
            No Attendees found
          </Div>
        )}
      </Div>
    </Layout>
  );
};

export default UpcomingAttendees;
