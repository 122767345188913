import { Div } from "@max/common-ui";
import styled from "styled-components";
import { FormContentWrapper } from "../form";
import { Checkbox as CheckboxBase } from "notes";

const CheckboxInputDescription = styled(Div)`
  font-size: 17;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray40};
  margin-left: 5px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const Checkbox = ({
  label,
  isSelected,
  description,
  onClick,
  ...props
}) => {
  return (
    <Wrapper mt_40 clickable {...props}>
      <Div as="label" clickable>
        <TitleContainer dflex svg_white>
          <CheckboxBase
            checked={isSelected}
            onChange={(checked: boolean) => onClick(checked)}
          />
          <CheckboxInputDescription mt_1>{label}</CheckboxInputDescription>
        </TitleContainer>
        <FormContentWrapper c_gray40 fs_15 lite mt_5>
          {description}
        </FormContentWrapper>
      </Div>
    </Wrapper>
  );
};

const TitleContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-left: 20px;
  }
`;

const Wrapper = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 22px 0 32px;
  }
`;
